import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function WattsCard() {
  const [lastWatts, setLastWatts] = React.useState("N/A");

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (deviceHistory && deviceHistory.length) {
      //get last data object
      let lastDataObject = deviceHistory[deviceHistory.length - 1];
      //parse data
      let jsonData = lastDataObject.data;
      jsonData = JSON.parse(jsonData);
      //get last level
      let lastWatts = jsonData.watt;
      //set last level
      setLastWatts(lastWatts);
    }
  }, [deviceHistory]);

  return (
    <Card>
      <CardHeader title="Watts" />
      <CardContent>
        <Typography
          variant="h4"
          //dont wrap text
          noWrap
          //hide overflow
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {lastWatts ? lastWatts + "W" : "No Reading"}
        </Typography>
      </CardContent>
    </Card>
  );
}
