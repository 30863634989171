import { DialogTitle } from "@material-ui/core";
import { DialogActions, Typography } from "@mui/material";
import { Dialog, DialogContent } from "@mui/material";
import React, { useEffect } from "react";
import { Button } from "@mui/material";
import deleteData from "../../API/Delete";
import { formatDate } from "../../Functions/formatDate";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import moment from "moment";
import { fetchDeviceHistory } from "../../Functions/fetchDeviceHistory";

export default function DeleteFramesDialog(props) {
  const { open, handleClose, selectedRows } = props;

  const [listOfFrames, setListOfFrames] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const device = useSelector((state) => state.device);
  const dateRange = useSelector((state) => state.dateRange);

  useEffect(() => {
    if (!open) return;

    //for each selected row, grab the row from deviceHistory, and create a list of fcnts and time_createds
    let listOfFrames = [];

    selectedRows.forEach((row) => {
      let frame = deviceHistory[row];
      let fcnt = frame.fcnt;

      //convert to date object
      let date = frame.time_created;

      //push to list
      listOfFrames.push({
        fcnt: fcnt,
        time_created: date,
      });
    });

    setListOfFrames(listOfFrames);
  }, [selectedRows, deviceHistory, device, open]);

  const deleteFrames = async () => {
    //for each selected row, grab the row from deviceHistory and delete it, wait for all to be deleted before fetching device history

    //first create all the urls
    let urls = [];

    listOfFrames.length &&
      listOfFrames.map(async (frame) => {
        let fcnt = frame.fcnt;
        let device_id = device.id;
        //convert to date object
        let date = moment(frame.time_created);

        //remove miliseconds
        date.milliseconds(0);

        //convert to unix timestamp
        let time_created = date.unix();

        let url = "/frame/" + device_id + "/" + fcnt + "/" + time_created;

        //push to list
        urls.push(url);

        return null;
      });

    //delete all the frames, waiting for each to be deleted before moving on (using Promise.all)
    await Promise.all(
      urls.map(async (url) => {
        await deleteData(url);
      })
    );

    // Fetch device history after all frames are deleted
    await fetchDeviceHistory(device.id, dateRange.startDate, dateRange.endDate);

    updateSnackbarMessage("Frames deleted successfully");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-frames-dialog"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="delete-frames-dialog">Delete Frames</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to delete the selected frames?
        </Typography>
      </DialogContent>
      <DialogContent>
        {
          //list of frames
          listOfFrames.map((frame, index) => {
            return (
              <Typography variant="body1" key={index}>
                {frame.fcnt} - {formatDate(frame.time_created)}
              </Typography>
            );
          })
        }
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={deleteFrames}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
