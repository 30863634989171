import { store } from "../store";

export default function removeSelectedFrames(framesArray) {
  framesArray.forEach((frame) => {
    store.dispatch({
      type: "deviceHistory/remove",
      payload: frame.id,
    });
  });
}
