import hotspot from "../hotspot.png";
import deviceicon from "../deviceicon.png";

export default function addImages(map) {
  map.loadImage(deviceicon, (error, image) => {
    if (error) throw error;
    map.addImage("marker-15", image);
  });

  //add image for hotspot-icon from local source
  map.loadImage(hotspot, (error, image) => {
    if (error) throw error;
    map.addImage("hotspot-icon", image);
  });
}
