import React from "react";
import DeviceTypeBase from "../DeviceTypeBase";

export default function Senseo8EnergyMonitor(props) {
  let { isShare } = props;

  const columns = [
    {
      field: "energy",
      title: "Energy",
      icon: "bolt",
      iconSize: "6x",
      unit: "kWh",
      formatter: (value) => (value ? value + " kWh" : "N/A"),
      aspect: 4,
      metricWidth: 4,
      graphWidth: 6,
    },
    {
      field: "current",
      title: "Current",
      icon: "bolt",
      iconSize: "6x",
      unit: "A",
      formatter: (value) => (value ? value + " A" : "N/A"),
      aspect: 4,
      metricWidth: 4,
      graphWidth: 6,
    },
    {
      field: "watt",
      title: "Watt",
      icon: "bolt",
      iconSize: "6x",
      unit: "W",
      formatter: (value) => (value ? value + " W" : "N/A"),
      aspect: 4,
      metricWidth: 4,
      graphWidth: 6,
    },
    {
      field: "voltage",
      title: "Voltage",
      icon: "bolt",
      iconSize: "6x",
      unit: "V",
      formatter: (value) => (value ? value + " V" : "N/A"),
      aspect: 4,
      metricWidth: 4,
      graphWidth: 6,
    },
    {
      field: "freq",
      title: "Frequency",
      icon: "signal",
      iconSize: "6x",
      unit: "Hz",
      formatter: (value) => (value ? value + " Hz" : "N/A"),
      aspect: 4,
      metricWidth: 4,
      graphWidth: 6,
    },
    {
      field: "pf",
      title: "Power Factor",
      icon: "bolt",
      iconSize: "6x",
      unit: "",
      formatter: (value) => (value ? value : "N/A"),
      aspect: 4,
      metricWidth: 4,
      graphWidth: 6,
    },
  ];

  return (
    <DeviceTypeBase isShare={isShare} columns={columns} showBattery={false} />
  );
}
