import { putData } from "../API/Put";

export default async function editDashboard(dashboardId, data) {
  let result = putData(`/dashboard/${dashboardId}`, data);

  if (result) {
    return true;
  } else {
    return false;
  }
}
