const initialState = [];

function basesReducer(state = initialState, action) {
  switch (action.type) {
    case "bases/init":
      return action.payload;
    case "bases/add":
      return [...state.value, action.payload];
    case "bases/remove":
      return state.filter((base) => base.id !== action.payload);
    case "bases/update":
      return action.payload;
    default:
      return state;
  }
}

export default basesReducer;
