import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React from "react";
import AlertLogDialog from "../Dialogs/AlertLog";
import { formatDate } from "../../Functions/formatDate";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

export default function LastAlert() {
  const [alertLogDialogOpen, setAlertLogDialogOpen] = React.useState(false);
  const { t } = useTranslation("common");

  const deviceAlerts = useSelector((state) => state.deviceAlerts);

  const theme = useTheme();

  const toggleAlertLogDialogOpen = () => {
    setAlertLogDialogOpen(!alertLogDialogOpen);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="subtitle"
              sx={{
                // if light theme then color is black, else dark grey
                color: theme.palette.mode === "light" ? "#000" : "#616161",
              }}
            >
              Last Alert
            </Typography>
          </Box>
          {deviceAlerts && deviceAlerts.length && deviceAlerts[0].message ? (
            <Tooltip
              title={deviceAlerts[0].message}
              onClick={() => {
                setAlertLogDialogOpen(true);
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "1.2rem" }}
                color="primary"
                className="cursor-pointer"
              >
                {formatDate(deviceAlerts[0].time_created)}
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip title={"Last Alert: " + t("common.none")}>
              <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
                {t("common.none")}.
              </Typography>
            </Tooltip>
          )}
        </CardContent>
      </Card>
      <AlertLogDialog
        open={alertLogDialogOpen}
        handleClose={() => toggleAlertLogDialogOpen()}
        lastAlert={
          deviceAlerts && deviceAlerts.length > 0 ? deviceAlerts[0] : null
        }
      />
    </>
  );
}
