import { CONFIG } from "../config";

// mapbox matching api call
export default async function getMatch(coordinates, profile = "driving") {
  if (coordinates.length < 3) {
    return coordinates;
  }

  // Create the query
  const query = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinates}?access_token=${CONFIG.mapboxAccessToken}&geometries=geojson`,
    { method: "GET" }
  );
  const response = await query.json();

  // Handle errors
  if (response.code !== "Ok") {
    console.log("response error", response);
    return coordinates;
  }
  return response.routes[0].geometry.coordinates;
}
