import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import setTitle from "../Functions/setTitle";

export default function NotFound(props) {
  useEffect(() => {
    setTitle("Not Found");
  }, []);

  return (
    <>
      <Typography variant="h4">Not Found</Typography>
    </>
  );
}
