const getGeofenceTitle = (tableMeta, t) => {
  if (!tableMeta) return;

  if (!tableMeta.rowData) return;

  let geofence_action = tableMeta.rowData[16];
  let command = tableMeta.rowData[17];

  let title = "";

  title = `Send Downlink on Geofence ${geofence_action} ${command}`;

  return title;
};

export default getGeofenceTitle;
