const initialState = [];

function deviceAlerts(state = initialState, action) {
  switch (action.type) {
    case "deviceAlerts/update":
      return action.payload;
    default:
      return state;
  }
}

export default deviceAlerts;
