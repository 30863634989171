import { Card, CardContent, CardHeader, useTheme } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function AnalogInputGraph(props) {
  let { analogInputHistory } = props;

  const theme = useTheme();

  const isMobile = useSelector((state) => state.isMobile);

  return (
    <Card>
      <CardHeader title="Analog Input History" />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? 5 : 2}>
          <AreaChart
            data={analogInputHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time_created"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis dataKey="value" type="category" yAxisId="1" scale="point" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
            />
            <Area
              yAxisId="1"
              type="monotone"
              dataKey="value"
              //use theme color for line
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Temperature)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
