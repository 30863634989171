// create a geojson circle for a geofence
export default function createGeoJSONCircleFeature(
  center,
  radiusInMeters,
  points,
  geocode,
  time,
  index,
  data
) {
  if (!points) points = 64;
  if (!radiusInMeters) radiusInMeters = 5;

  var coords = {
    latitude: center[1],
    longitude: center[0],
  };

  //convert meters to km
  var km = radiusInMeters / 1000;

  var ret = [];
  var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  var distanceY = km / 110.574;

  var theta, x, y;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  const feature = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [ret],
    },
    properties: {
      title: geocode,
      time: time,
      index: index,
      longitude: center[0],
      latitude: center[1],
      data: data,
    },
  };

  return feature;
}
