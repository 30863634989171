const initialState = false;

function isMobileReducer(state = initialState, action) {
  switch (action.type) {
    case "isMobile/update":
      return action.payload;
    default:
      return state;
  }
}

export default isMobileReducer;
