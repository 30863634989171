import fetchData from "../API/Fetch";
import { store } from "../store";

export default async function fetchGeofences(orgID) {
  if (orgID === undefined || orgID === 0) return;
  let result = await fetchData(`/geofence/organisation/${orgID}`);

  store.dispatch({
    type: "geofences/update",
    payload: result ? result : [],
  });
  return result;
}
