import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import fetchGeofences from "../../Functions/fetchGeofences";
import { useSelector } from "react-redux";
import deleteData from "../../API/Delete";

export default function GeofenceDeleteDialog(props) {
  const { open, handleClose } = props;
  const { t } = useTranslation("common");

  const geofences = useSelector((state) => state.geofences);

  //Delete geofences
  async function deleteGeofences() {
    try {
      // loop through props.selectedAlerts and delete each one
      for (let i = 0; i < props.selectedGeofences.length; i++) {
        await deleteData(`/geofence/${props.selectedGeofences[i]}`);
      }

      updateSnackbarMessage(t("geofences.delete_success"));
      fetchGeofences(localStorage.getItem("selectedOrganisationID"));
      handleClose();
    } catch (error) {
      updateSnackbarMessage(t("geofences.delete_error"));
      console.log(error);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("geofences.delete_title")}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <Typography variant="body1">
            {t("geofences.delete_confirmation")}
          </Typography>
          <ul>
            {props.selectedGeofences.map((geofence) => {
              // find geofence name from geofence to geofences id
              let geofenceName = geofences.find((b) => b.id === geofence);
              if (geofenceName && geofenceName.name) {
                return <li key={geofenceName.name}>{geofenceName.name}</li>;
              }
              return null;
            })}
          </ul>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="error" onClick={deleteGeofences}>
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
