import fetchData from "../API/Fetch";
import { store } from "../store";

export const fetchDevice = (deviceID) => {
  if (!deviceID) {
    store.dispatch({ type: "device/update", payload: [] });
    return;
  }

  fetchData(`/device/${deviceID}`).then((response) => {
    if (response) {
      store.dispatch({
        type: "device/update",
        payload: response,
      });
    } else {
      store.dispatch({ type: "device/update", payload: [] });
    }
  });
};
