import * as React from "react";
import Map, {
  FullscreenControl,
  NavigationControl,
  ScaleControl,
  Marker,
  Popup,
} from "react-map-gl";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { CONFIG } from "../../config";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { updatePitch } from "../../Functions/updatePitch";
import mapboxgl from "mapbox-gl";
import { formatDate } from "../../Functions/formatDate";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

export default function DevicesMap() {
  const devices = useSelector((state) => state.devices);
  const organisation = useSelector((state) => state.selectedOrganisation);

  let center = organisation.center;
  //convert from string to array
  if (typeof center === "string") {
    center = center.split(",").map(Number);
  }

  const filteredDevices = devices.filter((device) => device.lat && device.lng);
  const [showPopup, setShowPopup] = React.useState(false);
  const [selectedDevice, setSelectedDevice] = React.useState(null);
  const theme = useTheme();

  const navigate = useNavigate();

  const mapRef = React.useRef(null);
  const pitch = useSelector((state) => state.pitch);

  const fitToHistory = React.useCallback(() => {
    const filteredDev = devices.filter((device) => device.lat && device.lng);
    if (filteredDev.length) {
      const bounds = new mapboxgl.LngLatBounds();
      filteredDev.forEach((device) => {
        bounds.extend([device.lng, device.lat]);
      });

      //check to see if there is at least 1km between the two points
      if (bounds.getNorthEast().distanceTo(bounds.getSouthWest()) < 500) {
        bounds.extend([
          bounds.getNorthEast().lng + 0.001,
          bounds.getNorthEast().lat + 0.001,
        ]);

        bounds.extend([
          bounds.getSouthWest().lng - 0.001,
          bounds.getSouthWest().lat - 0.001,
        ]);
      }

      mapRef.current.fitBounds(bounds, {
        padding: 50,
        animate: false,
      });
    }
  }, [devices]);

  const handleMapLoad = (e) => {
    mapRef.current = e.target;

    if (organisation.hero === "map" && organisation.centerandzoom !== "false") {
      //fly to center not animated and set zoom

      mapRef.current.flyTo({
        center: organisation.center.split(",").map(Number),
        zoom: organisation.zoom,
        essential: true,
        animate: false,
      });
    } else {
      fitToHistory();
    }

    e.target.addControl(pitchToggle, "top-left");
  };

  const flyToDevice = (device, e) => {
    mapRef.current.flyTo({
      center: [device.lng, device.lat],
      zoom: 15,
      essential: true,
    });

    setSelectedDevice(device);
    setShowPopup(true);
  };

  class PitchToggle {
    constructor({ bearing = 0, pitch = 75, minpitchzoom = null }) {
      this._bearing = bearing;
      this._pitch = pitch;
      this._minpitchzoom = minpitchzoom;
    }

    onAdd(map) {
      this._map = map;
      let _this = this;

      this._btn = document.createElement("button");

      // get pitch and set button icon
      if (localStorage.getItem("pitch") === "0") {
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        this._btn.title = "Toggle Pitch (3D)";
      } else {
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        this._btn.title = "Toggle Pitch (2D)";
      }
      this._btn.type = "button";
      this._btn["aria-label"] = "Toggle Pitch";
      this._btn.onclick = function () {
        if (map.getPitch() === 0) {
          let options = { pitch: _this._pitch, bearing: _this._bearing };
          if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
            options.zoom = _this._minpitchzoom;
          }
          updateSnackbarMessage("3D View Enabled");
          updatePitch(75);
          //set pitch in local storage so it can be retrieved when the page is refreshed
          localStorage.setItem("pitch", 75);

          map.easeTo({ pitch: 75, bearing: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        } else {
          updatePitch(0);
          updateSnackbarMessage("2D View Enabled");
          localStorage.setItem("pitch", 0);

          map.easeTo({ pitch: 0, bearing: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        }
      };

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
      this._container.appendChild(this._btn);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  const pitchToggle = new PitchToggle({
    bearing: 0,
    pitch: pitch,
    minpitchzoom: null,
  });

  return (
    <Map
      mapboxApiAccessToken={CONFIG.mapboxAccessToken}
      mapStyle={
        theme.palette.mode === "light"
          ? "mapbox://styles/mapbox/streets-v11"
          : "mapbox://styles/mapbox/dark-v10"
      }
      onLoad={(e) => {
        mapRef.current = e.target;
        handleMapLoad(e);
      }}
      initialViewState={
        organisation &&
        organisation.centerandzoom &&
        organisation.centerandzoom !== "false"
          ? {
              latitude: center && center[1] ? center[1] : 0,
              longitude: center && center[0] ? center[0] : 0,
              zoom:
                organisation.zoom && organisation.zoom !== "false"
                  ? organisation.zoom
                  : 10,
            }
          : {}
      }
      id="map-panel"
    >
      <NavigationControl position="top-left" />
      <FullscreenControl position="top-left" />
      <ScaleControl position="bottom-right" />

      {filteredDevices.map((device) => (
        //if time_updated is more than 48 hours ago, color the marker red
        <Marker
          key={device.id}
          longitude={device.lng}
          latitude={device.lat}
          color={
            new Date(device.time_updated) < new Date(Date.now() - 172800000)
              ? "red"
              : "green"
          }
          onClick={(e) => {
            flyToDevice(device, e);
          }}
        />
      ))}
      {showPopup === true && (
        <Popup
          anchor="top"
          latitude={selectedDevice.lat}
          longitude={selectedDevice.lng}
          onClose={() => setShowPopup(false)}
          closeOnClick={false}
        >
          <Typography variant="h6">{selectedDevice.name}</Typography>
          <p>
            <strong>Last Seen: </strong>
            {formatDate(selectedDevice.time_updated)}
            <br />
            <strong>Last Location: </strong> {selectedDevice.last_location}
          </p>
          <Button
            variant="contained"
            onClick={() => navigate(`/device/${selectedDevice.id}`)}
          >
            View Device
          </Button>
        </Popup>
      )}
    </Map>
  );
}
