import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import { store } from "./store";

import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

import { Box, CircularProgress } from "@mui/material";
import { CONFIG } from "./config";
import { Provider } from "react-redux";

const locale = "en";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: CONFIG.defaultLanguage, // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
  },
});

// loading component for suspense fallback
const Loader = () => (
  <Box sx={{ display: "flex" }}>
    <CircularProgress />
  </Box>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0ProviderWithHistory>
      <I18nextProvider local={locale} messages={common_en} i18n={i18next}>
        <Suspense fallback={<Loader />}>
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
      </I18nextProvider>
    </Auth0ProviderWithHistory>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
