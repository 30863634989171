import { useState, useMemo, useEffect } from "react";
import DataTableBase from "./DataTableBase";
import DeleteFramesDialog from "./Dialogs/DeleteFramesDialog";
import { useSelector } from "react-redux";
import removeSelectedFrames from "../Functions/removeSelectedFrames";
import { Typography } from "@mui/material";
import { formatDate } from "../Functions/formatDate";
import convertMvToPercent from "../Functions/convertMvToPercent";

export default function RecentFrames(props) {
  const { columns, isShare, showBattery } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteFramesDialogOpen, setDeleteFramesDialogOpen] = useState(false);

  const device = useSelector((state) => state.device);
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [formattedColumns, setFormattedColumns] = useState([]);

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const user = useSelector((state) => state.user);

  const isOwner = useMemo(() => {
    return (
      user && selectedOrganisation && user.id === selectedOrganisation.owner_id
    );
  }, [user, selectedOrganisation]);

  const handleDeleteFramesDialogClose = () => {
    removeSelectedFrames(selectedRows);
    setSelectedRows([]);
    setDeleteFramesDialogOpen(false);
  };

  useEffect(() => {
    if (columns && columns.length > 0) {
      let formatted = [];

      //add time created
      formatted.push({
        name: "time_created",
        label: "Time Created",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === "N/A") {
              return <Typography>N/A</Typography>;
            }

            return <Typography>{formatDate(value, "LLL")}</Typography>;
          },
        },
      });

      columns.forEach((column) => {
        formatted.push({
          name: "data",
          label: column.title,
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              //get instantaneous_active_power from data object
              let jsonData = value;
              jsonData = JSON.parse(jsonData);
              let data = jsonData[column.field];

              if (data === undefined) {
                return <Typography>N/A</Typography>;
              }

              return <Typography>{column.formatter(data)}</Typography>;
            },
          },
        });
      });

      if (showBattery === true || showBattery === undefined) {
        formatted.push({
          //battery
          name: "data",
          label: "Battery",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
              // Remove unused parameters 'tableMeta' and 'updateValue'
              let battery = 0;
              //get battery from data object
              try {
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                battery =
                  jsonData.voltageMv || jsonData.batteryMv || jsonData.bat;
              } catch (error) {}

              if (battery === 0) {
                return <Typography>N/A</Typography>;
              }

              //draw a progress bar with the value and color it red if below 20% and yellow if below 50% and green if above 50%
              return (
                <Typography>
                  {convertMvToPercent(
                    battery,
                    device && device.device_brand ? device.device_brand : null
                  )}
                </Typography>
              );
            },
          },
        });
      }

      setFormattedColumns(formatted);
    }
  }, [columns, device, showBattery]);

  return (
    <>
      <DataTableBase
        title="Frame History"
        columns={formattedColumns}
        data={deviceHistory}
        rowsPerPage={10}
        sortOrder="time_created"
        sortDirection="desc"
        selectableRows={isOwner ? (!isShare ? "none" : "multiple") : "none"}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={
          !isOwner || isShare
            ? []
            : [
                {
                  name: "Delete",
                  icon: "Delete",
                  action: () => setDeleteFramesDialogOpen(true),
                },
              ]
        }
      />
      <DeleteFramesDialog
        open={deleteFramesDialogOpen}
        selectedRows={selectedRows}
        handleClose={handleDeleteFramesDialogClose}
      />
    </>
  );
}
