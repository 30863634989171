const moment = require("moment");
const { CONFIG } = require("../config");

const tz = moment.tz.guess();

function formatUnixDate(unixTimestamp) {
  //convert from utc to local time
  //check if local storage has a date_format, if not use the default form CONFIG.defaultDateFormat
  let dateFormat =
    localStorage.getItem("date_format") !== null
      ? localStorage.getItem("date_format")
      : CONFIG.defaultDateFormat;

  let timeFormat =
    localStorage.getItem("time_format") !== null
      ? localStorage.getItem("time_format")
      : "HH:mm";

  return moment
    .unix(unixTimestamp)
    .tz(tz)
    .format(timeFormat + " " + dateFormat);
}

module.exports = formatUnixDate;
