import React from "react";
import { Grid } from "@mui/material";
import DeviceActionLogs from "../Cards/Alerts";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import FrameList from "../FrameList";
import CurrentCard from "../Cards/Current";
import VoltageCard from "../Cards/Voltage";
import WattsCard from "../Cards/Watts";
import CurrentGraph from "../Graphs/Current";
import VoltageGraph from "../Graphs/Voltage";
import WattsGraph from "../Graphs/Watts";

export default function Power(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        {
          //current, voltage, watts
        }
        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <CurrentCard />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <VoltageCard />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <WattsCard />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <CurrentGraph />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <VoltageGraph />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <WattsGraph />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={4}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
