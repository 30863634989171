import { useEffect } from "react";
import { useParams } from "react-router-dom";
import OrganisationLogsTable from "../components/Tables/OrganisationLogs";
import fetchOrganisationLogs from "../Functions/fetchOrganisationLogs";
import { useSelector } from "react-redux";
import { Grid, Stack, useTheme } from "@mui/material";
import PageTitle from "../components/PageTitle";

export default function OrganisationLogs(props) {
  const params = useParams();
  const organisationID = params.id;

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const theme = useTheme();
  const isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    if (organisationID !== undefined) {
      fetchOrganisationLogs(organisationID);
    }
  }, [organisationID]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 1 }}
          >
            <PageTitle
              theme={theme}
              title={
                selectedOrganisation && selectedOrganisation.name
                  ? selectedOrganisation.name + " Logs"
                  : "Organisation Logs"
              }
              subTitle={"User action logs for this organisation"}
            />
          </Stack>
        </Grid>
      </Grid>
      <OrganisationLogsTable />
    </>
  );
}
