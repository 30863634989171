import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import TotalVolumeGraph from "../Graphs/TotalVolume";

export default function WaterTotalVolume(props) {
  const { field } = props;

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Total Volume History" />
      <CardContent>
        <TotalVolumeGraph field={field} />
      </CardContent>
    </Card>
  );
}
