import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Button,
  Stack,
  Grid,
  useTheme,
  Tooltip,
  Box,
  MenuItem,
  TextField,
} from "@mui/material";
import { Add, Refresh, Search } from "@mui/icons-material";

//functions
import { useTranslation } from "react-i18next";
import moment from "moment";
import AddDeviceDialog from "../components/Dialogs/addDevice";
import DeviceCard from "../components/Cards/Device";
import { formatDate } from "../Functions/formatDate";
import AddDeviceCard from "../components/Cards/AddDevice";
import { useSelector } from "react-redux";
import { fetchDevices } from "../Functions/fetchDevices";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import PageTitle from "../components/PageTitle";
import setTitle from "../Functions/setTitle";
import hasPermission from "../Functions/hasPermission";
import Hero from "../components/hero";

function Devices(props) {
  const theme = useTheme();

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const devices = useSelector((state) => state.devices);
  const user = useSelector((state) => state.user);
  const userOrganisationRoles = useSelector(
    (state) => state.userOrganisationRoles
  );
  const isMobile = useSelector((state) => state.isMobile);
  const [isXL, setIsXL] = useState(window.innerWidth > 2048);
  const [addDeviceOpen, setAddDeviceOpen] = useState(false);
  const [noDevices, setNoDevices] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage, setDevicesPerPage] = useState(
    localStorage.getItem("devicesPerPage")
      ? localStorage.getItem("devicesPerPage")
      : isXL
      ? 9
      : 10
  );
  const [totalPages, setTotalPages] = useState(
    devices && devices.length && devicesPerPage
      ? Math.ceil(devices.length / devicesPerPage)
      : 1
  );
  const [filter, setFilter] = useState("");

  useEffect(() => {
    function handleResize() {
      setIsXL(window.innerWidth > 2048);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [devicesPerPageOptions, setDevicesPerPageOptions] = useState([
    10, 20, 50, 100,
  ]);

  useEffect(() => {
    let devicesPerPagestoreage = localStorage.getItem("devicesPerPage") || 10;

    if (isXL) {
      setDevicesPerPageOptions([9, 18, 36, 72]);
      if (devicesPerPagestoreage === "10") {
        devicesPerPagestoreage = "9";
      } else if (devicesPerPagestoreage === "20") {
        devicesPerPagestoreage = "18";
      } else if (devicesPerPagestoreage === "50") {
        devicesPerPagestoreage = "36";
      } else if (devicesPerPagestoreage === "100") {
        devicesPerPagestoreage = "72";
      }
    } else {
      setDevicesPerPageOptions([10, 20, 50, 100]);
      if (devicesPerPagestoreage === "9") {
        devicesPerPagestoreage = "10";
      } else if (devicesPerPagestoreage === "18") {
        devicesPerPagestoreage = "20";
      } else if (devicesPerPagestoreage === "36") {
        devicesPerPagestoreage = "50";
      } else if (devicesPerPagestoreage === "72") {
        devicesPerPagestoreage = "100";
      }
    }

    localStorage.setItem("devicesPerPage", devicesPerPagestoreage);
    setDevicesPerPage(devicesPerPagestoreage);
  }, [isXL]);

  const { t } = useTranslation("common");

  useEffect(() => {
    //if any device is a tracker isTracker, show the map
    if (devices && devices.length) {
      setNoDevices(false);
    } else {
      setNoDevices(true);
    }
    if (selectedOrganisation) {
      if (selectedOrganisation && selectedOrganisation.name !== undefined) {
        setTitle(t("devices.title"), selectedOrganisation);
      }
    }
  }, [devices, selectedOrganisation, t]);

  //on devicesperpage change, update the total pages and set the current page to 1
  useEffect(() => {
    if (!devices || devices.length === 0) {
      return;
    }
    let filteredDevices = devices.filter((device) => {
      if (filter === "") {
        return device;
      } else {
        //check if name contains the filter
        if (device.name.toLowerCase().includes(filter.toLowerCase())) {
          return device;
        }
      }

      return null;
    });

    let totalPages = 1;

    if (
      filteredDevices &&
      filteredDevices.length > 0 &&
      devicesPerPage &&
      devicesPerPage > 0
    ) {
      totalPages = Math.ceil(filteredDevices.length / devicesPerPage);
    }

    setTotalPages(totalPages);
    setCurrentPage(1);
  }, [devicesPerPage, devices, filter]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              mt: 1,
              //verticalAlign: "middle",
              verticalAlign: "middle",
            }}
          >
            <PageTitle
              title={
                selectedOrganisation &&
                selectedOrganisation &&
                selectedOrganisation.name
                  ? selectedOrganisation.name + " " + t("devices.title")
                  : t("devices.title")
              }
              theme={theme}
              subTitle={
                (devices ? devices.length : 0) + " Devices in this Organisation"
              }
            />

            {!isMobile && devices && devices.length ? (
              <>
                <TextField
                  label="Search Devices"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  sx={{ mr: 1, width: "400px" }}
                  //search icon
                  InputProps={{
                    startAdornment: (
                      <Search
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "primary"
                              : "secondary",
                        }}
                      />
                    ),
                  }}
                ></TextField>
                <TextField
                  label="Devices Per Page"
                  value={devicesPerPage}
                  onChange={(e) => {
                    setDevicesPerPage(e.target.value);
                    //store the devices per page in local storage
                    localStorage.setItem("devicesPerPage", e.target.value);
                  }}
                  sx={{ minWidth: "100px", mr: 1 }}
                  select
                >
                  {devicesPerPageOptions &&
                    devicesPerPageOptions.length &&
                    devicesPerPageOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </TextField>
              </>
            ) : null}
            {
              // if user is not the owner of the organisation, don't show the add device button
              (selectedOrganisation &&
                user &&
                selectedOrganisation.owner_id === user.id) ||
              hasPermission(userOrganisationRoles, user.id, "add") ? (
                <Tooltip title="Add a Device" placement="top" arrow>
                  <Button
                    variant="outlined"
                    color={
                      theme.palette.mode === "dark" ? "primary" : "secondary"
                    }
                    sx={{ mr: 1, padding: 2 }}
                    onClick={() => {
                      setAddDeviceOpen(true);
                    }}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              ) : null
            }
            <Tooltip title="Refresh Devices" placement="top" arrow>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                sx={{ padding: 2 }}
                onClick={() => {
                  fetchDevices(localStorage.getItem("selectedOrganisationID"));
                  updateSnackbarMessage(
                    t("common.refreshed") +
                      " " +
                      formatDate(moment().toISOString())
                  );
                }}
              >
                <Refresh />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          <Hero />

          {devices && devices.length
            ? //return the devices based on the current page
              devices
                .filter((device) => {
                  if (filter === "") {
                    return device;
                  } else {
                    //check if name contains the filter
                    if (
                      device.name.toLowerCase().includes(filter.toLowerCase())
                    ) {
                      return device;
                    }

                    //check if dev_eui contains the filter
                    if (
                      device.dev_eui
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                    ) {
                      return device;
                    }

                    return null;
                  }
                })
                .slice(
                  (currentPage - 1) * devicesPerPage,
                  currentPage * devicesPerPage
                )
                .map((device) => {
                  return <DeviceCard device={device} key={device.id} />;
                })
            : null}

          {devices && devices.length ? (
            <Grid item xs={12}>
              <div
                style={{
                  //flex right
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ mr: 1 }}
                  color={
                    theme.palette.mode === "dark" ? "primary" : "secondary"
                  }
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  disabled={currentPage === 1}
                >
                  {t("common.previous")}
                </Button>

                {devices && devices.length > devicesPerPage ? (
                  //show a button for each page
                  Array.from(Array(totalPages), (e, i) => {
                    return (
                      <Button
                        sx={{ mr: 1 }}
                        key={i}
                        variant="outlined"
                        color={
                          theme.palette.mode === "dark"
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() => {
                          setCurrentPage(i + 1);
                        }}
                        disabled={currentPage === i + 1}
                      >
                        {i + 1}
                      </Button>
                    );
                  })
                ) : (
                  //show the current page
                  <Button
                    variant="outlined"
                    color={
                      theme.palette.mode === "dark" ? "primary" : "secondary"
                    }
                    disabled
                    sx={{ mr: 1 }}
                  >
                    {currentPage}
                  </Button>
                )}

                <Button
                  variant="outlined"
                  color={
                    theme.palette.mode === "dark" ? "primary" : "secondary"
                  }
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  disabled={currentPage === totalPages}
                >
                  {t("common.next")}
                </Button>
              </div>
            </Grid>
          ) : null}

          {noDevices ? (
            <AddDeviceCard setAddDeviceOpen={setAddDeviceOpen} />
          ) : null}
        </Grid>
      </Box>
      <AddDeviceDialog
        open={addDeviceOpen}
        handleClose={() => {
          setAddDeviceOpen(false);
          fetchDevices(localStorage.getItem("selectedOrganisationID"));
        }}
      />
    </>
  );
}

export default withAuthenticationRequired(Devices);
