import { putData } from "../API/Put";

export default async function editReport(reportId, data) {
  let result = putData(`/report/${reportId}`, data);

  if (result) {
    return true;
  } else {
    return false;
  }
}
