import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import TemperatureGuague from "../Guages/Temperature";
import TemperatureGraph from "../Graphs/Temperature";
import DoorOpenCard from "../Cards/DoorOpen";
import DoorOpenGraph from "../Graphs/DoorOpen";
import OpenCount from "../Cards/DoorCount";
import LastOpenedCard from "../Cards/LastOpened";
import FrameList from "../FrameList";

export default function DoorWindowWithTemp(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <DoorOpenCard />
          <LastOpenedCard />
          <OpenCount />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <DoorOpenGraph />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <TemperatureGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <TemperatureGraph useIsXL={false} />
        </Grid>

        <Grid item xs={12} md={!isShare ? 7 : 12} sx={{ mb: 1 }}>
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={5} sx={{ mb: 1 }}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
