import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { postData } from "../../API/Post";
import { fetchOrganisations } from "../../Functions/fetchOrganisations";
import { CONFIG } from "../../config";
import { fetchDeviceBrands } from "../../Functions/fetchDeviceBrands";
import fetchData from "../../API/Fetch";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function ManualKeysDialog(props) {
  const { open, handleClose } = props;
  const { t } = useTranslation("common");
  const [organisationID, setOrganisationID] = React.useState();
  const [organisations, setOrganisations] = React.useState();
  const [dev_eui, setDev_eui] = React.useState();
  const [app_eui, setApp_eui] = React.useState();
  const [app_key, setApp_key] = React.useState();
  const [name, setName] = React.useState();
  const [deviceBrand, setdeviceBrand] = React.useState();
  const [deviceBrands, setDeviceBrands] = React.useState();
  const [region, setRegion] = useState("EU868");
  const [lns_id, setLNSID] = useState();
  const [lns, setLNS] = useState([]);

  //TODO: when closed clear all fields
  // add working but giving fail message

  async function getOrganisations() {
    const response = await fetchOrganisations();
    setOrganisations(response);

    //set organisation id to first organisation
    if (response.length > 0) {
      setOrganisationID(response[0].id);
    }
  }

  async function getDeviceBrands() {
    const response = await fetchDeviceBrands();
    setDeviceBrands(response);
  }

  async function getLnss() {
    const response = await fetchData("/device/lns/");
    setLNS(response);

    //set lns id to first lns
    if (response.length > 0) {
      setLNSID(response[0].id);
    }
  }

  async function onboardManualKeys() {
    if (name === "") {
      alert("Please enter a name");
      return;
    }
    if (deviceBrand === "") {
      alert("Please enter a deviceBrand");
      return;
    }

    //check dev_eui is valid
    if (!dev_eui.match(/^[0-9a-fA-F]{16}$/)) {
      alert("Please enter a valid dev_eui");
      return;
    }
    //check app_eui is valid
    if (!app_eui.match(/^[0-9a-fA-F]{16}$/)) {
      alert("Please enter a valid app_eui");
      return;
    }
    //check app_key is valid
    if (!app_key.match(/^[0-9a-fA-F]{32}$/)) {
      alert("Please enter a valid app_key");
      return;
    }

    if (!lns_id) {
      updateSnackbarMessage("LNS cannot be blank");
      return;
    }

    const response = await postData("/device/manual-add", {
      organisation_id: organisationID,
      dev_eui: dev_eui,
      app_eui: app_eui,
      app_key: app_key,
      lns_id: lns_id,
      region: region,
      device_brand: deviceBrand.id,
      name: name,
    });

    if (response.status === 200) {
      updateSnackbarMessage("Device added successfully");
      handleClose();
    } else {
      alert("Device add failed");
    }
  }

  useEffect(() => {
    getOrganisations();
    getDeviceBrands();
    getLnss();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("common.manual_keys_title")}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t("common.manual_keys_intro", { endpoint: CONFIG.API_URL })}
        </DialogContentText>
        <TextField
          id="name"
          label={t("common.name")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <FormControl fullWidth>
          <TextField
            id="lns"
            label="LNS"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            select
            onChange={(e) => {
              setLNSID(e.target.value);
            }}
          >
            {lns &&
              lns.map((lns) => {
                return (
                  <MenuItem key={lns.id} value={lns.id}>
                    {lns.name}
                  </MenuItem>
                );
              })}
          </TextField>
        </FormControl>

        <TextField
          id="dev_eui"
          label={t("common.dev_eui")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => {
            setDev_eui(event.target.value);
          }}
        />
        <TextField
          id="app_eui"
          label={t("common.app_eui")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => {
            setApp_eui(event.target.value);
          }}
        />
        <TextField
          id="app_key"
          label={t("common.app_key")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => {
            setApp_key(event.target.value);
          }}
        />

        <Autocomplete
          id="deviceBrand"
          options={deviceBrands}
          getOptionLabel={(option) => option.description}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("common.device_type")}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
          onChange={(event, value) => {
            setdeviceBrand(value);
          }}
        />

        <FormControl fullWidth>
          <TextField
            id="Region"
            label="Region"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            select
            onChange={(e) => {
              setRegion(e.target.value);
            }}
          >
            <MenuItem key="EU868" value="EU868">
              EU868
            </MenuItem>
            <MenuItem key="US915" value="US915">
              US915
            </MenuItem>
            <MenuItem key="AU915" value="AU915">
              AU915
            </MenuItem>
          </TextField>
        </FormControl>

        <TextField
          id="organisation_id"
          label={t("common.organisation")}
          variant="outlined"
          fullWidth
          select
          margin="normal"
          onChange={(event) => {
            setOrganisationID(event.target.value);
          }}
          value={organisationID}
        >
          {organisations &&
            organisations.length &&
            organisations.map((organisation) => (
              <MenuItem key={organisation.id} value={organisation.id}>
                {organisation.name}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleClose()}
          color="secondary"
        >
          {t("common.close")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onboardManualKeys();
          }}
          color="primary"
        >
          {t("common.add_device")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
