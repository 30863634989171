import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";

export default function DryContactGraph(props) {
  const [dryContactHistory, setDryContactHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const theme = useTheme();

  const { field } = props;

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to dryContactHistory
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;
        let dryContact;

        if (field) {
          dryContact = jsonData[field];
        } else {
          dryContact = jsonData.state;
        }

        tempHistory.push({
          Date: time_created,
          DryContact: dryContact === 1 ? "Yes" : "No",
        });
      });
      setDryContactHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory, field]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={"Dry Contact"}
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "light" ? "#000" : "#616161",
        }}
        action={
          //get last value of dryContactHistory
          <Typography variant="h5">
            {dryContactHistory.length > 0
              ? dryContactHistory[dryContactHistory.length - 1].DryContact ===
                "Yes"
                ? "Yes"
                : "No"
              : "No Data"}
          </Typography>
        }
      />
      <CardContent>
        <ResponsiveContainer aspect={8}>
          <AreaChart
            data={dryContactHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="DryContact" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis dataKey="DryContact" type="category" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              //format label to capitalize first letter of each word and format value to use celsius symbol and format date

              formatter={(value, name) => {
                return [value, "Contact"];
              }}
            />
            <Area
              yAxisId="1"
              dataKey="DryContact"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#DryContact)"
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
