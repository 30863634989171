import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { putData } from "../../API/Put";
import fetchGeofences from "../../Functions/fetchGeofences";
import { useSelector } from "react-redux";

export default function GeofenceStatusDialog(props) {
  const { open, handleClose, selectedGeofences } = props;
  const { t } = useTranslation("common");

  const geofences = useSelector((state) => state.geofences);

  const [status, setStatus] = React.useState("active");

  //Update geofence status
  async function updateGeofenceStatus() {
    try {
      let word = status === "active" ? "activate" : "deactivate";

      // loop through props.selectedGeofences and delete each one
      for (let i = 0; i < selectedGeofences.length; i++) {
        await putData(`/geofence/${selectedGeofences[i]}/${word}`);

        updateSnackbarMessage(
          t("geofences.status_updated", { status: status })
        );

        fetchGeofences(localStorage.getItem("selectedOrganisationID"));
        handleClose();
      }
    } catch (error) {
      updateSnackbarMessage(t("geofences.status_update_error"));
      console.log(error);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("geofences.update_status_title")}</DialogTitle>
      <DialogContent>
        Change Status of the following Geofences:
        <ul>
          {selectedGeofences.map((geofence) => {
            let geofenceName = geofences.find(
              (g) => g.id === parseInt(geofence)
            ).name;
            return <li key={geofence}>{geofenceName}</li>;
          })}
        </ul>
        {
          // dropdown for active/inactive
        }
        <FormControl fullWidth sx={{ mt: 1 }} variant="outlined" size="small">
          <TextField
            id="outlined-select-currency"
            select
            label={t("common.status")}
            onChange={(e) => setStatus(e.target.value)}
            value={status}
          >
            <MenuItem key="active" value="active">
              {t("common.active")}
            </MenuItem>
            <MenuItem key="inactive" value="inactive">
              {t("common.inactive")}
            </MenuItem>
          </TextField>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            updateGeofenceStatus();
          }}
        >
          {t("common.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
