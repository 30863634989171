import { postData } from "../API/Post";
import { store } from "../store";

export const fetchUser = async (email, sub) => {
  //check email and sub are not null
  if (!email || !sub) return;

  const response = await postData("/user/", {
    email: email,
    sub: sub,
  });

  if (response.data) {
    //dispatch to users/update reducer
    store.dispatch({ type: "user/update", payload: response.data });
  }
};
