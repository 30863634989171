import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";

import MotionGraph from "../Graphs/Motion";
import FrameList from "../FrameList";
import MotionCardNBIOT from "../Cards/MotionNBIOT";
import DistanceGraph from "../Graphs/Distance";
import Rssi from "../Cards/Rssi";

export default function MotionNBIOT(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mt: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mt: 1 }}>
          <BatteryStatus />
        </Grid>

        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mt: 1 }}>
          <Rssi />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={3} sx={{ mt: 1 }}>
              <LastAlert />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3} sx={{ mt: 1 }}>
          <MotionCardNBIOT />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mt: 1 }}>
          <MotionGraph field="state" />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
          <DistanceGraph />
        </Grid>

        <Grid item xs={12} md={!isShare ? 6 : 12} sx={{ mt: 1 }}>
          <FrameList state="Motion" />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
