import deleteData from "../API/Delete";

export default async function deleteReport(reportId) {
  try {
    if (!reportId) {
      return false;
    }
    const response = await deleteData(`/report/${reportId}`);

    if (response.status && response.status === 200) {
      return true;
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}
