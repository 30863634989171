import { putData } from "../API/Put";
import { fetchDevices } from "./fetchDevices";

export async function updateDeviceXY(deviceID, x, y, selectedOrganisation) {
  let data = {
    x: x,
    y: y,
  };
  let response = await putData(`/device/${deviceID}/xy`, data);

  fetchDevices(selectedOrganisation.id);

  return response;
}
