import { MoreVert } from "@mui/icons-material";
import { Box, Card, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import WidgetEditOptions from "../widgetEditOptions";

export default function TitleWidget(props) {
  const { widget, editMode, dashboardId, refreshDashboard, isShare } = props;
  const theme = useTheme();

  let title = "";
  let align = "left";
  let fontSize = "1em";

  const [widgetMenuOpen, setWidgetMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    setWidgetMenuOpen(false);
  };

  if (widget && widget.field && widget.field !== "") {
    //decode the field object
    let field = JSON.parse(widget.field);
    title = field["Title"];
    align = field["Alignment"];
    fontSize = field["Font Size"] + "em";
  }

  if (editMode) {
    return (
      <Card
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: align,
          alignItems: align,
        }}
      >
        <Typography
          component="p"
          color={theme.palette.mode === "dark" ? "primary" : "black"}
          sx={{ fontSize: fontSize, fontWeight: 600 }}
        >
          {title}
        </Typography>
      </Card>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: align,
          alignItems: align,
        }}
      >
        <Typography
          variant="h4"
          component="p"
          color={theme.palette.mode === "dark" ? "primary" : "black"}
          sx={{ fontSize: fontSize, fontWeight: 600 }}
        >
          {title}
        </Typography>
        {isShare ? null : editMode ? null : ( //if not in edit mode, don't show the delete button
          //show a options menu, three vertical dots
          <IconButton
            sx={{
              p: 0,
            }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setWidgetMenuOpen(true);
            }}
          >
            <MoreVert />
          </IconButton>
        )}
      </Box>
      <WidgetEditOptions
        anchorEl={anchorEl}
        handleClose={handleClose}
        widget={widget}
        widgetMenuOpen={widgetMenuOpen}
        dashboardId={dashboardId}
        refreshDashboard={refreshDashboard}
      />
    </>
  );
}
