import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { formatDate } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function DistanceGraph(props) {
  const [distanceHistory, setDistanceHistory] = React.useState([]);

  const theme = useTheme();

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      let distanceHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;
        let distance = jsonData.distance;

        distanceHistory.push({
          Date: time_created,
          distance: distance,
        });
      });
      setDistanceHistory(distanceHistory);
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Distance History" />
      <CardContent>
        <ResponsiveContainer aspect={8}>
          <LineChart data={distanceHistory}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDate(value);
              }}
            />
            <YAxis dataKey="distance" type="number" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              formatter={(value, name) => {
                return [value + "mm", "Distance"];
              }}
            />
            <Line
              yAxisId="1"
              type="monotone"
              dataKey="distance"
              //use theme color for line
              stroke={theme.palette.primary.main}
              animationDuration={300}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
