const initialState = [];

function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case "reports/add":
      return [...state.value, action.payload];
    case "reports/remove":
      return state.value.filter((device) => device.id !== action.payload);
    case "reports/update":
      return action.payload;
    default:
      return state;
  }
}

export default reportsReducer;
