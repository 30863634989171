import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { useSelector } from "react-redux";
import deleteSelectedActions from "../../Functions/deleteSelectedActions";

const ActionsDeleteDialog = (props) => {
  const { t } = useTranslation("common");

  const { selectedActions } = props;

  const actions = useSelector((state) => state.actions);

  async function deleteAction() {
    try {
      deleteSelectedActions(selectedActions);
      updateSnackbarMessage(t("actions.delete_success"));
    } catch (error) {
      console.log(error);
      updateSnackbarMessage(t("actions.delete_error"));
    } finally {
      props.handleClose();
    }
  }

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>{t("actions.delete_title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("actions.delete_confirmation")}
        </DialogContentText>
        <List>
          {actions &&
            actions.length > 0 &&
            actions.map((action) => {
              if (selectedActions.includes(action.id)) {
                return (
                  <ListItem key={action.id + "-selected-fowarder-item"}>
                    <ListItemText
                      key={action.id + "-selected-action-item-text"}
                      primary={action.name}
                    />
                  </ListItem>
                );
              } else {
                return null;
              }
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => props.handleClose()}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteAction();
          }}
        >
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionsDeleteDialog;
