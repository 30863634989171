import moment from "moment";
import { store } from "../store";

const updateDateRange = (startDateUnix, endDateUnix) => {
  //if either are empty, generate a new date range
  if (!startDateUnix || !endDateUnix) {
    //start date, 24 hours ago in unix timestamp
    startDateUnix = moment().utc().subtract(24, "hours").unix();
    //end date, now in unix timestamp
    endDateUnix = moment().utc().unix();
  }

  //check if start date and end date are unix timestamps, if not, convert them
  if (startDateUnix._isAMomentObject) {
    startDateUnix = startDateUnix.unix();
  }

  if (endDateUnix._isAMomentObject) {
    endDateUnix = endDateUnix.unix();
  }

  store.dispatch({
    type: "dateRange/update",
    payload: {
      startDate: startDateUnix,
      endDate: endDateUnix,
    },
  });
};

export default updateDateRange;
