export const CONFIG = {
  API_URL: "https://api.cargotwins.com",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/clkha3odl008i01qpeqws6d21",
  siteTitle: "CargoTwins",
  siteTitleDescription:
    "CargoTwins is a GPS tracking platform for your cargo and assets.",
  battery_minMV: 2300,
  battery_maxMV: 3000,
  shareUrl: "https://app.cargotwins.com/share/",
  dashboardShareUrl: "https://app.cargotwins.com/share/dashboard/",
  websiteURL: "https://app.cargotwins.com",
  Auth0Domain: "cargotwin.us.auth0.com",
  Auth0ClientId: "IkOyTVin3sMb9Zlaj9zMppoBPRBAdGce",
  Auth0Audience: "https://cargotwin-api.trackpac.io",
  primaryColour: "#db80bb",
  primaryColourRGB: {
    r: 219,
    g: 30,
    b: 187,
  },
  secondaryColour: "#4287f5",
  defaultTheme: "light",
  defaultLanguage: "en",
  locale: "en",
  organisation_enabled: false,
  billing_enabled: false,
  manual_add: false,
  self_host: false,
  feedback_enabled: false,
  feedback_url: "https://trackpac.canny.io/feature-device-requests",
  show_geofences: true,
  api_key_enabled: false,
  register_card_enabled: false,
  defaultDateFormat: "MM/DD/YYYY",
  show_buy_link: false,
  buy_link: "https://hexaspot.com/collections/trackpac",
};
