import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import moment from "moment";
import "moment-timezone";

import editReport from "../../Functions/editReport";
import { formatTimeOnly } from "../../Functions/formatTimeOnly";
export default function ReportEditDialog(props) {
  const { t } = useTranslation("common");

  const { open, handleClose, report } = props;
  const [name, setName] = useState("New Report");
  const [schedule, setSchedule] = useState("7");
  const [scheduleTime, setScheduleTime] = useState("09:00");
  const [reportDevices, setReportDevices] = useState([]);
  const [reportContacts, setReportContacts] = useState([]);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const contacts = useSelector((state) => state.contacts);
  const devices = useSelector((state) => state.devices);
  const reports = useSelector((state) => state.reports);

  const handleEdit = () => {
    if (name === "") {
      updateSnackbarMessage("Error: Name is required");
      return;
    }

    //check there is more than one device, and one contact
    if (reportDevices.length < 1) {
      updateSnackbarMessage("Error: At least one device is required");
      return;
    }

    //reportDevices and reportContacts will be an array of objects, get the id's and create a new array for each
    let deviceIDs = reportDevices.map((device) => device.id);
    let contactIDs = reportContacts.map((contact) => contact.id);

    //we need to pass in a UTC time to the backend, so we need to use moment to find out the hour offset, and then subtract that from the local time
    const localTimeMoment = moment(scheduleTime, "HH:mm");
    const timezone = moment.tz.guess();
    const offset = moment.tz.zone(timezone).utcOffset(localTimeMoment);
    const utcTime = moment.utc(localTimeMoment).subtract(offset, "minutes");

    //we only want HH:MM
    const utcTimeFormatted = utcTime.format("HH:mm");

    // Continue with the rest of the code...
    let result = editReport(
      name,
      schedule,
      utcTimeFormatted,
      deviceIDs,
      contactIDs,
      selectedOrganisation.id
    );

    if (result) {
      updateSnackbarMessage("Success: Report Updated");
      handleClose();
    } else {
      updateSnackbarMessage("Error: Report Update Failed");
    }
  };

  useEffect(() => {
    if (report && report.id) {
      setName(report.name);
      setSchedule(report.schedule_id);
      setScheduleTime(formatTimeOnly(report.scheduled_time));

      let reportDevices = [];
      let reportContacts = [];

      if (report.devices) {
        //we only want a list of device ids
        reportDevices = report.devices.map((device) => {
          return devices.find((d) => d.id === device.device_id);
        });

        setReportDevices(reportDevices);
      }

      if (report.contacts) {
        //we only want a list of contact ids
        reportContacts = report.contacts.map((contact) => {
          return contacts.find((c) => c.id === contact.contact_id);
        });

        setReportContacts(reportContacts);
      }
    }
  }, [report, reports, devices, contacts]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{t("reports.edit")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={t("common.name")}
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          margin="dense"
          id="schedule"
          label={t("reports.schedule")}
          type="text"
          fullWidth
          value={schedule}
          select
          onChange={(e) => setSchedule(e.target.value)}
        >
          <MenuItem value="1">Daily</MenuItem>
          <MenuItem value="7">Weekly</MenuItem>
          <MenuItem value="30">Monthly</MenuItem>
        </TextField>

        <TextField
          margin="dense"
          id="scheduled_time"
          label={t("reports.schedule_time")}
          type="text"
          fullWidth
          value={scheduleTime}
          onChange={(e) => setScheduleTime(e.target.value)}
          select
        >
          <MenuItem value="00:00">00:00</MenuItem>
          <MenuItem value="01:00">01:00</MenuItem>
          <MenuItem value="02:00">02:00</MenuItem>
          <MenuItem value="03:00">03:00</MenuItem>
          <MenuItem value="04:00">04:00</MenuItem>
          <MenuItem value="05:00">05:00</MenuItem>
          <MenuItem value="06:00">06:00</MenuItem>
          <MenuItem value="07:00">07:00</MenuItem>
          <MenuItem value="08:00">08:00</MenuItem>
          <MenuItem value="09:00">09:00</MenuItem>
          <MenuItem value="10:00">10:00</MenuItem>
          <MenuItem value="11:00">11:00</MenuItem>
          <MenuItem value="12:00">12:00</MenuItem>
          <MenuItem value="13:00">13:00</MenuItem>
          <MenuItem value="14:00">14:00</MenuItem>
          <MenuItem value="15:00">15:00</MenuItem>
          <MenuItem value="16:00">16:00</MenuItem>
          <MenuItem value="17:00">17:00</MenuItem>
          <MenuItem value="18:00">18:00</MenuItem>
          <MenuItem value="19:00">19:00</MenuItem>
          <MenuItem value="20:00">20:00</MenuItem>
          <MenuItem value="21:00">21:00</MenuItem>
          <MenuItem value="22:00">22:00</MenuItem>
          <MenuItem value="23:00">23:00</MenuItem>
        </TextField>

        <Autocomplete
          multiple
          id="tags-standard"
          options={devices}
          value={reportDevices}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Devices"
            />
          )}
          onChange={(event, value) => {
            setReportDevices(value);
          }}
          sx={{ my: 1 }}
        />

        {
          //recipients
        }
        <Autocomplete
          multiple
          id="tags-standard"
          options={contacts}
          value={reportContacts}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Recipients"
            />
          )}
          onChange={(event, value) => {
            setReportContacts(value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleEdit}>
          {t("common.edit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
