import deleteData from "../API/Delete";

export default async function deleteDashboard(dashboardId) {
  if (!dashboardId) {
    return false;
  }
  const response = await deleteData(`/dashboard/${dashboardId}`);

  if (response.status === 200) {
    return true;
  }

  return false;
}
