import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { putData } from "../../API/Put";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function DeviceTransferDialog(props) {
  const { open, handleClose } = props;

  const organisations = useSelector((state) => state.organisations);
  const device = useSelector((state) => state.device);

  const { t } = useTranslation("common");

  const [newOrganisation, setNewOrganisation] = React.useState(null);

  async function transferDevice() {
    try {
      let response = await putData(`/device/${device.id}/transfer`, {
        organisation_id: newOrganisation["id"],
      });

      if (response.status === 200) {
        updateSnackbarMessage(t("devices.transfer_success"));
        handleClose();
      } else {
        updateSnackbarMessage(
          `${t("devices.transfer_error")} ${JSON.stringify(response)}`
        );
      }
    } catch (error) {
      console.error(error);
      updateSnackbarMessage(t("devices.transfer_error"));
    }
  }

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>{t("devices.transfer_title")}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{t("devices.transfer_info")}</Typography>
        {organisations && organisations.length ? (
          <Autocomplete
            id="select-organisation"
            options={organisations}
            getOptionLabel={(option) => option.name}
            sx={{ mt: 2 }}
            value={newOrganisation}
            onChange={(event, newValue) => {
              setNewOrganisation(newValue);
            }}
            renderTags={(value) =>
              value.map((value, index) => (
                <Chip variant="outlined" key={index} label={value.name} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t("common.organisation")}
                placeholder={t("common.organisation")}
                sx={{ mb: 1 }}
                onChange={(event) => {
                  setNewOrganisation(event.target.value);
                }}
                fullWidth
                rows={4}
              />
            )}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClose()}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            transferDevice();
          }}
        >
          {t("common.transfer")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
