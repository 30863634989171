import mapboxgl from "mapbox-gl";
import { formatDate } from "./formatDate";
import createGeoJSONCircleFeature from "./createGeoJSONCircleFeature";
import formatTemperature from "./formatTemperature";
import formatSpeed from "./formatSpeed";
import { updateCurrent } from "./updateCurrent";

//add markers for history
export default async function addHistoryMarkers(mapRef, deviceHistory) {
  if (!mapRef) return;

  //if deviceHistory is empty, return
  if (!deviceHistory || !deviceHistory || deviceHistory.length < 1) return;

  //get features from history.data after json parsing
  let features = [];

  deviceHistory.forEach((item, index) => {
    let data = item.data;
    //json parse data
    if (typeof data === "string") {
      data = JSON.parse(data);
    }

    //return coordinates
    features.push(
      createGeoJSONCircleFeature(
        [data.longitude, data.latitude],
        5,
        64,
        data.geocode,
        item.time_created,
        index,
        item.data
      )
    );
  });

  //create a geojson object from the features array
  features = {
    type: "FeatureCollection",
    features: features,
  };

  // if features.features.length === 1. empty the array
  if (features.features.length === 1) {
    features.features = [];
  }

  if (mapRef.loaded()) {
    if (!mapRef.getSource("device-history-markers")) {
      //add a source for device history markers
      mapRef.addSource("device-history-markers", {
        type: "geojson",
        data: features,
      });

      //add a layer for device history markers to use standard marker pin
      mapRef.addLayer({
        id: "device-history-markers-layer",
        type: "fill",
        source: "device-history-markers",
        paint: {
          "fill-color": "#4ecca3",
          "fill-opacity": 0.5,
          "fill-outline-color": "#004d40",
          //hide overflows
          "fill-antialias": false,
        },
        //hide overflows
        layout: {
          visibility: "visible",
        },

        filter: ["==", "$type", "Polygon"],
      });

      //onclick of dot-point, fly to location and show popup
      mapRef.on("click", "device-history-markers-layer", function (e) {
        let latitude = e.features[0].properties.latitude;
        let longitude = e.features[0].properties.longitude;

        updateCurrent(e.features[0].properties.index);

        //parse json data
        let data = JSON.parse(e.features[0].properties.data);

        //for each key in data, write html with key and value
        let html = "";

        for (const [key, value] of Object.entries(data)) {
          //if key is latitude or longitude or batteryMv or accuracy or geocode, skip
          if (
            key === "latitude" ||
            key === "longitude" ||
            key === "batteryMv" ||
            key === "accuracy" ||
            key === "geocode" ||
            key === "wifi_macs" ||
            key === "uplink" ||
            key === "downlink" ||
            key === "dont_log_frame" ||
            key === "voltageMv" ||
            key === "collected_at"
          ) {
            continue;
          }
          if (key === "temperature") {
            if (value === null) {
              continue;
            }
            html += `<p><strong>Temperature:</strong> ${formatTemperature(
              value
            )}</p>`;
            continue;
          }

          if (key === "speedKmph") {
            if (value === null) {
              continue;
            }
            html += `<p><strong>Speed:</strong> ${formatSpeed(value)}</p>`;
            continue;
          }

          if (key === "headingDeg") {
            if (value === null) {
              continue;
            }
            html += `<p><strong>Heading:</strong> ${value}°</p>`;
            continue;
          }

          if (key === "humidity") {
            if (value === null) {
              continue;
            }
            html += `<p><strong>Humidity:</strong> ${value}%</p>`;
            continue;
          }

          if (key === "co2") {
            if (value === null) {
              continue;
            }
            html += `<p><strong>CO2:</strong> ${value}ppm</p>`;
            continue;
          }

          if (key === "button_pressed") {
            if (value === null) {
              continue;
            }
            html += `<p><strong>Button Pressed:</strong> ${
              value === 1 ? "Yes" : "No"
            }</p>`;
            continue;
          }

          if (key === "light") {
            if (value === null) {
              continue;
            }
            html += `<p><strong>Light:</strong> ${value}%</p>`;
            continue;
          }

          if (key === "event_status") {
            if (value === null) {
              continue;
            }
            //if value is null or blank, return no event
            if (value === "") {
              html += `<p><strong>Event Status:</strong> No Event</p>`;
              continue;
            }
            html += `<p><strong>Event Status:</strong> ${value}</p>`;
            continue;
          }

          if (key === "location_resolution_method") {
            if (value === null) {
              continue;
            }
            html += `<p><strong>Location Resolution Method:</strong> ${value}</p>`;
            continue;
          }

          html += `<p><strong>${key}:</strong> ${value}</p>`;
        }

        new mapboxgl.Popup()
          .setLngLat([longitude, latitude])
          .setHTML(
            "<h3>" +
              formatDate(e.features[0].properties.time) +
              "</h3><p><strong>Location:</strong><br /> " +
              e.features[0].properties.title +
              "</p>" +
              html
          )
          .addTo(mapRef);

        mapRef.flyTo({
          center: [longitude, latitude],
        });
      });
    }
  } else {
    mapRef.on("load", async function () {
      if (!mapRef.getSource("device-history-markers")) {
        //add a source for device history markers
        mapRef.addSource("device-history-markers", {
          type: "geojson",
          data: features,
        });

        //add a layer for device history markers to use standard marker pin
        mapRef.addLayer({
          id: "device-history-markers-layer",
          type: "fill",
          source: "device-history-markers",
          paint: {
            "fill-color": "#4ecca3",
            "fill-opacity": 0.5,
            "fill-outline-color": "#004d40",
            //hide overflows
            "fill-antialias": false,
          },
          //hide overflows
          layout: {
            visibility: "visible",
          },

          filter: ["==", "$type", "Polygon"],
        });

        //onclick of dot-point, fly to location and show popup
        mapRef.on("click", "device-history-markers-layer", function (e) {
          let latitude = e.features[0].properties.latitude;
          let longitude = e.features[0].properties.longitude;

          updateCurrent(e.features[0].properties.index);

          //parse json data
          let data = JSON.parse(e.features[0].properties.data);

          //for each key in data, write html with key and value
          let html = "";

          for (const [key, value] of Object.entries(data)) {
            //if key is latitude or longitude or batteryMv or accuracy or geocode, skip
            if (
              key === "latitude" ||
              key === "longitude" ||
              key === "batteryMv" ||
              key === "accuracy" ||
              key === "geocode" ||
              key === "wifi_macs" ||
              key === "uplink" ||
              key === "downlink" ||
              key === "dont_log_frame" ||
              key === "voltageMv" ||
              key === "collected_at"
            ) {
              continue;
            }
            if (key === "temperature") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Temperature:</strong> ${formatTemperature(
                value
              )}</p>`;
              continue;
            }

            if (key === "speedKmph") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Speed:</strong> ${formatSpeed(value)}</p>`;
              continue;
            }

            if (key === "headingDeg") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Heading:</strong> ${value}°</p>`;
              continue;
            }

            if (key === "humidity") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Humidity:</strong> ${value}%</p>`;
              continue;
            }

            if (key === "co2") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>CO2:</strong> ${value}ppm</p>`;
              continue;
            }

            if (key === "button_pressed") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Button Pressed:</strong> ${
                value === 1 ? "Yes" : "No"
              }</p>`;
              continue;
            }

            if (key === "light") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Light:</strong> ${value}%</p>`;
              continue;
            }

            if (key === "event_status") {
              if (value === null) {
                continue;
              }
              //if value is null or blank, return no event
              if (value === "") {
                html += `<p><strong>Event Status:</strong> No Event</p>`;
                continue;
              }
              html += `<p><strong>Event Status:</strong> ${value}</p>`;
              continue;
            }

            if (key === "location_resolution_method") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Location Resolution Method:</strong> ${value}</p>`;
              continue;
            }

            html += `<p><strong>${key}:</strong> ${value}</p>`;
          }

          new mapboxgl.Popup()
            .setLngLat([longitude, latitude])
            .setHTML(
              "<h3>" +
                formatDate(e.features[0].properties.time) +
                "</h3><p><strong>Location:</strong><br /> " +
                e.features[0].properties.title +
                "</p>" +
                html
            )
            .addTo(mapRef);

          mapRef.flyTo({
            center: [longitude, latitude],
          });
        });
      }
    });
  }
}
