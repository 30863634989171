import { Menu, MenuItem } from "@mui/material";
import EditWidgetDialog from "./Dialogs/editWidget";
import DeleteWidgetConfirmationDialog from "./Dialogs/WidgetDelete";
import { useState } from "react";

export default function WidgetEditOptions(props) {
  const {
    widget,
    dashboardId,
    refreshDashboard,
    anchorEl,
    handleClose,
    widgetMenuOpen,
  } = props;

  const [deleteWidgetOpen, setDeleteWidgetOpen] = useState(false);
  const [editWidgetOpen, setEditWidgetOpen] = useState(false);

  return (
    <>
      <DeleteWidgetConfirmationDialog
        open={deleteWidgetOpen}
        widget={widget}
        dashboardId={dashboardId}
        handleClose={() => setDeleteWidgetOpen(false)}
        refreshDashboard={refreshDashboard}
      />
      <EditWidgetDialog
        open={editWidgetOpen}
        widget={widget}
        handleClose={() => setEditWidgetOpen(false)}
        refreshDashboard={refreshDashboard}
      />

      <Menu anchorEl={anchorEl} open={widgetMenuOpen} onClose={handleClose}>
        <MenuItem onClick={() => setEditWidgetOpen(true)}>Edit</MenuItem>
        <MenuItem onClick={() => setDeleteWidgetOpen(true)}>Delete</MenuItem>
      </Menu>
    </>
  );
}
