import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";

export default function CurrentGraph(props) {
  const [currentHistory, setCurrentHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const theme = useTheme();

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to currentHistory
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let current = jsonData.current;

        tempHistory.push({
          Date: time_created,
          Current: current,
        });
      });
      setCurrentHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={"Current History"}
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "light" ? "#000" : "#616161",
        }}
        action={
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            {
              //get last current value and format it with A
              currentHistory.length > 0
                ? currentHistory[currentHistory.length - 1].Current.toFixed(1) +
                  "A"
                : "No Data"
            }
          </Typography>
        }
      />
      <CardContent>
        <ResponsiveContainer aspect={8}>
          <AreaChart
            data={currentHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="Current" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis dataKey="Current" type="number" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              formatter={(value, name) => {
                return [value + " A", "Current"];
              }}
            />
            <Area
              yAxisId="1"
              dataKey="Current"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Current)"
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
