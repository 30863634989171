import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import fetchData from "../API/Fetch";
import { CreditCard } from "@mui/icons-material";
import setTitle from "../Functions/setTitle";
import { useTheme } from "@emotion/react";
import PageTitle from "../components/PageTitle";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";

export default function RegisterCard() {
  const openCardCaptureSession = () => {
    fetchData(`/billing/card-capture-session`).then((data) => {
      //redirect to url
      if (data && data.url) {
        window.location.href = data.url;
      } else {
        updateSnackbarMessage("Error creating card capture session", "error");
      }
    });
  };

  const theme = useTheme();

  useEffect(() => {
    setTitle("Register a Card");
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
          <PageTitle title="Register a Card" theme={theme} />
          <Card sx={{ mt: 1 }} maxWidth="lg">
            <CardContent>
              <Typography variant="body1">
                A card must be registered on your account for billing to use the
                Trackpac service.
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Please click the button below to be taken to stripe to register
                a card.
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                No funds will be taken from your card until you have exceeded
                your device limit.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => openCardCaptureSession()}
                startIcon={<CreditCard />}
              >
                Register A Card
              </Button>
            </CardActions>
          </Card>
        </Stack>
      </Box>
    </>
  );
}
