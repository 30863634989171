import BooleanWidget from "./Boolean";
import BreakWidget from "./break";
import DeviceCardWidget from "./Device";
import FluidLevelWidget from "./FluidLevel";
import GraphWidget from "./Graph";
import IconCardWidget from "./IconCard";
import IframeWidget from "./iframe";
import ImageWidget from "./image";
import MapWidget from "./Map";
import SwitchWidget from "./Switch";
import TitleWidget from "./Title";

export default function DashboardWidget(props) {
  const { widget, devices, editMode, dashboardId, refreshDashboard, isShare } =
    props;

  const widgets = {
    2: IconCardWidget,
    3: MapWidget,
    5: TitleWidget,
    6: DeviceCardWidget,
    7: BooleanWidget,
    8: BreakWidget,
    9: FluidLevelWidget,
    10: GraphWidget,
    11: SwitchWidget,
    12: IframeWidget,
    13: ImageWidget,
  };

  const Widget = widgets[widget.widget_id];

  return (
    <Widget
      widget={widget}
      devices={devices}
      editMode={editMode}
      dashboardId={dashboardId}
      refreshDashboard={refreshDashboard}
      isShare={isShare}
    />
  );
}
