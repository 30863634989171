import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { CONFIG } from "../config";

const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <Auth0Provider
      domain={CONFIG.Auth0Domain}
      clientId={CONFIG.Auth0ClientId}
      redirectUri={window.location.origin}
      audience={CONFIG.Auth0Audience}
      cacheLocation="localstorage" // <-- add this config
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
