import { Card, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function DeviceCardPanel(props) {
  const isMobile = useSelector((state) => state.isMobile);
  const { title, content, isWidget } = props;

  let isW = isWidget;
  //if isWidget is nothing, then it is a normal card, set isWidget to false
  if (!isWidget) {
    isW = false;
  }

  const theme = useTheme();
  return (
    <Tooltip
      sx={{ height: "100%" }}
      title={title + ": " + content}
      position="top"
      arrow
    >
      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1f1f1f" : "#f0f0f0",
          p: 1,
          mb: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            fontSize:
              //if its a widget and isMobile is true, set the font size to 0.5rem, otherwise set it to 1rem
              isW && isMobile ? "0.5rem" : "1rem",
          }}
        >
          {title}
        </Typography>

        <Typography
          variant="p"
          noWrap={isMobile ? false : true}
          sx={{
            fontSize: "1rem",
            mt: 2,
            pr: 1,
            wordWrap: isMobile ? "break-word" : "normal",
          }}
        >
          {content}
        </Typography>
      </Card>
    </Tooltip>
  );
}
