import React from "react";

import FrameList from "../FrameList";
import DeviceActionLogs from "../Cards/Alerts";
import { Grid } from "@mui/material";
import TemperatureGraph from "../Graphs/Temperature";
import TemperatureGuague from "../Guages/Temperature";

export default function StickerTracker(props) {
  const { isShare } = props;

  return (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      <Grid item xs={12} md={3} xxl={3} sx={{ mb: 1 }}>
        <TemperatureGuague />
      </Grid>

      <Grid item xs={12} md={9} xxl={9} sx={{ mb: 1 }}>
        <TemperatureGraph useIsXL={false} />
      </Grid>
      <Grid item xs={12} md={!isShare ? 6 : 12} sx={{ mb: 1 }}>
        <FrameList />
      </Grid>
      {!isShare && (
        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <DeviceActionLogs />
        </Grid>
      )}
    </Grid>
  );
}
