import { useSelector } from "react-redux";
import DataTableBase from "../DataTableBase";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { fetchReports } from "../../Functions/fetchReports";
import { useState } from "react";
import { formatDate } from "../../Functions/formatDate";

import ReportEditDialog from "../../components/Dialogs/reportEdit";
import DeleteReportConfirmationDialog from "../Dialogs/reportDelete";

export default function ReportsTable() {
  const reports = useSelector((state) => state.reports);
  const { t } = useTranslation("common");

  const [deleteReportConfirmationOpen, setDeleteReportConfirmationOpen] =
    useState(false);
  const [reportId, setReportId] = useState(null);
  const [reportName, setReportName] = useState("");

  const [editReportDialogOpen, setEditReportDialogOpen] = useState(false);
  const [report, setReport] = useState({});

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  //columns for devices for data table
  const columns = [
    {
      name: "id",
      label: t("common.ID"),
      options: {
        display: false,
      },
    },
    {
      name: "name",
      options: {
        label: t("common.name"),
        customBodyRender: (value, tableMeta, meta) => {
          return (
            <Typography
              onClick={() => {
                window.location.href = "/report/" + tableMeta.rowData[0];
              }}
              className="cursor-pointer"
              variant="body1"
              color="primary"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "schedule_id",
      label: t("common.schedule"),
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2">
              {value === 1
                ? "Daily"
                : value === 7
                ? "Weekly"
                : value === 30
                ? "Monthly"
                : "N/A"}
            </Typography>
          );
        },
      },
    },
    {
      name: "schedule_time",
      label: t("common.schedule_time"),
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2">{value ? value : "N/A"}</Typography>
          );
        },
      },
    },
    // time_updated
    {
      name: "time_updated",
      label: t("common.time_updated"),
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2">
              {value ? formatDate(value) : "N/A"}
            </Typography>
          );
        },
      },
    },
    // time_created
    {
      name: "time_created",
      label: t("common.time_created"),
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2">{formatDate(value)}</Typography>;
        },
      },
    },
    //options
    {
      name: "options",
      label: "Options",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setReport(
                    reports.find((report) => report.id === tableMeta.rowData[0])
                  );
                  setEditReportDialogOpen(true);
                }}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setDeleteReportConfirmationOpen(true);
                  setReportId(tableMeta.rowData[0]);
                  setReportName(tableMeta.rowData[1]);
                }}
              >
                Delete
              </Button>
            </Stack>
          );
        },
      },
    },
  ];

  return (
    <Box sx={{ mt: 1 }}>
      <DataTableBase
        title={t("reports.index")}
        data={reports}
        columns={columns}
      />
      <DeleteReportConfirmationDialog
        open={deleteReportConfirmationOpen}
        handleClose={() => {
          setDeleteReportConfirmationOpen(false);
          //wait 1 second before fetching reports
          setTimeout(() => {
            fetchReports(selectedOrganisation.id);
          }, 1000);
        }}
        refreshReport={() => fetchReports(selectedOrganisation.id)}
        reportId={reportId}
        reportName={reportName}
      />
      <ReportEditDialog
        open={editReportDialogOpen}
        handleClose={() => setEditReportDialogOpen(false)}
        report={report}
      />
    </Box>
  );
}
