import { useTheme } from "@emotion/react";
import { Card, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function HumidityGraph(props) {
  const [humidityHistory, setHumidityHistory] = React.useState([]);

  const theme = useTheme();

  const { humidityValue, aspect } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let humidity = 0;

        if (humidityValue) {
          humidity = jsonData[humidityValue];
        } else {
          humidity = jsonData.humidity;
        }

        tempHistory.push({
          Date: time_created,
          Humidity: humidity,
        });
      });
      setHumidityHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory, humidityValue]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Humidity History" />
      <ResponsiveContainer aspect={aspect ? aspect : !isMobile ? 5 : 2}>
        <AreaChart
          data={humidityHistory}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <defs>
            <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="Date"
            type={"category"} //format date to be more readable
            tickFormatter={(value) => {
              return formatDateOnly(value);
            }}
          />
          <YAxis dataKey="Humidity" type="number" yAxisId="1" />
          <Tooltip
            contentStyle={{
              backgroundColor: theme.palette.background.default,
            }}
            cursor={false}
            labelFormatter={(label) => {
              return formatDate(label);
            }}
            formatter={(value, name) => {
              return [value + "%", "Humidity"];
            }}
          />
          <Area
            yAxisId="1"
            type="monotone"
            dataKey="Humidity"
            //use theme color for line
            stroke={theme.palette.primary.main}
            fill="url(#Temperature)"
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
}
