import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function PHGuague(props) {
  const [phValue, setPHValue] = React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  let defaultArch = [
    {
      limit: 0,
      color: "#FF0000",
    },
    {
      limit: 1,
      color: "#FF3300",
    },
    {
      limit: 2,
      color: "#FF6600",
    },
    {
      limit: 3,
      color: "#FF9900",
    },
    {
      limit: 4,
      color: "#FFCC00",
    },
    {
      limit: 5,
      color: "#FFFF00",
    },
    {
      limit: 6,
      color: "#99FF00",
    },
    {
      limit: 7,
      color: "#00FF00",
    },
    {
      limit: 8,
      color: "#00FF99",
    },
    {
      limit: 9,
      color: "#00FFFF",
    },
    {
      limit: 10,
      color: "#3399FF",
    },
    {
      limit: 11,
      color: "#3366FF",
    },
    {
      limit: 12,
      color: "#3333FF",
    },
    {
      limit: 13,
      color: "#6600FF",
    },
    {
      limit: 14,
      color: "#9900FF",
    },
  ];

  const { value, title } = props;

  let unit = "ph";

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      //check if its an array if it is, use the first value
      if (Array.isArray(jsonData[value])) {
        setPHValue(jsonData[value][0]);
      } else {
        setPHValue(jsonData[value]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, deviceProfile, value]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={title}
        action={<Typography variant="h5">{phValue} ph</Typography>}
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
          tickLabels: {
            type: "outer",
          },
        }}
        value={phValue}
        minValue={0}
        maxValue={14}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax type={value} unit={unit} />
      </Box>
    </Card>
  );
}
