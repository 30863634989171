import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { useTranslation } from "react-i18next";
import { putData } from "../../API/Put";
import { useSelector } from "react-redux";
import fetchActions from "../../Functions/fetchActions";

export default function ActionStatusDialog(props) {
  const { open, selectedActions, handleClose } = props;
  const [status, setStatus] = React.useState(true);

  const actions = useSelector((state) => state.actions);

  const selectedOrganisationID = localStorage.getItem("selectedOrganisationID");

  const { t } = useTranslation("common");

  const handleUpdate = () => {
    //for each action in selectedActions, update the status
    selectedActions.forEach(async (action) => {
      await putData(`/action/${selectedOrganisationID}/${action}/status/`, {
        action_status: status ? "true" : "false",
      });
    });
    updateSnackbarMessage(
      "Action status updated to " + (status ? "active" : "inactive")
    );
    fetchActions(selectedOrganisationID);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>{t("common.status")}</DialogTitle>
      <DialogContent>
        Change Status of the following actions:
        <ul>
          {selectedActions.map((action) => {
            //find the action in the actions array and get the name
            let actionName = actions.find(
              (a) => a.id === parseInt(action)
            ).name;
            return <li key={action}>{actionName}</li>;
          })}
        </ul>
        <TextField
          select
          label={t("common.status")}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          sx={{
            mt: 1,
          }}
          fullWidth
          variant="outlined"
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleUpdate()}
        >
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
