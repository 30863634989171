import React from "react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function CountCard() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [count, setCount] = React.useState(0);
  const [ble, setBle] = React.useState(0);
  const [wifi, setWifi] = React.useState(0);

  const [displayBLE, setDisplayBLE] = React.useState(true);
  const [displayWIFI, setDisplayWIFI] = React.useState(true);

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      setCount(jsonData.count);

      //check to see if there is ble and wifi data
      if (!jsonData.ble) {
        setDisplayBLE(false);
      } else {
        setBle(jsonData.ble);
      }

      if (!jsonData.wifi) {
        setDisplayWIFI(false);
      } else {
        setWifi(jsonData.wifi);
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <>
      <Card>
        <CardHeader title={"Total Count"} />
        <CardContent>
          <Typography variant="h4">{count}</Typography>
        </CardContent>
      </Card>
      {displayBLE && (
        <Box sx={{ mt: 1 }}>
          <Card>
            <CardHeader title={"BLE Count"} />
            <CardContent>
              <Typography variant="h4">{ble}</Typography>
            </CardContent>
          </Card>
        </Box>
      )}
      {displayWIFI && (
        <Box sx={{ mt: 1 }}>
          <Card>
            <CardHeader title={"Wifi Count"} />
            <CardContent>
              <Typography variant="h4">{wifi}</Typography>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}
