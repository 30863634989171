import fetchData from "../API/Fetch";
import { store } from "../store";

export async function fetchOrganisations() {
  const response = await fetchData("/organisation");

  store.dispatch({
    type: "organisations/update",
    payload: response || [],
  });

  return response;
}
