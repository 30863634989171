import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import LastSeen from "../Cards/LastSeen";
import DeviceMetric from "../Cards/DeviceMetric";
import GraphBase from "../Graphs/GraphBase";
import RecentFrames from "../RecentFrames";
import BatteryStatus from "../Cards/BatteryStatus";
import TimelineCard from "../Cards/timeline";
import DeviceInfo from "../Cards/DeviceInfo";
import { useSelector } from "react-redux";
import DeviceActionLogs from "../Cards/Alerts";

export default function DeviceTypeBase(props) {
  const isMobile = useSelector((state) => state.isMobile);
  let { isShare, columns, showBattery, isTracker, hideStats, sideBySide } =
    props;

  return (
    <>
      {isTracker && (
        <>
          <Grid item xs={12} md={12} sx={{ mb: 1 }}>
            <TimelineCard />
          </Grid>

          {isMobile && (
            <Grid item xs={12} md={12} sx={{ my: 1 }}>
              <DeviceInfo />
            </Grid>
          )}
        </>
      )}

      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        {sideBySide ? (
          <>
            <Grid
              item
              xs={12}
              md={
                !isShare && showBattery ? 4 : !isShare && !showBattery ? 6 : 12
              }
              sx={{ mb: 1 }}
            >
              <LastSeen />
            </Grid>
            {showBattery && (
              <Grid item xs={12} md={isShare ? 6 : 4} sx={{ mb: 1 }}>
                <BatteryStatus />
              </Grid>
            )}
            {!isShare && (
              <Grid item xs={12} md={showBattery ? 4 : 6} sx={{ mb: 1 }}>
                <LastAlert />
              </Grid>
            )}
            {columns.length &&
              columns.map((column, index) => (
                <>
                  <Grid item xs={12} md={3} sx={{ mb: 1 }} key={index}>
                    <DeviceMetric
                      value={column.field}
                      title={column.title}
                      icon={column.icon}
                      iconSize={column.iconSize}
                      truefalse={column.truefalse}
                      trueV={column.trueV}
                      falseV={column.falseV}
                      formatter={column.formatter}
                      textsize={column.textsize}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={{ mb: 1 }} key={index}>
                    <GraphBase
                      title={column.title}
                      value={column.field}
                      unit={column.unit}
                      trimValue={column.trimValue}
                      trimNumber={column.trimNumber}
                      trueV={column.trueV}
                      falseV={column.falseV}
                      truefalse={column.truefalse}
                      aspect={column.aspect}
                      formatter={column.formatter}
                      graphValueFormatter={column.graphValueFormatter}
                    />
                  </Grid>
                </>
              ))}
          </>
        ) : (
          <>
            {!hideStats && (
              <>
                <Grid
                  item
                  xs={12}
                  md={
                    !isShare && showBattery
                      ? 4
                      : !isShare && !showBattery
                      ? 6
                      : 12
                  }
                  sx={{ mb: 1 }}
                >
                  <LastSeen />
                </Grid>
                {showBattery && (
                  <Grid item xs={12} md={isShare ? 6 : 4} sx={{ mb: 1 }}>
                    <BatteryStatus />
                  </Grid>
                )}
                {!isShare && (
                  <Grid item xs={12} md={showBattery ? 4 : 6} sx={{ mb: 1 }}>
                    <LastAlert />
                  </Grid>
                )}
              </>
            )}
            {columns.length
              ? columns.map((column, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={
                        column.metricWidth
                          ? column.metricWidth
                          : columns.length === 1
                          ? 12
                          : columns.length === 2
                          ? 6
                          : columns.length === 3
                          ? 4
                          : columns.length === 4
                          ? 3
                          : columns.length % 3 === 0
                          ? 3
                          : index === columns.length - 1
                          ? 12
                          : 2
                      }
                      sx={{ mb: 1 }}
                      key={index}
                    >
                      <DeviceMetric
                        value={column.field}
                        title={column.title}
                        icon={column.icon}
                        iconSize={column.iconSize}
                        truefalse={column.truefalse}
                        trueV={column.trueV}
                        falseV={column.falseV}
                        formatter={column.formatter}
                        textsize={column.textsize}
                      />
                    </Grid>
                  );
                })
              : null}
            {columns.length
              ? columns.map((column, index) => {
                  if (column.hideGraph) return null;
                  return (
                    <Grid
                      item
                      xs={12}
                      md={
                        column.graphWidth
                          ? column.graphWidth
                          : columns.length -
                              columns.filter((c) => c.hideGraph).length ===
                            1
                          ? 12
                          : columns.length -
                              columns.filter((c) => c.hideGraph).length ===
                            2
                          ? 6
                          : columns.length -
                              columns.filter((c) => c.hideGraph).length ===
                            3
                          ? 4
                          : columns.length -
                              columns.filter((c) => c.hideGraph).length ===
                            4
                          ? 3
                          : 2
                      }
                      sx={{ mb: 1 }}
                      key={index}
                    >
                      <GraphBase
                        title={column.title}
                        value={column.field}
                        unit={column.unit}
                        trimValue={column.trimValue}
                        trimNumber={column.trimNumber}
                        trueV={column.trueV}
                        falseV={column.falseV}
                        truefalse={column.truefalse}
                        aspect={column.aspect}
                        formatter={column.formatter}
                      />
                    </Grid>
                  );
                })
              : null}
          </>
        )}

        <Grid item xs="12" md={!isShare ? 7 : 12} sx={{ mb: 1 }}>
          <RecentFrames columns={columns} showBattery={showBattery} />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={5}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
