/*
Type Name	LPP	Size	Dim	Signed	Resolution
Digital Input	0	1	1	False	1
Digital Output	1	1	1	False	1
Analog Input	2	2	1	True	0.01
Analog Output	3	2	1	True	0.01
Generic Sensor	100	4	1	False	1
Illuminance	101	2	1	False	1 Lux
Presence	102	1	1	False	1  
Temperature	103	2	1	True	0.1°C
Humidity	104	1	1	False	0.5 %
Accelerometer	113	6	3	True	0.001 G
Barometer	115	2	1	False	0.1 hPa
Voltage	116	2	1	False	0.01 V
Current	117	2	1	False	0.001 A
Frequency	118	4	1	False	1 Hz
Percentage	120	1	1	False	1 %
Altitude	121	2	1	True	1 m
Load	122	3	1	True	0.001 kg
Concentration	125	2	1	False	1
Power	128	2	1	False	1
Distance	130	4	1	False	0.001 km
Energy	131	4	1	False	0.001 kJ
Direction	132	2	1	False	1 °
Time	133	4	1	False	1 s
Gyrometer	134	6	3	True	0.01 °/s
Colour	135	3	3	False	1 RGB
Location	136	9	3	True	0.00001 lat
0.00001 lon
0.01 alt
Switch	142	1	1	False	1 on/off
*/

import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import AnalogInput from "../Cards/AnalogInput";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

export default function CayenneLPP(props) {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [sensorDataTypes, setSensorDataTypes] = useState([]);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    //loop through data and get all LLP types from the data
    let types = [];

    deviceHistory.forEach((history) => {
      let data = history.data;

      //data is an arry of channels, get the type for each channel

      if (typeof data === "string") {
        data = JSON.parse(data);
      }

      //check if empty
      if (!data || data.length === 0) {
        return;
      }

      data.forEach((channel) => {
        if (channel.type) {
          types.push(channel.type);
        }
      });
    });

    //remove duplicates
    types = [...new Set(types)];

    //set sensorDataTypes
    setSensorDataTypes(types);
  }, [deviceHistory]);

  return (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      {sensorDataTypes.includes(136) && (
        //map
        <Typography variant="h4">Map</Typography>
      )}

      {sensorDataTypes.includes(0) && (
        //digital input
        <Typography variant="h4">Digital Input</Typography>
      )}

      {sensorDataTypes.includes(1) && (
        //digital output
        <Typography variant="h4">Digital Output</Typography>
      )}

      {sensorDataTypes.includes(2) && (
        //analog input
        <>
          <AnalogInput deviceHistory={deviceHistory} />
        </>
      )}

      {sensorDataTypes.includes(3) && (
        //analog output
        <Typography variant="h4">Analog Output</Typography>
      )}

      {sensorDataTypes.includes(100) && (
        //generic sensor
        <Typography variant="h4">Generic Sensor</Typography>
      )}

      {sensorDataTypes.includes(101) && (
        //illuminance
        <Typography variant="h4">Illuminance</Typography>
      )}

      {sensorDataTypes.includes(102) && (
        //presence
        <Typography variant="h4">Presence</Typography>
      )}
      {sensorDataTypes.includes(103) && (
        //temperature
        <Typography variant="h4">Temperature</Typography>
      )}
      {sensorDataTypes.includes(104) && (
        //humidity
        <Typography variant="h4">Humidity</Typography>
      )}
      {sensorDataTypes.includes(113) && (
        //accelerometer
        <Typography variant="h4">Accelerometer</Typography>
      )}
      {sensorDataTypes.includes(115) && (
        //barometer
        <Typography variant="h4">Barometer</Typography>
      )}
      {sensorDataTypes.includes(116) && (
        //voltage
        <Typography variant="h4">Voltage</Typography>
      )}
      {sensorDataTypes.includes(117) && (
        //current
        <Typography variant="h4">Current</Typography>
      )}
      {sensorDataTypes.includes(118) && (
        //frequency
        <Typography variant="h4">Frequency</Typography>
      )}
      {sensorDataTypes.includes(120) && (
        //percentage
        <Typography variant="h4">Percentage</Typography>
      )}
      {sensorDataTypes.includes(121) && (
        //altitude
        <Typography variant="h4">Altitude</Typography>
      )}
      {sensorDataTypes.includes(122) && (
        //load
        <Typography variant="h4">Load</Typography>
      )}
      {sensorDataTypes.includes(125) && (
        //concentration
        <Typography variant="h4">Concentration</Typography>
      )}
      {sensorDataTypes.includes(128) && (
        //power
        <Typography variant="h4">Power</Typography>
      )}
      {sensorDataTypes.includes(130) && (
        //distance
        <Typography variant="h4">Distance</Typography>
      )}
      {sensorDataTypes.includes(131) && (
        //energy
        <Typography variant="h4">Energy</Typography>
      )}
      {sensorDataTypes.includes(132) && (
        //direction
        <Typography variant="h4">Direction</Typography>
      )}
      {sensorDataTypes.includes(133) && (
        //time
        <Typography variant="h4">Time</Typography>
      )}
      {sensorDataTypes.includes(134) && (
        //gyrometer
        <Typography variant="h4">Gyrometer</Typography>
      )}
      {sensorDataTypes.includes(135) && (
        //colour
        <Typography variant="h4">Colour</Typography>
      )}
      {sensorDataTypes.includes(136) && (
        //location
        <Typography variant="h4">Location</Typography>
      )}
      {sensorDataTypes.includes(142) && (
        //switch
        <Typography variant="h4">Switch</Typography>
      )}
    </Grid>
  );
}
