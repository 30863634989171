// Desc: Put API call to update data
import axios from "axios";
import { CONFIG } from "../config";
import { getHeaders } from "./getHeaders";

//axios api call to fetch data from the server
export const postData = async (url, data) => {
  try {
    let headers = await getHeaders();

    if (headers === null) {
      //set headers
      headers = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      };
    }

    const response = await axios.post(CONFIG.API_URL + url, data, {
      headers: headers,
    });

    return response;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};
