const initialState = [];

function dashboardsReducer(state = initialState, action) {
  switch (action.type) {
    case "dashboards/init":
      return action.payload;
    case "dashboards/update":
      return action.payload;

    default:
      return state;
  }
}

export default dashboardsReducer;
