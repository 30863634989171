import React from "react";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { CONFIG } from "../../config";

export default function AddDeviceCard(props) {
  const { setAddDeviceOpen } = props;

  return (
    <>
      <Grid item xs={12} md={4}>
        <>
          <Card
            sx={{
              //onhover set shadow
              "&:hover": {
                boxShadow: 10,
              },
              cursor: "pointer",
            }}
            onClick={() => {
              setAddDeviceOpen(true);
            }}
          >
            <CardContent>
              <Stack
                direction="row"
                //align items in the center
                alignItems="center"
              >
                <Add
                  sx={{
                    fontSize: 50,
                    color: "#00D09F",
                    mr: 2,
                  }}
                />
                <Typography variant="h5" component="div">
                  Click here to Add a Device
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </>
      </Grid>
      {CONFIG.show_buy_link ? (
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              //onhover set shadow
              "&:hover": {
                boxShadow: 10,
              },
              cursor: "pointer",
            }}
            onClick={() => {
              //open a new window to : https://hexaspot.com/collections/trackpac
              window.open(CONFIG.buy_link);
            }}
          >
            <CardContent>
              <Stack
                direction="row"
                //align items in the center
                alignItems="center"
              >
                <Add
                  sx={{
                    fontSize: 50,
                    color: "#00D09F",
                    mr: 2,
                  }}
                />
                <Typography variant="h5" component="div">
                  Don't have a device? Buy one here
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ) : null}
    </>
  );
}
