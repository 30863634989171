import fetchData from "../API/Fetch";

export default async function fetchDashboardDevices(dashboardID) {
  if (!dashboardID) {
    return [];
  }

  let response = await fetchData(`/dashboard/${dashboardID}/devices`);
  return response;
}
