// no device found dialog, show a dialog with the text no device found
import React from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function NoDeviceFoundDialog(props) {
  const { t } = useTranslation("common");

  const { open, handleClose } = props;
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("devices.no_device_found")}</DialogTitle>
      <DialogContent>
        <Typography>{t("devices.no_device_found")}</Typography>
      </DialogContent>
    </Dialog>
  );
}
