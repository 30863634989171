import moment from "moment";

const initialState = {
  //start date, 24 hours ago in unix timestamp
  startDate: moment().utc().startOf("day").unix(),
  //end date, now in unix timestamp
  endDate: moment().utc().endOf("day").unix(),
};

//snackbar open reducer
export default function dateRangeReducer(state = initialState, action) {
  switch (action.type) {
    case "dateRange/update":
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    default:
      return state;
  }
}
