import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

import {
  AddAlarm,
  CorporateFare,
  Dashboard,
  Description,
  Fence,
  Logout,
  Person,
  Router,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

function SidebarLayout(props) {
  const navigate = useNavigate();

  const { logout } = useAuth0();

  let theme = useTheme();

  const { collapsed } = props;

  const { t } = useTranslation("common");

  return (
    <Sidebar
      collapsed={collapsed}
      className="app"
      backgroundColor={theme.palette.mode === "dark" ? "#191B1F" : "lightgray"}
    >
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0) {
              return {
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.main
                    : "#000000de",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#191B1F" : "lightgray",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.mode === "dark" ? "white" : "white",
                },
              };
            } else if (level === 1) {
              return {
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.main
                    : "#000000de",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#3b4049" : "#cccccc", //minwidth 200px
                //minwidth 200px
                minWidth: "400px",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                },
              };
            }
          },
        }}
      >
        <MenuItem onClick={() => navigate("/dashboard")} icon={<Dashboard />}>
          {t("common.dashboards")}
        </MenuItem>
        <MenuItem onClick={() => navigate("/")} icon={<Router />}>
          {t("common.devices")}
        </MenuItem>

        <MenuItem onClick={() => navigate("/action")} icon={<AddAlarm />}>
          {t("common.actions")}
        </MenuItem>

        <MenuItem onClick={() => navigate("/geofence")} icon={<Fence />}>
          {t("common.geofences")}
        </MenuItem>

        <MenuItem onClick={() => navigate("/report")} icon={<Description />}>
          {t("common.reports")}
        </MenuItem>
        {/*
        <MenuItem onClick={() => navigate("/asset")} icon={<Inventory />}>
          {t("common.assets")}
        </MenuItem>
         */}

        <MenuItem onClick={() => navigate("/contact")} icon={<Person />}>
          {t("common.contacts")}
        </MenuItem>

        <MenuItem
          onClick={() => navigate("/organisation")}
          icon={<CorporateFare />}
        >
          {t("common.organisations")}
        </MenuItem>

        <MenuItem
          onClick={() => navigate("/account")}
          icon={<Settings />}
          //
        >
          {t("common.account")}
        </MenuItem>

        <MenuItem
          onClick={() => logout({ returnTo: window.location.origin })}
          icon={<Logout />}
        >
          {t("common.logout")}
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

export default SidebarLayout;
