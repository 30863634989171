const initialState = 0;

function pitchReducer(state = initialState, action) {
  switch (action.type) {
    case "pitch/update":
      return action.payload;
    default:
      return state;
  }
}

export default pitchReducer;
