import { store } from "../store";

export default function updateSnackbarMessage(message) {
  let severity = "success";

  //lowercase message and check for error
  if (message.toLowerCase().includes("error")) {
    severity = "error";
  }

  store.dispatch({
    type: "snackbar/update",
    payload: {
      open: true,
      message: message,
      severity: severity,
    },
  });
}
