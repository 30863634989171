//import deviceHistory, use type prop to get data for each history period and show avg and max
import { useTheme } from "@emotion/react";
import { Card, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import formatTemperature from "../../Functions/formatTemperature";
import { useSelector } from "react-redux";

export default function AvgAndMax(props) {
  const { type, value } = props;
  const [minimum, setMinimum] = React.useState(0);
  const [maximum, setMaximum] = React.useState(0);
  const [average, setAverage] = React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  const theme = useTheme();

  useEffect(() => {
    if (deviceHistory.length > 0) {
      let dataToUse = [];

      deviceHistory.forEach((history) => {
        let jsonData = history.data;
        jsonData = JSON.parse(jsonData);

        if (type === "temperature" || type === "temp") {
          //to int
          jsonData = jsonData[value || type]
            ? parseInt(formatTemperature(jsonData[value || type], true))
            : 0;
        } else if (type === "percentFull") {
          //work our percent full
          try {
            let deviceProfile2 = JSON.parse(deviceProfile);
            //use min as 100% and max as 0%
            jsonData =
              100 - (jsonData.distance / deviceProfile2.distance.max) * 100;

            //to fixed 2
            jsonData = jsonData.toFixed(1);
          } catch (error) {
            console.log(error);
          }
        } else {
          jsonData = jsonData[value || type];
        }

        //if its an array,use the first value
        if (Array.isArray(jsonData)) {
          jsonData = jsonData[0];
        } else {
          dataToUse.push(jsonData);
        }
      });

      if (dataToUse.length > 0) {
        //if its not a number, return
        let sum = 0;

        dataToUse.forEach((data) => {
          if (isNaN(data)) {
            return;
          }
          sum += data;
        });

        //remove any nan from dataToUse
        dataToUse = dataToUse.filter((data) => !isNaN(data));

        let avg = sum / dataToUse.length;
        let max = Math.max(...dataToUse);
        let min = Math.min(...dataToUse);

        setAverage(avg.toFixed(1));
        setMaximum(max.toFixed(1));
        setMinimum(min.toFixed(1));
      }
    }
  }, [deviceHistory, type, value, deviceProfile]);

  // return a card showing min max and average
  return (
    <Stack
      direction="row"
      //full width fill space

      sx={{
        px: 1,
        pb: 1,
        justifyContent: "space-between",
      }}
    >
      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1f1f1f" : "#f0f0f0",
          p: 2,
          boxShadow: 0,
          width: "31%",
          mr: 1,
        }}
      >
        <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
          Min
        </Typography>
        <Stack direction="row" alignItems="baseline">
          <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
            {minimum ? minimum : "0"}
          </Typography>

          <Typography variant="caption">{props.unit}</Typography>
        </Stack>
      </Card>

      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1f1f1f" : "#f0f0f0",
          p: 2,
          boxShadow: 0,
          width: "31%",
          mr: 1,
        }}
      >
        <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
          Max
        </Typography>
        <Stack direction="row" alignItems="baseline">
          <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
            {maximum ? maximum : "0"}
          </Typography>

          <Typography variant="caption">{props.unit}</Typography>
        </Stack>
      </Card>

      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1f1f1f" : "#f0f0f0",
          p: 2,
          boxShadow: 0,
          width: "31%",
        }}
      >
        <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
          Avg
        </Typography>
        <Stack direction="row" alignItems="baseline">
          <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
            {average
              ? //2 decimal places
                average
              : "0"}
          </Typography>

          <Typography variant="caption">{props.unit}</Typography>
        </Stack>
      </Card>
    </Stack>
  );
}
