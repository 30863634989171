import { Box, Card, IconButton, Stack, Typography } from "@mui/material";
import { formatDate } from "../../Functions/formatDate";
import { useState } from "react";
import { MoreVert } from "@mui/icons-material";
import WidgetEditOptions from "../widgetEditOptions";

export default function WidgetCard(props) {
  const {
    children,
    title,
    date,
    isShare,
    editMode,
    widget,
    dashboardId,
    refreshDashboard,
  } = props;

  const [widgetMenuOpen, setWidgetMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setWidgetMenuOpen(false);
  };

  return (
    <>
      <Card sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 1,
          }}
        >
          <Box>
            <Typography variant="h7" sx={{ fontWeight: "600" }}>
              {title}
            </Typography>
            <br />
            <Typography variant="caption">{formatDate(date)}</Typography>
          </Box>
          {isShare ? null : editMode ? null : ( //if not in edit mode, don't show the delete button
            //show a options menu, three vertical dots
            <IconButton
              sx={{
                p: 0,
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setWidgetMenuOpen(true);
              }}
            >
              <MoreVert />
            </IconButton>
          )}
        </Box>
        <Stack
          spacing={2}
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            p: 2,
            pt: 0,
            //full height of the card
            flex: "1 1 auto",
          }}
        >
          <>{children}</>
        </Stack>
      </Card>
      <WidgetEditOptions
        anchorEl={anchorEl}
        handleClose={handleClose}
        widget={widget}
        widgetMenuOpen={widgetMenuOpen}
        dashboardId={dashboardId}
        refreshDashboard={refreshDashboard}
      />
    </>
  );
}
