//dialogbase to add organisation
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { putData } from "../../API/Put";
import OrganisationDeleteDialog from "./organisationDelete";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import mapboxgl from "mapbox-gl";
import { CONFIG } from "../../config";
import { CloudUpload } from "@mui/icons-material";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import refreshData from "../../Functions/refreshData";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

mapboxgl.accessToken = CONFIG.mapboxAccessToken;

export default function OrganisationSettingsDialog(props) {
  const { open, handleClose, organisation } = props;
  const { t } = useTranslation("common");
  const { user } = useAuth0();

  const [deleteOrganisationDialogOpen, setDeleteOrganisationDialogOpen] =
    React.useState(false);
  const [organisationId, setOrganisationId] = React.useState(null);
  const [centerandzoom, setCenterAndZoom] = React.useState("false");
  const [heroMode, setHeroMode] = React.useState("mapiftrackers");
  const [center, setCenter] = React.useState([0, 0]);
  const [zoom, setZoom] = React.useState(1);
  const mapRef = React.useRef(null);
  const [image, setImage] = React.useState(null);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    unregister,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });

  const handleDeleteOrganisationDialogClose = () => {
    setDeleteOrganisationDialogOpen(false);
  };

  useEffect(() => {
    if (organisation !== null) {
      setValue("name", organisation.name);
      setValue("description", organisation.description);
      setValue("hero", organisation.hero);
      setValue("center_and_zoom", organisation.centerandzoom);
      setValue("center", organisation.center);
      setValue("zoom", organisation.zoom);
      setOrganisationId(organisation.id);
      setCenterAndZoom(organisation.centerandzoom);
      setHeroMode(organisation.hero);
      setCenter(
        organisation &&
          organisation.center &&
          organisation.center.split(",").map(Number)
      );
      setImage(organisation.image);
      setZoom(organisation.zoom);
    }
  }, [organisation, setValue]);

  useEffect(() => {
    if (centerandzoom === "true") {
      //load the mapbox map

      let latlng = [0, 0];

      if (organisation && organisation.center) {
        latlng = organisation.center.split(",").map(Number);
      }

      let zoomInt = 1;

      if (organisation.zoom) {
        zoomInt = organisation.zoom;
      }

      //check if container exists
      if (!document.getElementById("center")) {
        return;
      }

      const map = new mapboxgl.Map({
        container: "center",
        style: "mapbox://styles/mapbox/streets-v11",
        center: latlng,
        zoom: zoomInt,
      });

      mapRef.current = map;

      map.on("move", () => {
        setCenter([map.getCenter().lng, map.getCenter().lat]);
        setZoom(map.getZoom());
      });

      //onload add search control and geolocate control
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        })
      );

      //add search control top left
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
        }),
        "top-left"
      );

      map.addControl(new mapboxgl.NavigationControl());

      return () => map.remove();
    }
  }, [centerandzoom, organisation, heroMode]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onSubmit = async (data) => {
    const { name, description } = data;

    const organisation = {
      name: name,
      description: description,
      hero: heroMode,
      centerandzoom: centerandzoom,
      center: center ? center.toString() : "",
      zoom: zoom,
    };

    //first we want to send the image to the server and get the url
    if (image && heroMode === "image") {
      //grab the image bytes and send to the server

      let formData = new FormData();
      formData.append("file", image);

      let extension = image.name.split(".").pop();

      let accessToken = await localStorage.getItem("access_token");

      let headers = {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "multipart/form-data",
      };

      const response = await axios.post(
        CONFIG.API_URL +
          `/organisation/${organisationId}/hero-image/${extension}`,
        formData,
        {
          headers: headers,
        }
      );

      if (response === undefined) {
        updateSnackbarMessage("Error updating organisation");
        handleClose();
        return;
      }

      if (response.status !== 200) {
        updateSnackbarMessage("Error updating organisation");
        handleClose();
        return;
      }

      if (response.status === 200) {
        let imageResponse = response.data;

        if (imageResponse) {
          organisation.image = imageResponse;
        } else {
          updateSnackbarMessage("Error updating organisation");
          handleClose();
          return;
        }
      }
    }

    let responseOrg = await putData(
      `/organisation/${organisationId}`,
      organisation
    );

    if (responseOrg === undefined) {
      updateSnackbarMessage("Error updating organisation");
      handleClose();
      return;
    }

    if (responseOrg.status !== 200) {
      updateSnackbarMessage("Error updating organisation");
      handleClose();
      return;
    }

    if (responseOrg.status === 200) {
      updateSnackbarMessage("Organisation updated successfully");
      await refreshData(user.email, user.sub);
      handleClose();
      return;
    }
  };

  const validationLabels = {
    name: {
      required: t("organisations.name_required"),
    },
    description: {
      required: t("organisations.description_required"),
    },
    hero: {
      required: t("organisations.hero_required"),
    },
  };

  useEffect(() => {
    //register and unregister fields based on hero mode
    if (heroMode === "map") {
      register("center_and_zoom", { required: true });
    }

    if (heroMode === "image") {
      unregister("center_and_zoom");
    }

    return () => {
      unregister("center_and_zoom");
    };
  }, [heroMode, register, unregister]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="organisation-add-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="organisation-add-dialog-title">
          {t("organisations.settings")}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  margin="dense"
                  id="name"
                  label={t("organisations.org_name")}
                  type="text"
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  margin="dense"
                  id="description"
                  label={t("common.description")}
                  multiline
                  rows={4}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.description[error.type] : ""
                  }
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                />
              )}
            />

            <Controller
              name="hero"
              control={control}
              defaultValue={heroMode}
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  margin="dense"
                  id="hero"
                  label={t("organisations.hero")}
                  type="text"
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.hero[error.type] : ""}
                  variant="outlined"
                  value={heroMode}
                  sx={{
                    mt: 1,
                  }}
                  select
                  onChange={(e) => {
                    setHeroMode(e.target.value);
                  }}
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="mapiftrackers">
                    Map If Tracker Devices
                  </MenuItem>
                  <MenuItem value="map">Map</MenuItem>
                  <MenuItem value="image">Image with Icons</MenuItem>
                </TextField>
              )}
            />

            {heroMode === "map" ? (
              <>
                <TextField
                  margin="dense"
                  id="center_and_zoom"
                  label={t("organisations.center_and_zoom")}
                  value={centerandzoom}
                  fullWidth
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  select
                  onChange={(e) => {
                    setCenterAndZoom(e.target.value);
                  }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>

                {centerandzoom === "true" ? (
                  <>
                    <div id="center" style={{ minHeight: "300px" }}></div>
                  </>
                ) : null}
              </>
            ) : null}

            {heroMode === "image" ? (
              <Stack
                direction={"row"}
                spacing={2}
                sx={{
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ width: "49%", height: "100%" }}
                >
                  {image ? (
                    <img
                      src={
                        //if image is a file, then use the file, otherwise use the url

                        image instanceof File
                          ? URL.createObjectURL(image)
                          : image
                      }
                      alt="Hero"
                      width="200px"
                      height="auto"
                    />
                  ) : (
                    "No image selected"
                  )}
                </Typography>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUpload />}
                  sx={{ width: "49%" }}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }}
                  />
                </Button>
              </Stack>
            ) : null}

            {JSON.stringify(errors)}

            <Button
              variant="contained"
              color="error"
              sx={{
                mt: 1,
              }}
              onClick={() => {
                setDeleteOrganisationDialogOpen(true);
              }}
            >
              {t("organisations.delete")}
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <OrganisationDeleteDialog
        open={deleteOrganisationDialogOpen}
        handleClose={handleDeleteOrganisationDialogClose}
        handleDelete={
          //firstly close that dialog, and then close this one
          () => {
            setDeleteOrganisationDialogOpen(false);

            handleClose();
          }
        }
        organisation={organisation}
      />
    </>
  );
}
