import { CONFIG } from "../config";

export default function setTitle(title, selectedOrganisation) {
  if (!selectedOrganisation) {
    document.title = `${title} | ${CONFIG.siteTitle} - ${CONFIG.siteTitleDescription}`;
    return true;
  } else if (!selectedOrganisation.name) {
    document.title = `${title} | ${CONFIG.siteTitle} - ${CONFIG.siteTitleDescription}`;
    return true;
  }
  document.title = `${selectedOrganisation.name} ${title} | ${CONFIG.siteTitle} - ${CONFIG.siteTitleDescription}`;
  return true;
}
