import React from "react";
import { Grid } from "@mui/material";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import DistanceGraph from "../Graphs/Distance";
import DistanceCard from "../Cards/Distance";
import FrameList from "../FrameList";
import DistanceGuague from "../Guages/FluidLevel";
import LiquidLevelGraph from "../Graphs/LiquidLevel";
import LastAlert from "../Cards/LastAlert";
import RssiAndSnr from "../Cards/RssiAndSnr";

export default function Distance2(props) {
  let { isShare } = props;

  const hasLiquidLevel = false;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <RssiAndSnr />
        </Grid>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 1 }}>
              <LastAlert />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          {hasLiquidLevel ? <DistanceGuague /> : <DistanceCard />}
        </Grid>

        {hasLiquidLevel ? (
          <Grid item xs={12} md={9} sx={{ mb: 1 }}>
            <LiquidLevelGraph />
          </Grid>
        ) : (
          <Grid item xs={12} md={9} sx={{ mb: 1 }}>
            <DistanceGraph />
          </Grid>
        )}

        <Grid item xs={12} md={!isShare ? 7 : 12} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={5}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
