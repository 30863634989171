import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { DialogActions } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { putData } from "../../API/Put";
import fetchContacts from "../../Functions/fetchContacts";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { useSelector } from "react-redux";
import hasPermission from "../../Functions/hasPermission";

export default function ContactEditDialog(props) {
  const { t } = useTranslation("common");
  const { open, handleClose, editContact } = props;

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const userDB = useSelector((state) => state.user);
  const userOrganisationRoles = useSelector(
    (state) => state.userOrganisationRoles
  );

  const { register, handleSubmit, unregister, watch, control, setValue } =
    useForm({
      reValidateMode: "onBlur",
    });

  const watchContactMethod = watch("contactMethod");

  const validationLabels = {
    name: {
      required: t("contacts.name_required"),
    },
    email: {
      required: t("contacts.email_required"),
      pattern: t("contacts.email_invalid"),
    },
    phone: {
      required: t("contacts.phone_required"),
      pattern: t("contacts.phone_invalid"),
    },
  };

  const onSubmit = async (data) => {
    const { name, email, phone, contactMethod } = data;

    try {
      let data = {
        name: name,
        email: email,
        phone: phone,
        contact_method: contactMethod,
      };

      await putData("/contact/" + editContact[0], data);

      await fetchContacts(localStorage.getItem("selectedOrganisationID"));

      updateSnackbarMessage(t("contacts.contact_updated", { name: name }));

      handleClose();
    } catch (error) {
      updateSnackbarMessage(t("contacts.contact_update_error"));
    }
  };

  useEffect(() => {
    if (editContact && editContact.length > 0) {
      setValue("name", editContact[1]);
      setValue("email", editContact[2]);
      setValue("phone", editContact[3]);
      setValue("contactMethod", editContact[5]);

      if (editContact.length === 5 && editContact[5] === "email") {
        register("email");
        unregister("phone");
      } else if (editContact.length === 5 && editContact[5] === "phone") {
        register("phone");
        unregister("email");
      } else if (editContact.length === 5 && editContact[5] === "both") {
        register("email");
        register("phone");
      }
    }
  }, [editContact, setValue, register, unregister]);

  useEffect(() => {
    if (watchContactMethod === "email") {
      register("email");
      if (editContact && editContact.length > 1) {
        setValue("email", editContact[2]);
      }

      unregister("phone");
    } else if (watchContactMethod === "phone") {
      register("phone");

      if (editContact && editContact.length > 1) {
        setValue("phone", editContact[3]);
      }
      unregister("email");
    } else if (watchContactMethod === "both") {
      register("email");
      register("phone");

      if (editContact && editContact.length > 1) {
        setValue("email", editContact[2]);
        setValue("phone", editContact[3]);
      }
    }
  }, [watchContactMethod, register, unregister, editContact, setValue]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {t("contacts.edit_title", {
            name: editContact && editContact[1] ? editContact[1] : "Contact",
          })}
        </DialogTitle>
        <DialogContent>
          <Controller
            name={"name"}
            control={control}
            rules={{
              required: true,
              minLength: 2,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  label={t("common.name")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                />
              </FormControl>
            )}
          />
          <Controller
            name={"contactMethod"}
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  label={t("contacts.contact_method")}
                  fullWidth
                  {...field}
                  select
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("contactMethod")}
                >
                  <MenuItem value="email">{t("contacts.email")}</MenuItem>
                  <MenuItem value="phone">{t("contacts.phone")}</MenuItem>
                  <MenuItem value="both">
                    {t("contacts.email_and_sms")}
                  </MenuItem>
                </TextField>
              </FormControl>
            )}
          />

          {watchContactMethod === "email" && (
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    mt: 1,
                  }}
                >
                  <TextField
                    label={t("contacts.email")}
                    fullWidth
                    {...field}
                    error={error !== undefined}
                    helperText={error ? validationLabels.email[error.type] : ""}
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  />
                </FormControl>
              )}
            />
          )}

          {watchContactMethod === "phone" && (
            <Controller
              name={"phone"}
              control={control}
              rules={{
                required: true,
                pattern: /^\+[1-9]{1}[0-9]{3,14}$/,
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    mt: 1,
                  }}
                >
                  <TextField
                    label={t("contacts.phone")}
                    fullWidth
                    {...field}
                    error={error !== undefined}
                    helperText={error ? validationLabels.phone[error.type] : ""}
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  />
                </FormControl>
              )}
            />
          )}

          {watchContactMethod === "both" && (
            <>
              <Controller
                name={"email"}
                control={control}
                rules={{
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      label={t("contacts.email")}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.email[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name={"phone"}
                control={control}
                rules={{
                  required: true,
                  pattern: /^\+[0-9]{1,3}\s?[0-9]{10}$/,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      label={t("contacts.phone")}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.phone[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                    />
                  </FormControl>
                )}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={
              (selectedOrganisation &&
                userDB &&
                selectedOrganisation.owner_id === userDB.id) ||
              hasPermission(userOrganisationRoles, userDB.id, "edit")
                ? false
                : true
            }
          >
            {t("common.update")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
