import moment from "moment";
import "moment-timezone";

const tz = moment.tz.guess();

export function formatTimeOnly(date) {
  if (!date) return "";

  let timeFormat =
    localStorage.getItem("time_format") !== null
      ? localStorage.getItem("time_format")
      : "HH:mm";

  // return the date in the local timezone
  return moment.utc(date).tz(tz).format(timeFormat);
}
