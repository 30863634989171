import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

export default function SignalQuality(props) {
  const [signal_quality, setSignalQuality] = React.useState("N/A");
  const theme = useTheme();

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (deviceHistory && deviceHistory.length > 0) {
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      jsonData = JSON.parse(jsonData);
      setSignalQuality(jsonData.signalQuality);
    }
  }, [deviceHistory]);

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            Signal Quality
          </Typography>
        </Box>
        <Tooltip
          title={
            signal_quality !== "N/A"
              ? "Signal Quality: " + signal_quality
              : "Signal Quality: N/A"
          }
        >
          <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
            {signal_quality !== "N/A" ? signal_quality + "%" : "N/A"}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
