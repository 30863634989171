import { putData } from "../API/Put";

export default function updateWidget(widgetId, dashboardId, data) {
  if (!widgetId || !dashboardId || !data) {
    return false;
  }

  let response = putData(`/dashboard/${dashboardId}/widget/${widgetId}`, data);

  if (response) {
    return true;
  }
  return false;
}
