import React from "react";
import {
  Box,
  Card,
  CardHeader,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function ButtonPressCard(props) {
  const { value, title } = props;
  const [buttonPressed, setButtonPressed] = React.useState(0);
  const [totalTimes, setTotalTimes] = React.useState(0);
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const theme = useTheme();

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      setButtonPressed(jsonData[value]);

      let timesPressed = 0;

      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);

        if (jsonData[value] === 1) {
          timesPressed++;
        }
      });
      setTotalTimes(timesPressed);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, value]);

  return (
    <Card>
      <CardHeader
        title={title + " Pressed"}
        titleTypographyProps={{
          variant: "h6",
          color: theme.palette.mode === "light" ? "#000" : "#616161",
        }}
        sx={{
          // if light theme then color is black, else dark grey
          color: theme.palette.mode === "light" ? "#000" : "#616161",
          mb: 0,
          pb: 1,
        }}
        action={
          <Typography
            variant="h4"
            sx={{
              color: buttonPressed === 1 ? "green" : "red",
            }}
          >
            {buttonPressed === 1 ? "Yes" : "No"}
          </Typography>
        }
      />
      <Tooltip
        title={`${title} Pressed: ${buttonPressed === 1 ? "Yes" : "No"}`}
      >
        <Box
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? "#1f1f1f" : "#f0f0f0",
            p: 2,
            boxShadow: 0,
            mx: 1,
            mb: 1,
            rounded: 16,
          }}
        >
          <Typography variant="h6">
            {"Pressed " + totalTimes + " times"}
          </Typography>
        </Box>
      </Tooltip>
    </Card>
  );
}
