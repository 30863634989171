import { Card, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { useSelector } from "react-redux";

export default function TotalVolumeGuage(props) {
  const [totalVolume, setTotalVolume] = React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);

  let defaultArch = [
    {
      limit: 0,

      color: "#0061f2",
    },
    {
      //blue
      limit: 10,
      color: "#0061f2",
    },
    {
      //green
      limit: 30,
      color: "#00FF00",
    },
    {
      //red
      limit: 50,
      color: "#FF0000",
    },
  ];

  useEffect(() => {
    if (!deviceHistory || !deviceHistory.length) {
      return;
    }
    //get last data object from deviceHistory
    let lastData = deviceHistory[deviceHistory.length - 1];
    //parse data
    let jsonData = lastData.data;
    jsonData = JSON.parse(jsonData);

    //set totalVolume to value of totalVolume
    let totalVolume = jsonData.total_volume;
    setTotalVolume(totalVolume);
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={"Total Volume"}
        action={<Typography variant="h5">{totalVolume} Liters</Typography>}
      />

      <GaugeComponent
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
          tickLabels: {
            type: "outer",
            valueConfig: {
              formatTextValue: (value) => value + " L",
              fontSize: 10,
            },
          },
        }}
        value={totalVolume}
        minValue={0}
        maxValue={50}
        type="semicircle"
      />
    </Card>
  );
}
