import React from "react";

import DeviceTypeBase from "./DeviceTypeBase";

export default function DraginoLDS02(props) {
  let { isShare } = props;

  const columns = [
    {
      field: "door_open",
      title: "Door Open",
      icon: "door-open",
      iconSize: "6x",
      truefalse: true,
      formatter: (value) => (value ? "Open" : "Closed"),
      trueV: "Open",
      falseV: "Closed",
    },
    {
      field: "count",
      title: "Open Count",
      icon: "door-closed",
      iconSize: "6x",
      formatter: (value) => value + " times",
    },
    {
      field: "time",
      title: "Last Opened Duration",
      icon: "clock",
      iconSize: "6x",
      formatter: (value) => value + " seconds",
      unit: "s",
    },
  ];

  return (
    <DeviceTypeBase isShare={isShare} columns={columns} showBattery={true} />
  );
}
