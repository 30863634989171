//get and display all alerts for a device
import React from "react";
import DataTableBase from "../DataTableBase";
import { formatDate } from "../../Functions/formatDate";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function DeviceActionLogs() {
  const { t } = useTranslation("common");

  const deviceAlerts = useSelector((state) => state.deviceAlerts);

  const alertTableColumns = [
    {
      name: "id",
      label: t("common.id"),
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },

    {
      name: "time_created",
      label: t("common.received"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography>{formatDate(value, "LLL")}</Typography>;
        },
      },
    },
    //message, time_created
    {
      name: "message",
      label: t("actions.singular"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
  ];

  return (
    <DataTableBase
      title={t("actions.log_name")}
      data={deviceAlerts}
      columns={alertTableColumns}
      sortOrder={"time_created"}
      sortDirection="desc"
      rowsPerPage={10}
    />
  );
}
