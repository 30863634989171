import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import deleteData from "../../API/Delete";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function OrganisationDeleteDialog(props) {
  const { t } = useTranslation("common");

  const { organisation, handleClose, handleDelete } = props;

  const [confirmOrganisation, setConfirmOrganisation] = React.useState("");

  async function deleteOrganisation() {
    try {
      if (confirmOrganisation !== organisation.name) {
        alert(t("organisations.delete_text_not_match"));
        return;
      }

      let response = await deleteData(`/organisation/${organisation.id}`);

      if (response.data.status === 200) {
        updateSnackbarMessage(t("organisations.delete_success"));
        handleDelete();
      } else if (response.data.status === 406) {
        alert(
          "Organisation has devices, Please delete or transfer them first."
        );
      } else {
        updateSnackbarMessage(
          `${t("organisations.delete_error")} ${response.data}`
        );
      }
    } catch (error) {
      updateSnackbarMessage(`${t("organisations.delete_error")}  ${error}`);
    }
  }

  return (
    <Dialog open={props.open} onClose={() => props.onClose(false)}>
      <DialogTitle>{t("organisations.delete_title")}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t("organisations.delete_confirmation")}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
          {t("organisations.delete_info")}
        </Typography>
        {organisation && (
          <Typography
            variant="body2"
            sx={{ mt: 1, mb: 1, fontFamily: "monospace" }}
          >
            {organisation.name}
          </Typography>
        )}
        <Typography variant="body1">
          Please type the organisation name to confirm
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          sx={{ mt: 1 }}
          onChange={(e) => {
            setConfirmOrganisation(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClose()}
        >
          {t("common.cancel")}
        </Button>
        <Button
          disabled={!organisation || confirmOrganisation !== organisation.name}
          variant="contained"
          color="error"
          onClick={() => deleteOrganisation()}
        >
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
