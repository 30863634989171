import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import RangeCards from "./rangeCards";

export default function CardBase(props) {
  const { values, title } = props;
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    let lastValue = values.values[values.values.length - 1];

    if (lastValue) {
      setValue(lastValue);
    }
  }, [values]);

  return (
    <>
      <Card sx={{ height: "92%" }}>
        <CardHeader title={title} />
        <CardContent>
          <Typography variant="h2">{value}</Typography>
        </CardContent>
        <CardContent>
          <RangeCards values={values} unit="" />
        </CardContent>
      </Card>
    </>
  );
}
