import deleteData from "../API/Delete";

async function deleteSelectedActions(selectedActions) {
  const selectedOrganisationID = localStorage.getItem("selectedOrganisationID");

  try {
    selectedActions.forEach(async (action) => {
      await deleteData(`/action/${selectedOrganisationID}/${action}`);
    });
  } catch (error) {
    console.log(error);
  }
}

export default deleteSelectedActions;
