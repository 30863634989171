const initialState = false;

function isFullscreenReducer(state = initialState, action) {
  switch (action.type) {
    case "isFullscreen/update":
      return action.payload;
    default:
      return state;
  }
}

export default isFullscreenReducer;
