import fetchData from "../API/Fetch";

export default function fetchReportFiles(id) {
  if (!id) {
    return [];
  }

  // Fetch report files
  let files = fetchData(`/report/file/${id}`);
  return files;
}
