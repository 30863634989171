import fetchData from "../API/Fetch";
import { store } from "../store";

export const fetchDeviceProfile = async (deviceID) => {
  const response = await fetchData(`/device/${deviceID}/profile`);

  if (response && response.profile) {
    try {
      let parsed = JSON.parse(response.profile);

      //dispatch to users/update reducer
      store.dispatch({
        type: "deviceProfile/update",
        payload: parsed,
      });
    } catch (e) {
      console.log("error parsing profile json", e);
      store.dispatch({
        type: "deviceProfile/update",
        payload: [],
      });
    }
  }
};
