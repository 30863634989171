// Moved data parsing logic to a separate function
export const parseDeviceHistory = (deviceHistory, field) => {
  if (!deviceHistory || deviceHistory.length === 0) {
    return [];
  }

  try {
    return deviceHistory.map((dataObject) => {
      const jsonData = JSON.parse(dataObject.data);

      let { state, motion } = jsonData;

      const time_created = dataObject.time_created;

      if (field) {
        motion = state;
      }

      return {
        Date: time_created,
        motion: motion,
      };
    });
  } catch (error) {
    console.error(error);
    return [];
  }
};
