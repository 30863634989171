import { putData } from "../API/Put";

export default function SaveDashboard(layout, dashboard_id) {
  if (!dashboard_id) {
    return false;
  }

  if (!layout) {
    return false;
  }

  let data = {
    layout: layout,
  };

  let response = putData(`/dashboard/${dashboard_id}/layout`, data);

  if (response) {
    return true;
  }
}
