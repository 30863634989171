const initialState = [];

function deviceHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case "deviceHistory/add":
      return [...state.value, action.payload];
    case "deviceHistory/remove":
      return state.filter((frame) => frame.id !== action.payload);
    case "deviceHistory/update":
      return action.payload;
    default:
      return state;
  }
}

export default deviceHistoryReducer;
