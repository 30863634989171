import fetchData from "../API/Fetch";
import { store } from "../store";

export const fetchDeviceAlerts = (deviceID, startDate, endDate) => {
  fetchData(
    `/device/${deviceID}/alert?start_time=${startDate}&end_time=${endDate}`
  ).then((response) => {
    if (response) {
      //update device history in redux store
      store.dispatch({
        type: "deviceAlerts/update",
        payload: response,
      });

      return response;
    }
  });
};
