import React from "react";
import formatTemperature from "../../../Functions/formatTemperature";
import DeviceTypeBase from "../DeviceTypeBase";

export default function BrowanTBDW100(props) {
  let { isShare } = props;

  const columns = [
    {
      field: "door_open",
      title: "Door Open",
      icon: "door-open",
      iconSize: "6x",
      truefalse: true,
      formatter: (value) => (value ? "Open" : "Closed"),
      trueV: "Open",
      falseV: "Closed",
      aspect: 10,
      metricWidth: 4,
      graphWidth: 12,
    },
    {
      field: "count",
      title: "Open Count",
      icon: "door-closed",
      iconSize: "6x",
      formatter: (value) => value + " times",
      aspect: 4,
      metricWidth: 4,
      graphWidth: 6,
    },
    {
      field: "temperature",
      title: "Temperature",
      icon: "thermometer-half",
      iconSize: "6x",
      formatter: (value) => formatTemperature(value, true),
      unit: "",
      aspect: 4,
      metricWidth: 4,
      graphWidth: 6,
    },
  ];

  return (
    <DeviceTypeBase isShare={isShare} columns={columns} showBattery={true} />
  );
}
