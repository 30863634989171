import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import OrganisationMemberDeleteDialog from "../Dialogs/organisationMemberDelete";
import OrganisationMemberEditRolesDialog from "../Dialogs/OrganisationMemberEditRoles";
import { useSelector } from "react-redux";

export default function OrganisationMemberCard(props) {
  const { member, organisation, refreshMembers } = props;
  const [
    organisationMemberDeleteDialogOpen,
    setOrganisationMemberDeleteDialogOpen,
  ] = React.useState(false);

  const [showEditRolesDialog, setShowEditRolesDialog] = useState(false);

  const user = useSelector((state) => state.user);

  const roles = ["View"];

  if (member && member.organisationRoles) {
    if (member.organisationRoles.admin_permission) {
      roles.push("Admin");
    }

    if (member.organisationRoles.edit_permission) {
      roles.push("Edit");
    }

    if (member.organisationRoles.add_permission) {
      roles.push("Add");
    }

    if (member.organisationRoles.delete_permission) {
      roles.push("Delete");
    }

    if (member.organisationRoles.transfer_permission) {
      roles.push("Transfer");
    }
  }

  return (
    <Grid item xs={12} md={6} lg={6} sx={{ mb: 1 }}>
      <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flexGrow: 1 }}>
          {
            // capitalise the first letter of the email
          }
          <Typography gutterBottom variant="h5" component="h2">
            {member.email.charAt(0).toUpperCase() + member.email.slice(1)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Roles: {roles.join(", ")}
          </Typography>
        </CardContent>
        <CardActions>
          {organisation &&
          organisation.owner_id &&
          member &&
          member.user_id &&
          organisation.owner_id === member.user_id ? (
            <Button variant="outlined" color="primary" disabled>
              Owner
            </Button>
          ) : member && member.user_id && member.user_id === user.id ? (
            <Button variant="outlined" color="primary" disabled>
              Admin
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOrganisationMemberDeleteDialogOpen(true);
                }}
              >
                Remove Member
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowEditRolesDialog(true);
                }}
              >
                Edit Roles
              </Button>
            </>
          )}
        </CardActions>
      </Card>

      <OrganisationMemberDeleteDialog
        open={organisationMemberDeleteDialogOpen}
        onClose={() => {
          refreshMembers();
          setOrganisationMemberDeleteDialogOpen(false);
        }}
        member_id={member.user_id}
        organisation={organisation}
      />

      <OrganisationMemberEditRolesDialog
        open={showEditRolesDialog}
        onClose={() => {
          refreshMembers();
          setShowEditRolesDialog(false);
        }}
        organisationId={organisation.id}
        userId={member.user_id}
        member={member}
      />
    </Grid>
  );
}
