import React, { useEffect } from "react";

import FrameList from "../FrameList";
import DeviceActionLogs from "../Cards/Alerts";
import { Box, Grid } from "@mui/material";
import TimelineCard from "../Cards/timeline";
import DeviceInfo from "../Cards/DeviceInfo";
import TemperatureGraph from "../Graphs/Temperature";
import TemperatureGuague from "../Guages/Temperature";
import LightGuague from "../Guages/Light";
import LightGraph from "../Graphs/Light";
import EventStatusCard from "../Cards/EventStatus";
import { useSelector } from "react-redux";
import SOSEventGraph from "../Graphs/SOSEvent";

export default function TrackerWithButton(props) {
  let { startPlayback, restartPlayback, stopPlayback, isShare } = props;

  const [lightValue, setLightValue] = React.useState(false);
  const [temperatureValue, setTemperatureValue] = React.useState(false);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    //check deviceHistory is not empty and if it has light and temperature data then set the values
    if (deviceHistory.length > 0) {
      //check through the most resent 10 data points to see if temperature and light are present

      let limited_deviceHistory = deviceHistory.slice(0, 10);

      limited_deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);

        //check field exists
        if (jsonData.temperature !== "") {
          setTemperatureValue(true);
        }

        //check if jsonData contains light key
        if (jsonData.light !== "") {
          setLightValue(true);
        }
      });
    }
  }, [deviceHistory]);

  return (
    <>
      <Grid container alignItems="stretch" spacing={1} sx={{ mt: 1 }}>
        {!isMobile ? (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 1 }}>
              <EventStatusCard />
            </Grid>
            <Grid item xs={12} md={9} sx={{ mb: 1 }}>
              <TimelineCard
                startPlayback={startPlayback}
                restartPlayback={restartPlayback}
                stopPlayback={stopPlayback}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={9} sx={{ mb: 1 }}>
              <Box sx={{ mb: 1 }}>
                <TimelineCard
                  startPlayback={startPlayback}
                  restartPlayback={restartPlayback}
                  stopPlayback={stopPlayback}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <DeviceInfo />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mb: 1, mt: 1 }}>
              <EventStatusCard />
            </Grid>
          </>
        )}

        {
          //if light and temperature values are set then show them
          temperatureValue && (
            <>
              {
                //if there is a light value, use md 3, xxl 2, else use md 6, xxl 4
              }
              <Grid
                item
                xs={12}
                md={lightValue ? 3 : 6}
                xxl={lightValue ? 2 : 4}
                sx={{ mb: 1 }}
              >
                <TemperatureGuague
                  temperatureValue="temperature"
                  title="Temperature"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={lightValue ? 9 : 6}
                xxl={lightValue ? 4 : 8}
                sx={{ mb: 1 }}
              >
                <TemperatureGraph title="Temperature" />
              </Grid>
            </>
          )
        }

        {lightValue && (
          <>
            <Grid
              item
              xs={12}
              md={temperatureValue ? 3 : 6}
              xxl={temperatureValue ? 2 : 4}
              sx={{ mb: 1 }}
            >
              <LightGuague />
            </Grid>
            <Grid
              item
              xs={12}
              md={temperatureValue ? 9 : 6}
              xxl={temperatureValue ? 4 : 8}
              sx={{ mb: 1 }}
            >
              <LightGraph />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <SOSEventGraph />
        </Grid>

        <Grid
          item
          xs={12}
          md={!isShare ? 7 : 12}
          xxl={!isShare ? 8 : 12}
          sx={{ mb: 1 }}
        >
          <FrameList />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={5} xxl={4} sx={{ mb: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
