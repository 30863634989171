export default function cleanAddress(historyItem) {
  try {
    let data = historyItem.data;
    //json parse data
    let dataParsed = JSON.parse(data);

    let address = dataParsed.geocode;

    return address;
  } catch (e) {
    return "No geocode available";
  }
}
