export default function formatTemperature(
  temperature,
  noDisplaySymbol = false,
  onlyDisplaySymbol = false
) {
  if (!temperature && temperature !== 0) {
    return "N/A";
  }

  //check temperature unit in local storage
  let temperature_unit = localStorage.getItem("temperature_unit");

  if (onlyDisplaySymbol) {
    return "°" + temperature_unit;
  }

  //if temperature unit is null, set to C
  if (!temperature_unit) {
    localStorage.setItem("temperature_unit", "C");
    temperature_unit = "C";
  }

  //if temperature unit is F, convert to F
  if (temperature_unit === "F") {
    temperature = (temperature * 9) / 5 + 32;
    if (typeof temperature.toFixed === "function") {
      temperature = temperature.toFixed(1);
    }
  }

  if (noDisplaySymbol === true) {
    //check temperature can run toFixed
    if (isNaN(temperature) || temperature === null) {
      return temperature;
    }
    // TypeError: temperature.toFixed is not a function
    if (typeof temperature.toFixed !== "function") {
      return temperature;
    }
    temperature = temperature.toFixed(1);
    if (temperature.endsWith(".0")) {
      temperature = temperature.slice(0, -2);
    }
    return temperature;
  }

  if (temperature.toFixed === undefined) {
    return temperature + "°" + temperature_unit;
  }

  // to fixed to 1 decimal places and add degree symbol
  temperature = temperature.toFixed(1);
  if (temperature.endsWith(".0")) {
    temperature = temperature.slice(0, -2);
  }
  return temperature + "°" + temperature_unit;
}
