import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import deleteData from "../../API/Delete";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

const DeletedeviceDialog = (props) => {
  const { t } = useTranslation("common");

  const { handleClose, handleDelete } = props;

  const [deviceConfirmName, setDeviceConfirmName] = React.useState("");

  const device = useSelector((state) => state.device);

  async function deleteSelecteddevices() {
    try {
      if (deviceConfirmName !== device.dev_eui) {
        alert(t("devices.delete_text_not_match"));
        return;
      }

      let response = await deleteData(`/device/${device.id}`);

      if (response.status === 200) {
        updateSnackbarMessage(t("devices.delete_success"));
        handleDelete();
      } else {
        updateSnackbarMessage(
          `${t("devices.delete_error")} ${JSON.stringify(response)}`
        );
      }
    } catch (error) {
      console.error(error);
      updateSnackbarMessage(t("devices.delete_error"));
    }
  }

  return (
    <Dialog open={props.open} onClose={() => props.onClose(false)}>
      <DialogTitle>{t("devices.delete_device_title")}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t("devices.delete_confirmation")}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
          {t("devices.delete_info")}
        </Typography>
        {device && (
          <Typography
            variant="body2"
            sx={{ mt: 1, mb: 1, fontFamily: "monospace" }}
          >
            {device.dev_eui}
          </Typography>
        )}
        <Typography variant="body1">
          Please type the device dev_eui to confirm
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          sx={{ mt: 1 }}
          onChange={(e) => {
            setDeviceConfirmName(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClose()}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={deviceConfirmName !== device.dev_eui}
          onClick={() => {
            deleteSelecteddevices();
          }}
        >
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletedeviceDialog;
