import { Button, Grid, Stack, Tooltip, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import { useTranslation } from "react-i18next";
import { Add, Refresh } from "@mui/icons-material";
import { fetchReports } from "../Functions/fetchReports";
import ReportsTable from "../components/Tables/Reports";
import ReportsAddDialog from "../components/Dialogs/reportAdd";
import { useState } from "react";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";

export default function Reports(props) {
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const isMobile = useSelector((state) => state.isMobile);
  const theme = useTheme();
  const { t } = useTranslation("common");
  const reports = useSelector((state) => state.reports);

  const [addOpen, setAddOpen] = useState(false);

  const handleRefresh = () => {
    fetchReports(selectedOrganisation.id);
    updateSnackbarMessage(
      "Reports refreshed at " + new Date().toLocaleTimeString()
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent={isMobile ? "flex-start" : "flex-end"}
          sx={{ mt: 1 }}
          spacing={1}
        >
          <PageTitle
            theme={theme}
            title={
              selectedOrganisation && selectedOrganisation.name
                ? selectedOrganisation.name + " " + t("reports.index")
                : t("reports.index")
            }
            subTitle={
              reports && reports.length
                ? reports.length + " Reports in this organisation"
                : 0 + " Reports in this organisation"
            }
            beta
          />
          <Tooltip title={t("reports.add")} placement="top" arrow>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              color={theme.palette.mode === "dark" ? "primary" : "secondary"}
              onClick={() => setAddOpen(true)}
            >
              <Add />
            </Button>
          </Tooltip>

          <Tooltip title={t("reports.refresh")} placement="top" arrow>
            <Button
              variant="outlined"
              color={theme.palette.mode === "dark" ? "primary" : "secondary"}
              onClick={handleRefresh}
            >
              <Refresh />
            </Button>
          </Tooltip>
        </Stack>
      </Grid>

      <ReportsTable />

      <ReportsAddDialog
        open={addOpen}
        handleClose={() => {
          setAddOpen(false);

          //set a timeout to refresh the reports
          setTimeout(() => {
            handleRefresh();
          }, 1000);
        }}
      />
    </>
  );
}
