import { Button, Grid, Stack, Tooltip, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import { useTranslation } from "react-i18next";
import { Add, Refresh } from "@mui/icons-material";
import { fetchDashboards } from "../Functions/fetchDashboards";
import DashboardsTable from "../components/Tables/Dashboards";
import DashboardAddDialog from "../components/Dialogs/dashboardAdd";
import { useEffect, useState } from "react";
import setTitle from "../Functions/setTitle";

export default function Dashboards(props) {
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const isMobile = useSelector((state) => state.isMobile);
  const theme = useTheme();
  const { t } = useTranslation("common");
  const dashboards = useSelector((state) => state.dashboards);

  const [addOpen, setAddOpen] = useState(false);

  const handleRefresh = () => {
    fetchDashboards(selectedOrganisation.id);
  };

  useEffect(() => {
    setTitle("Dashboards", selectedOrganisation);
  }, [selectedOrganisation, t]);

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent={isMobile ? "flex-start" : "flex-end"}
          sx={{ mt: 1 }}
          spacing={1}
        >
          <PageTitle
            theme={theme}
            title={
              selectedOrganisation && selectedOrganisation.name
                ? selectedOrganisation.name + " " + t("dashboards.index")
                : t("dashboards.index")
            }
            subTitle={dashboards.length + " Dashboards in this organisation"}
            beta
          />
          <Tooltip title={t("dashboards.add")} placement="top" arrow>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              color={theme.palette.mode === "dark" ? "primary" : "secondary"}
              onClick={() => setAddOpen(true)}
            >
              <Add />
            </Button>
          </Tooltip>

          <Tooltip title={t("dashboards.refresh")} placement="top" arrow>
            <Button
              variant="outlined"
              color={theme.palette.mode === "dark" ? "primary" : "secondary"}
              onClick={handleRefresh}
            >
              <Refresh />
            </Button>
          </Tooltip>
        </Stack>
      </Grid>

      <DashboardsTable />

      <DashboardAddDialog
        open={addOpen}
        handleClose={() => {
          setAddOpen(false);
          handleRefresh();
        }}
      />
    </>
  );
}
