import fetchData from "../API/Fetch";
import { store } from "../store";
export default async function fetchBases(orgID) {
  if (orgID === undefined || orgID === 0) return;
  let result = await fetchData(`/base/organisation/${orgID}`);

  store.dispatch({
    type: "bases/update",
    payload: result ? result : [],
  });
  return result;
}
