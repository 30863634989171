const initialState = { value: false };

function searchReducer(state = initialState, action) {
  switch (action.type) {
    case "search/update":
      return { value: action.payload };

    default:
      return state;
  }
}

export default searchReducer;
