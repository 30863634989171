import deleteData from "../API/Delete";

export default async function deleteWidget(widgetId, dashboardId) {
  if (!widgetId || !dashboardId) {
    return false;
  }
  const response = await deleteData(
    `/dashboard/${dashboardId}/widget/${widgetId}`
  );

  if (response.status === 200) {
    return true;
  }

  return false;
}
