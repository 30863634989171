import { useSelector } from "react-redux";
import DataTableBase from "../DataTableBase";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteDashboardConfirmationDialog from "../Dialogs/DashboardDelete";
import { fetchDashboards } from "../../Functions/fetchDashboards";
import { useState } from "react";
import { formatDate } from "../../Functions/formatDate";

import DashboardEditDialog from "../../components/Dialogs/dashboardEdit";

export default function DashboardsTable() {
  const dashboards = useSelector((state) => state.dashboards);
  const { t } = useTranslation("common");

  const [deleteDashboardConfirmationOpen, setDeleteDashboardConfirmationOpen] =
    useState(false);
  const [dashboardId, setDashboardId] = useState(null);
  const [dashboardName, setDashboardName] = useState("");

  const [editDashboardDialogOpen, setEditDashboardDialogOpen] = useState(false);
  const [dashboard, setDashboard] = useState({});

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  //columns for devices for data table
  const columns = [
    {
      name: "id",
      label: t("common.ID"),
      options: {
        display: false,
      },
    },
    {
      name: "name",
      options: {
        label: t("common.name"),
        customBodyRender: (value, tableMeta, meta) => {
          return (
            <Typography
              onClick={() => {
                window.location.href = "/dashboard/" + tableMeta.rowData[0];
              }}
              className="cursor-pointer"
              variant="body1"
              color="primary"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    // time_updated
    {
      name: "time_updated",
      label: t("common.time_updated"),
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2">
              {value ? formatDate(value) : "N/A"}
            </Typography>
          );
        },
      },
    },
    // time_created
    {
      name: "time_created",
      label: t("common.time_created"),
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2">{formatDate(value)}</Typography>;
        },
      },
    },
    //options
    {
      name: "options",
      label: "Options",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDashboard(
                    dashboards.find(
                      (dashboard) => dashboard.id === tableMeta.rowData[0]
                    )
                  );
                  setEditDashboardDialogOpen(true);
                }}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setDeleteDashboardConfirmationOpen(true);
                  setDashboardId(tableMeta.rowData[0]);
                  setDashboardName(tableMeta.rowData[1]);
                }}
              >
                Delete
              </Button>
            </Stack>
          );
        },
      },
    },
  ];

  return (
    <Box sx={{ mt: 1 }}>
      <DataTableBase
        title={t("dashboards.index")}
        data={dashboards}
        columns={columns}
      />
      <DeleteDashboardConfirmationDialog
        open={deleteDashboardConfirmationOpen}
        handleClose={() => {
          setDeleteDashboardConfirmationOpen(false);
          fetchDashboards(selectedOrganisation.id);
        }}
        refreshDashboard={() => fetchDashboards(selectedOrganisation.id)}
        dashboardId={dashboardId}
        dashboardName={dashboardName}
      />
      <DashboardEditDialog
        open={editDashboardDialogOpen}
        handleClose={() => setEditDashboardDialogOpen(false)}
        dashboard={dashboard}
      />
    </Box>
  );
}
