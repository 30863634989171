import React from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import DeviceActionLogs from "../Cards/Alerts";
import HumidityGuague from "../Guages/Humidity";
import TemperatureGraph from "../Graphs/Temperature";
import HumidityGraph from "../Graphs/Humidity";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import ButtonPressedCard from "../Cards/ButtonPressed";
import FrameList from "../FrameList";
import { useSelector } from "react-redux";

export default function TempHumidity(props) {
  let { isShare } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  //check to see if there is a button_pressed field in the deviceHistory.data json string
  //if there is, then we know this is a button tracker and we can show the button press graph and card
  let buttonPressed = false;

  if (deviceHistory.length > 0) {
    let lastData = deviceHistory[deviceHistory.length - 1];
    let jsonData = lastData.data;
    jsonData = JSON.parse(jsonData);
    //check data contains a button_pressed key (it will be true or false value)
    if (jsonData.button_pressed === 1 || jsonData.button_pressed === 0) {
      buttonPressed = true;
    }
  }

  //if button press is true, set the column to 3, otherwise set it to 4
  let buttonPressColumn = buttonPressed ? 3 : 4;

  if (isShare) {
    buttonPressColumn = buttonPressed ? 4 : 6;
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>

        {buttonPressed && (
          <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 1 }}>
            <ButtonPressedCard alarm={false} />
          </Grid>
        )}

        {!isShare && (
          <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <TemperatureGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <TemperatureGraph useIsXL={false} />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <HumidityGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <HumidityGraph />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={4}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
