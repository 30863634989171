import React from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import DeviceActionLogs from "../Cards/Alerts";
import HumidityGuague from "../Guages/Humidity";
import TemperatureGraph from "../Graphs/Temperature";
import HumidityGraph from "../Graphs/Humidity";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import WaterLeakDetectedCard from "../Cards/WaterLeak";
import WaterLeakGraph from "../Graphs/WaterLeak";
import FrameList from "../FrameList";

export default function WaterLeakTempHumidity(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 3 : 4} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 3 : 4} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>
        <Grid item xs={12} md={!isShare ? 3 : 4} sx={{ mb: 1 }}>
          <WaterLeakDetectedCard />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={3} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <WaterLeakGraph />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <TemperatureGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <TemperatureGraph useIsXL={false} />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <HumidityGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <HumidityGraph />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
