// use auth0 to get the headers for the API
export const getHeaders = async (token = null) => {
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
  const accessToken = await localStorage.getItem("access_token");

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};
