import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import { Card, CardContent, CardHeader } from "@mui/material";

export default function ThreeTemperaturesGraph(props) {
  const [temperatureHistory, setTemperatureHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);

  const [minTemp, setMinTemperature] = React.useState(0);
  const [maxTemp, setMaxTemperature] = React.useState(0);

  const theme = useTheme();

  useEffect(() => {
    if (deviceHistory.length === 0) {
      return;
    }

    try {
      //history will be a collection of button numbers, and will only show in history if the button was pressed
      let tempHistory = [];

      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let min, max;

        if (jsonData.temp_red < minTemp) {
          min = jsonData.temp_red;
        }
        if (jsonData.temp_black < minTemp) {
          min = jsonData.temp_black;
        }
        if (jsonData.temp_white < minTemp) {
          min = jsonData.temp_white;
        }

        if (jsonData.temp_red > maxTemp) {
          max = jsonData.temp_red;
        }
        if (jsonData.temp_black > maxTemp) {
          max = jsonData.temp_black;
        }
        if (jsonData.temp_white > maxTemp) {
          max = jsonData.temp_white;
        }

        setMinTemperature(min);
        setMaxTemperature(max);

        tempHistory.push({
          Date: time_created,
          temp_red: jsonData.temp_red,
          temp_black: jsonData.temp_black,
          temp_white: jsonData.temp_white,
        });
      });

      setTemperatureHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory, minTemp, maxTemp]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Temperature History" />
      <CardContent>
        <ResponsiveContainer aspect={isMobile ? 10 : 5}>
          <AreaChart
            data={temperatureHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="temp_red" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={"red"} stopOpacity={0.8} />
                <stop offset="95%" stopColor={"red"} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="temp_black" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={"black"} stopOpacity={0.8} />

                <stop offset="95%" stopColor={"black"} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="temp_white" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis
              type="number"
              yAxisId="1"
              domain={[minTemp - 2, maxTemp + 2]}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(value) => {
                return formatDate(value);
              }}
              formatter={(value, name) => {
                if (name === "temp_red") {
                  return [value + "°C", "Temperature Red"];
                }
                if (name === "temp_black") {
                  return [value + "°C", "Temperature Black"];
                }
                if (name === "temp_white") {
                  return [value + "°C", "Temperature White"];
                }
              }}
            />
            <Area
              yAxisId="1"
              dataKey="temp_red"
              stroke={"red"}
              animationDuration={300}
              fill="url(#temp_red)"
              strokeWidth={2}
            />
            <Area
              yAxisId="1"
              dataKey="temp_black"
              stroke={"black"}
              animationDuration={300}
              fill="url(#temp_black)"
              strokeWidth={2}
            />
            <Area
              yAxisId="1"
              dataKey="temp_white"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#temp_white)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
