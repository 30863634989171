import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { postData } from "../../API/Post";
import { fetchOrganisations } from "../../Functions/fetchOrganisations";
import { CONFIG } from "../../config";
import { fetchDeviceBrands } from "../../Functions/fetchDeviceBrands";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function SelfHostDialog(props) {
  const selectedOrganisationID = localStorage.getItem("selectedOrganisationID");

  const { open, handleClose } = props;
  const { t } = useTranslation("common");
  const [organisationID, setOrganisationID] = React.useState(
    selectedOrganisationID
  );
  const [organisations, setOrganisations] = React.useState();
  const [dev_eui, setDev_eui] = React.useState();
  const [name, setName] = React.useState();
  const [deviceBrands, setDeviceBrands] = React.useState();
  const [deviceBrand, setDeviceBrand] = React.useState();

  async function getOrganisations() {
    const response = await fetchOrganisations();
    setOrganisations(response);

    //set organisation id to first organisation
    if (response.length > 0) {
      setOrganisationID(response[0].id);
    }
  }

  async function getDeviceBrands() {
    const response = await fetchDeviceBrands();
    setDeviceBrands(response);
  }

  async function onboardSelfHosted() {
    if (dev_eui === "") {
      alert("Please enter a dev_eui");
      return;
    }

    if (name === "") {
      alert("Please enter a name");
      return;
    }

    if (deviceBrand === "") {
      alert("Please select a device type");
      return;
    }

    //check dev_eui is valid
    if (!dev_eui.match(/^[0-9a-fA-F]{16}$/)) {
      alert("Please enter a valid dev_eui");
      return;
    }

    const response = await postData("/device/self-host", {
      organisation_id: organisationID,
      dev_eui: dev_eui,
      name: name,
      device_brand: deviceBrand,
    });

    if (response.status === 200) {
      updateSnackbarMessage(t("common.self_host_success"));
      handleClose();
    } else {
      updateSnackbarMessage(t("common.self_host_fail"));
    }
  }

  useEffect(() => {
    getOrganisations();
    getDeviceBrands();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("common.self_host_title")}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t("common.self_host_intro", { endpoint: CONFIG.API_URL })}
        </DialogContentText>
        <TextField
          id="name"
          label={t("common.name")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => {
            setName(event.target.value);
          }}
        />

        <TextField
          id="dev_eui"
          label={t("common.dev_eui")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => {
            setDev_eui(event.target.value);
          }}
        />
        <TextField
          id="deviceBrand"
          label={t("common.device_type")}
          variant="outlined"
          fullWidth
          select
          margin="normal"
          onChange={(event) => {
            setDeviceBrand(event.target.value);
          }}
          value={deviceBrand}
        >
          {deviceBrands &&
            deviceBrands.length &&
            deviceBrands.map((deviceBrand) => (
              <MenuItem key={deviceBrand.id} value={deviceBrand.id}>
                {deviceBrand.description}
              </MenuItem>
            ))}
        </TextField>

        <TextField
          id="organisation_id"
          label={t("common.organisation")}
          variant="outlined"
          fullWidth
          select
          margin="normal"
          onChange={(event) => {
            setOrganisationID(event.target.value);
          }}
          value={organisationID}
        >
          {organisations &&
            organisations.length &&
            organisations.map((organisation) => (
              <MenuItem key={organisation.id} value={organisation.id}>
                {organisation.name}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleClose()}
          color="secondary"
        >
          {t("common.close")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onboardSelfHosted();
          }}
          color="primary"
        >
          {t("common.add_device")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
