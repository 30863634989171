import { store } from "../store";

export default function setSelectedOrganisation(organisation) {
  store.dispatch({
    type: "selectedOrganisation/update",
    payload: {
      value: organisation,
    },
  });
}
