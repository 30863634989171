import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function LightGuague(props) {
  const [light, setLight] = React.useState(0);

  const { unit } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  let lightUnit = unit || "%";

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      setLight(jsonData.light);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={"Light"}
        action={<Typography variant="h5">{light + "%"}</Typography>}
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          gradient: true,
          subArcs: [
            {
              limit: 20,
              // almost black but not quite blue
              color: "#000019",
            },
            {
              limit: 40,
              // almost black but not quite blue
              color: "#000066",
            },
            {
              limit: 60,
              //dark blue
              color: "#0000FF",
            },
            {
              limit: 80,
              //light blue
              color: "#00FFFF",
            },
          ],
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
        }}
        value={light}
        minValue={0}
        maxValue={100}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax type="light" unit={lightUnit} />
      </Box>
    </Card>
  );
}
