import deleteData from "../API/Delete";

export default async function deleteOrganisationMember(
  organisation_id,
  member_id
) {
  const response = deleteData(
    `/organisation/${organisation_id}/members/${member_id}`
  );

  return response;
}
