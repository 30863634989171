import { Grid } from "@mui/material";
import React from "react";
import LastSeen from "../Cards/LastSeen";
import BatteryStatus from "../Cards/BatteryStatus";
import LastAlert from "../Cards/LastAlert";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import GraphBase from "../Graphs/GraphBase";
import DeviceMetric from "../Cards/DeviceMetric";

export default function CustomPressure01(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <DeviceMetric
            value="gas_pressure_psi"
            title="Gas Pressure"
            unit="psi"
            icon="fa-tachometer-alt"
            iconSize="6x"
          />
        </Grid>

        <Grid item xs={12} md={8} sx={{ mb: 1 }}>
          <GraphBase
            value="gas_pressure_psi"
            title="Gas Pressure"
            unit="psi"
            useIsXL={false}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <DeviceMetric
            value="idc_intput_ma"
            title="Transducer mA"
            unit="mA"
            icon="fa-bolt"
            iconSize="6x"
          />
        </Grid>

        <Grid item xs={12} md={8} sx={{ mb: 1 }}>
          <GraphBase
            value="idc_intput_ma"
            title="Transducer mA"
            unit="mA"
            useIsXL={false}
          />
        </Grid>

        <Grid item xs={12} md={isShare ? 12 : 6} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={6}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
