import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import { useSelector } from "react-redux";
import ButtonPressCard from "../Cards/ButtonPress";
import ButtonPressRakGraph from "../Graphs/ButtonPressedRak";

export default function Rak4Button(props) {
  let { isShare } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  let button1Label = "Button 1";
  let button2Label = "Button 2";
  let button3Label = "Button 3";
  let button4Label = "Button 4";

  if (deviceProfile.length) {
    //json decode deviceProfile

    try {
      let deviceProfileJSON = JSON.parse(deviceProfile);

      if (deviceProfileJSON.Rak4Button) {
        if (deviceProfileJSON.Rak4Button.button1) {
          button1Label = deviceProfileJSON.Rak4Button.button1;
        }
        if (deviceProfileJSON.Rak4Button.button2) {
          button2Label = deviceProfileJSON.Rak4Button.button2;
        }
        if (deviceProfileJSON.Rak4Button.button3) {
          button3Label = deviceProfileJSON.Rak4Button.button3;
        }
        if (deviceProfileJSON.Rak4Button.button4) {
          button4Label = deviceProfileJSON.Rak4Button.button4;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  let deviceFrames;

  if (deviceHistory.length) {
    deviceFrames = deviceHistory[0].data;

    //json decode deviceFrames
    if (deviceFrames) {
      deviceFrames = JSON.parse(deviceFrames);
    }
  }

  return (
    <>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} md={isShare ? 12 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <ButtonPressCard value="button1" title={button1Label} />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <ButtonPressCard value="button2" title={button2Label} />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <ButtonPressCard value="button3" title={button3Label} />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <ButtonPressCard value="button4" title={button4Label} />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <ButtonPressRakGraph />
        </Grid>

        <Grid
          item
          xs={12}
          md={isShare ? 12 : 6}
          xxl={isShare ? 12 : 6}
          sx={{ mb: 1 }}
        >
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} xxl={6} sx={{ mb: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
