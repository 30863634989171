import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconPicker } from "react-fa-icon-picker";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import updateWidget from "../../Functions/updateWidget";
import { widgets } from "../Widgets/widgets";

export default function EditWidgetDialog(props) {
  const { open, handleClose, widget, refreshDashboard } = props;
  const { t } = useTranslation("common");
  const [fieldValues, setFieldValues] = useState({});
  const [deviceKeys, setDeviceKeys] = useState([]);
  const devices = useSelector((state) => state.devices);

  useEffect(() => {
    //parse widget field json.parse and then set it to state
    if (widget && widget.field) {
      const field = JSON.parse(widget.field);
      //convert to an object
      let fieldObj = {};
      Object.keys(field).forEach((key) => {
        fieldObj[key] = field[key];
      });

      //issse: we might have older widgets missing options from their widgets so we need to check if the field exists in the widget
      //if it does not we need to add it to the field object

      //first lets find the widget in widgets, check fieldObj for each field in the widget, if it does not exist add it to the fieldObj
      let widgetStruct = widgets.find(
        (widgetFind) => parseInt(widgetFind.id) === parseInt(widget.widget_id)
      );

      if (widgetStruct) {
        widgetStruct.fields.forEach((field) => {
          if (!fieldObj[field.name]) {
            fieldObj[field.name] = "";

            //if selectedDevices we need to set it devices, with id's all true
            if (field.name === "selectedDevices") {
              let selectedDevices = [];
              devices.forEach((device) => {
                selectedDevices.push(device.id + ",true");
              });
              fieldObj["selectedDevices"] = selectedDevices;
            }
          }
        });
      }

      setFieldValues(fieldObj);

      if (field["Value Field"]) {
        let device = devices.find(
          (device) => parseInt(device.id) === parseInt(field["Value Field"])
        );

        if (device) {
          let keys = Object.keys(JSON.parse(device.last_data));
          setDeviceKeys(keys);
        }
      }

      if (field["Boolean Field"]) {
        let device = devices.find(
          (device) => parseInt(device.id) === parseInt(field["Boolean Field"])
        );

        if (device) {
          let keys = Object.keys(JSON.parse(device.last_data));
          setDeviceKeys(keys);
        }
      }

      if (field["statusField"]) {
        let device = devices.find(
          (device) => parseInt(device.id) === parseInt(field["statusField"])
        );

        if (device) {
          let keys = Object.keys(JSON.parse(device.last_data));
          setDeviceKeys(keys);
        }
      }
    }
  }, [widget, devices]);

  const handleUpdateWidget = () => {
    //dispatch an action to update the widget
    //pass the widget ID and the fieldValues
    //close the dialog
    let data = {
      dashboard_id: widget.dashboard_id,
      order: widget.order,
      x: widget.x,
      y: widget.y,
      w: widget.w,
      h: widget.h,
      widget_id: widget.widget_id,
      device_id: fieldValues["Value Field"],
      field: JSON.stringify(fieldValues),
    };

    let resp = updateWidget(widget.id, widget.dashboard_id, data);

    if (resp) {
      updateSnackbarMessage("Widget Updated");

      handleClose();

      //set timeout to refresh the dashboard after 1 second

      setTimeout(() => {
        refreshDashboard();
      }, 1000);
    } else {
      updateSnackbarMessage("Error Updating Widget");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{t("dashboards.edit_widget")}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            select
            label={t("dashboards.widget_type")}
            value={widget.widget_id}
            disabled
            variant="outlined"
            fullWidth
            sx={{ my: 2 }}
          >
            {widgets &&
              widgets.length &&
              widgets.map((widget) => (
                <MenuItem key={widget.id} value={widget.id}>
                  {widget.name}
                </MenuItem>
              ))}
          </TextField>

          {fieldValues && Object.keys(fieldValues).length
            ? Object.keys(fieldValues).map((index, key) => {
                return key === "Value Field" ? (
                  <TextField
                    key={index}
                    label={"Device"}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    select
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        "Value Field": e.target.value,
                      });

                      let device = devices.find(
                        (device) =>
                          parseInt(device.id) === parseInt(e.target.value)
                      );

                      if (device) {
                        let keys = Object.keys(JSON.parse(device.last_data));
                        setDeviceKeys(keys);
                      }
                    }}
                  >
                    {devices &&
                      devices.length &&
                      devices.map((device) => (
                        <MenuItem key={device.id} value={device.id}>
                          {device.name}
                        </MenuItem>
                      ))}
                  </TextField>
                ) : key === "selectedDevices" ? (
                  //we want to show a list of devices with a toggle switch for each defaulting to true, when the user clicks save we will save the selected devices to the field
                  <Box>
                    {devices &&
                      devices.length &&
                      devices.map((device) => (
                        <Box
                          key={device.id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 2,
                          }}

                          //we want to show the device name and a toggle switch
                        >
                          <Typography>{device.name}</Typography>
                          <Switch
                            checked={
                              fieldValues["selectedDevices"] &&
                              fieldValues["selectedDevices"].length &&
                              fieldValues["selectedDevices"].includes(
                                device.id + ",true"
                              )
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              //update the selected devices to be true or false
                              let selectedDevices =
                                fieldValues["selectedDevices"];
                              if (e.target.checked) {
                                //check to see if the device is already in the array, if it is update it to true
                                if (
                                  selectedDevices &&
                                  selectedDevices.length &&
                                  selectedDevices.includes(device.id + ",false")
                                ) {
                                  selectedDevices = selectedDevices.filter(
                                    (d) => d !== device.id + ",false"
                                  );
                                }
                                selectedDevices.push(device.id + ",true");
                              } else {
                                //check to see if the device is already in the array, if it is update it to false
                                if (
                                  selectedDevices &&
                                  selectedDevices.length &&
                                  selectedDevices.includes(device.id + ",true")
                                ) {
                                  selectedDevices = selectedDevices.filter(
                                    (d) => d !== device.id + ",true"
                                  );
                                }
                                selectedDevices.push(device.id + ",false");
                              }
                              setFieldValues({
                                ...fieldValues,
                                selectedDevices: selectedDevices,
                              });
                            }}
                          />
                        </Box>
                      ))}
                  </Box>
                ) : key === "Boolean Field" ? (
                  <TextField
                    id={index}
                    label={"Device"}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    select
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        "Boolean Field": e.target.value,
                      });

                      let device = devices.find(
                        (device) =>
                          parseInt(device.id) === parseInt(e.target.value)
                      );

                      if (device) {
                        let keys = Object.keys(JSON.parse(device.last_data));
                        setDeviceKeys(keys);
                      }
                    }}
                  >
                    {devices &&
                      devices.length &&
                      devices.map((device) => (
                        <MenuItem key={device.id} value={device.id}>
                          {device.name}
                        </MenuItem>
                      ))}
                  </TextField>
                ) : key === "statusField" ? (
                  <TextField
                    key={index}
                    label={"Device"}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    select
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        "Boolean Field": e.target.value,
                      });

                      let device = devices.find(
                        (device) =>
                          parseInt(device.id) === parseInt(e.target.value)
                      );

                      if (device) {
                        let keys = Object.keys(JSON.parse(device.last_data));
                        setDeviceKeys(keys);
                      }
                    }}
                  >
                    {devices &&
                      devices.length &&
                      devices.map((device) => (
                        <MenuItem key={device.id} value={device.id}>
                          {device.name}
                        </MenuItem>
                      ))}
                  </TextField>
                ) : key === "Field" ? (
                  <TextField
                    key={index}
                    select
                    label="Field"
                    value={fieldValues[key]}
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        Field: e.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    {deviceKeys && deviceKeys.length
                      ? deviceKeys.map((key) => (
                          <MenuItem key={key} value={key}>
                            {key}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                ) : key === "Device" ? (
                  <TextField
                    key={index}
                    select
                    label={key}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                  >
                    {devices &&
                      devices.length &&
                      devices.map((device) => (
                        <MenuItem key={device.id} value={device.id}>
                          {device.name}
                        </MenuItem>
                      ))}
                  </TextField>
                ) : key === "Icon" ? (
                  <Box
                    sx={{
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      //space before the icon picker
                      justifyContent: "center",
                    }}
                  >
                    <IconPicker
                      value={fieldValues[key]}
                      onChange={(value) => {
                        setFieldValues({ ...fieldValues, [key]: value });
                      }}
                    />
                  </Box>
                ) : key === "Formatter" ? (
                  <TextField
                    key={index}
                    select
                    label={key}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        [key]: e.target.value,
                      });
                    }}
                  >
                    <MenuItem key="none" value="none">
                      None
                    </MenuItem>
                    <MenuItem key="temperature" value="temperature">
                      Temperature
                    </MenuItem>
                    <MenuItem key="battery" value="battery">
                      Battery
                    </MenuItem>
                    <MenuItem key="round" value="round">
                      Round
                    </MenuItem>
                    <MenuItem value="speed">Speed</MenuItem>
                  </TextField>
                ) : key === "Icon Size" ? (
                  <TextField
                    key={index}
                    label={key}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    select
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        [key]: e.target.value,
                      });
                    }}
                  >
                    <MenuItem key="1x" value="1x">
                      1x
                    </MenuItem>
                    <MenuItem key="2x" value="2x">
                      2x
                    </MenuItem>
                    <MenuItem key="3x" value="3x">
                      3x
                    </MenuItem>
                    <MenuItem key="4x" value="4x">
                      4x
                    </MenuItem>
                    <MenuItem key="5x" value="5x">
                      5x
                    </MenuItem>
                    <MenuItem key="6x" value="6x">
                      6x
                    </MenuItem>
                    <MenuItem key="7x" value="7x">
                      7x
                    </MenuItem>
                    <MenuItem key="8x" value="8x">
                      8x
                    </MenuItem>
                    <MenuItem key="9x" value="9x">
                      9x
                    </MenuItem>
                    <MenuItem key="10x" value="10x">
                      10x
                    </MenuItem>
                  </TextField>
                ) : key === "MapType" ? (
                  <TextField
                    key={index}
                    select
                    label={key}
                    value={fieldValues["MapType"]}
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        MapType: e.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    <MenuItem value="roadmap">Roadmap</MenuItem>
                    <MenuItem value="satellite">Satellite</MenuItem>
                  </TextField>
                ) : key === "actionTrue" ? (
                  <TextField
                    key={index}
                    label={"Action True"}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        actionTrue: e.target.value,
                      });
                    }}
                  />
                ) : key === "actionTrueport" ? (
                  <TextField
                    key={index}
                    label={"Action True Port"}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        actionTrueport: e.target.value,
                      });
                    }}
                  />
                ) : key === "actionFalse" ? (
                  <TextField
                    key={index}
                    label={"Action False"}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        actionFalse: e.target.value,
                      });
                    }}
                  />
                ) : key === "actionFalseport" ? (
                  <TextField
                    key={index}
                    label={"Action False Port"}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        actionFalseport: e.target.value,
                      });
                    }}
                  />
                ) : key === "size" ? (
                  <TextField
                    key={index}
                    select
                    label={"Size"}
                    value={fieldValues["size"]}
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        size: e.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    <MenuItem value="1x">1x</MenuItem>
                    <MenuItem value="2x">2x</MenuItem>
                    <MenuItem value="3x">3x</MenuItem>
                    <MenuItem value="4x">4x</MenuItem>
                    <MenuItem value="5x">5x</MenuItem>
                    <MenuItem value="6x">6x</MenuItem>
                    <MenuItem value="7x">7x</MenuItem>
                    <MenuItem value="8x">8x</MenuItem>
                    <MenuItem value="9x">9x</MenuItem>
                    <MenuItem value="10x">10x</MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    key={index}
                    label={key}
                    value={fieldValues[key]}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        [key]: e.target.value,
                      });
                    }}
                  />
                );
              })
            : null}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateWidget}
        >
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
