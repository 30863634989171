import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import deleteData from "../../API/Delete";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

function DeleteDevicesDialog(props) {
  const { open, handleClose, selectedItems, setSelectedItems } = props;

  const DeleteDevices = async () => {
    //check selected items are not empty
    if (selectedItems.length > 0) {
      //loop through selected items and delete each one
      for (let i = 0; i < selectedItems.length; i++) {
        //delete device
        await deleteData("/device/" + selectedItems[i]);
      }

      //update snackbar message
      updateSnackbarMessage("Devices deleted successfully");
      setSelectedItems([]);
    } else {
      //update snackbar message
      updateSnackbarMessage("No devices selected");
    }

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Devices</DialogTitle>
      <DialogContent>
        <p>Are you sure you want to delete these devices?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={DeleteDevices} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDevicesDialog;
