import React from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import HumidityGuague from "../Guages/Humidity";
import C02PPMGuague from "../Guages/C02PPM";
import TemperatureGraph from "../Graphs/Temperature";
import HumidityGraph from "../Graphs/Humidity";
import C02Graph from "../Graphs/C02";
import LastAlert from "../Cards/LastAlert";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";

import FrameList from "../FrameList";
import AQIGuague from "../Guages/AQIGuague";
import Rssi from "../Cards/Rssi";
import AQIGraph from "../Graphs/AQI";

export default function IndoorAirQualityNBIOTBattery(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} lg={isShare ? 6 : 4} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>

        <Grid item xs={12} lg={isShare ? 6 : 4} sx={{ mb: 1 }}>
          <Rssi />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} lg={4} sx={{ mb: 1 }}>
              <LastAlert />
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <TemperatureGuague temperatureValue="temp" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <HumidityGuague humidityValue="RH" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <C02PPMGuague c02Value="CO2" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <AQIGuague value="AQI" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <TemperatureGraph useIsXL={false} temperatureValue="temp" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <HumidityGraph humidityValue="RH" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <C02Graph c02Value="CO2" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <AQIGraph value="AQI" />
        </Grid>

        <Grid item xs={12} lg={!isShare ? 7 : 12}>
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} lg={5}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
