import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import RobSoundGraph from "../Graphs/RobSound";
import { useSelector } from "react-redux";
import DeviceDetail from "../Cards/DeviceDetail";

export default function RobSound(props) {
  let { isShare } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  let deviceFrames;

  if (deviceHistory.length) {
    deviceFrames = deviceHistory[0].data;

    //json decode deviceFrames
    if (deviceFrames) {
      deviceFrames = JSON.parse(deviceFrames);
    }
  }

  return (
    <>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} md={isShare ? 12 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <DeviceDetail
            title="Min"
            content={
              deviceFrames && deviceFrames.min !== undefined
                ? deviceFrames.min
                : "N/A"
            }
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <DeviceDetail
            title="Max"
            content={
              deviceFrames && deviceFrames.max !== undefined
                ? deviceFrames.max
                : "N/A"
            }
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <DeviceDetail
            title="Avg"
            content={
              deviceFrames && deviceFrames.avg !== undefined
                ? deviceFrames.avg
                : "N/A"
            }
          />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <RobSoundGraph />
        </Grid>

        <Grid
          item
          xs={12}
          md={isShare ? 12 : 6}
          xxl={isShare ? 12 : 6}
          sx={{ mb: 1 }}
        >
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} xxl={6} sx={{ mb: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
