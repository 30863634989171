import { Delete } from "@mui/icons-material";
import { Card, Box, IconButton } from "@mui/material";
import DeleteWidgetConfirmationDialog from "../Dialogs/WidgetDelete";
import { useState } from "react";

export default function BreakWidget(props) {
  const { editMode, widget, dashboardId, refreshDashboard } = props;

  const [deleteWidgetOpen, setDeleteWidgetOpen] = useState(false);

  return editMode ? (
    <>
      <Card
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>Break Widget</p>
        <IconButton
          sx={{
            m: 1.5,
            p: 0,
          }}
          onClick={() => {
            setDeleteWidgetOpen(true);
          }}
        >
          <Delete />
        </IconButton>
      </Card>
      <DeleteWidgetConfirmationDialog
        open={deleteWidgetOpen}
        widget={widget}
        dashboardId={dashboardId}
        handleClose={() => setDeleteWidgetOpen(false)}
        refreshDashboard={refreshDashboard}
      />
    </>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    ></Box>
  );
}
