const initialState = null;

function selectedOrganisationReducer(state = initialState, action) {
  switch (action.type) {
    case "selectedOrganisation/update":
      return action.payload;
    default:
      return state;
  }
}

export default selectedOrganisationReducer;
