import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import deleteWidget from "../../Functions/deleteWidget";

export default function DeleteWidgetConfirmationDialog(props) {
  const { open, handleClose, widget, dashboardId, refreshDashboard } = props;

  const handleDelete = () => {
    deleteWidget(widget.id, dashboardId);
    updateSnackbarMessage("Widget Deleted", "success");
    handleClose();

    //wait 1 second before refreshing the dashboard
    setTimeout(() => {
      refreshDashboard();
    }, 1000);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this widget? NOTE: It cannot be
          restored after deletion.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
