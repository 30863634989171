import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { DialogActions } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { postData } from "../../API/Post";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import fetchContacts from "../../Functions/fetchContacts";

export default function ContactAddDialog(props) {
  const { open, handleClose } = props;

  const selectedOrganisationID = localStorage.getItem("selectedOrganisationID");

  const { t } = useTranslation("common");

  const { register, handleSubmit, unregister, watch, control } = useForm({
    reValidateMode: "onBlur",
    defaultValues: {
      contactMethod: "email",
    },
  });

  const watchContactMethod = watch("contactMethod");

  const validationLabels = {
    name: {
      required: t("contacts.name_required"),
    },
    email: {
      required: t("contacts.email_required"),
      pattern: t("contacts.email_invalid"),
    },
    phone: {
      required: t("contacts.phone_required"),
      pattern: t("contacts.phone_invalid"),
    },
  };

  const onSubmit = async (data) => {
    const { name, email, phone, contactMethod } = data;

    try {
      let data = {
        name: name,
        email: email,
        phone: phone,
        contact_method: contactMethod,
        organisation_id: selectedOrganisationID,
      };

      await postData(`/contact`, data);

      updateSnackbarMessage(t("contacts.contact_created", { name: name }));

      await fetchContacts(localStorage.getItem("selectedOrganisationID"));

      handleClose();
    } catch (error) {
      // if error is 404 then contact already exists
      if (
        error.response &&
        error.response.status &&
        error.response.status === 404
      ) {
        updateSnackbarMessage(t("contacts.contact_exists", { name: name }));
      } else if (error.response && error.response.status === 500) {
        updateSnackbarMessage(t("contacts.error_adding_contact"));
        console.log(error.response.data);
      } else {
        updateSnackbarMessage(t("contacts.error_adding_contact" + error));
      }
    }
  };

  useEffect(() => {
    register("email");
    if (watchContactMethod === "email") {
      register("email");
      unregister("phone");
    } else if (watchContactMethod === "phone") {
      register("phone");
      unregister("email");
    } else if (watchContactMethod === "both") {
      register("email");
      register("phone");
    }
  }, [watchContactMethod, register, unregister]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("contacts.add_title")}</DialogTitle>
        <DialogContent>
          <Controller
            name={"name"}
            control={control}
            rules={{
              required: true,
              minLength: 2,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  label={t("common.name")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                />
              </FormControl>
            )}
          />
          <Controller
            name={"contactMethod"}
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  label={t("contacts.contact_method")}
                  fullWidth
                  {...field}
                  select
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("contactMethod")}
                >
                  <MenuItem value="email">{t("contacts.email")}</MenuItem>
                  <MenuItem value="phone">{t("contacts.phone")}</MenuItem>
                  <MenuItem value="both">
                    {t("contacts.email_and_sms")}
                  </MenuItem>
                </TextField>
              </FormControl>
            )}
          />

          {watchContactMethod === "email" && (
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    mt: 1,
                  }}
                >
                  <TextField
                    label={t("contacts.email")}
                    fullWidth
                    {...field}
                    error={error !== undefined}
                    helperText={error ? validationLabels.email[error.type] : ""}
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  />
                </FormControl>
              )}
            />
          )}

          {
            // if watchContactMethod is phone, both or empty then show phone field
            watchContactMethod === "phone" && (
              <Controller
                name={"phone"}
                control={control}
                rules={{
                  required: true,
                  //regex for phone number, minimum 10 digits, must support numbers like +61408202352
                  pattern: /^\+[1-9]{1}[0-9]{3,14}$/,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      label={t("contacts.phone")}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.phone[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                    />
                  </FormControl>
                )}
              />
            )
          }

          {
            // if watchContactMethod is both or empty then show phone and email fields
            watchContactMethod === "both" && (
              <>
                <Controller
                  name={"email"}
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        mt: 1,
                      }}
                    >
                      <TextField
                        label={t("contacts.email")}
                        fullWidth
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error ? validationLabels.email[error.type] : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name={"phone"}
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^\+[0-9]{1,3}\s?[0-9]{10}$/,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        mt: 1,
                      }}
                    >
                      <TextField
                        label={t("contacts.phone")}
                        fullWidth
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error ? validationLabels.phone[error.type] : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                      />
                    </FormControl>
                  )}
                />
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.add")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
