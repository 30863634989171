import React from "react";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function ButtonPressedCard(props) {
  const { alarm } = props;
  const [buttonPressed, setButtonPressed] = React.useState(0);
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const theme = useTheme();

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      setButtonPressed(jsonData.button_pressed);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            {alarm ? "Alarm Triggered?" : "Button Pressed?"}
          </Typography>
        </Box>
        <Tooltip
          title={
            alarm
              ? `Alarm Triggered: ${buttonPressed === 1 ? "Yes" : "No"}`
              : `Button Pressed: ${buttonPressed === 1 ? "Yes" : "No"}`
          }
        >
          <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
            {buttonPressed === 1 ? "Yes" : "No"}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
