// show an auth error page

import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import { Link } from "react-router-dom";
import setTitle from "../Functions/setTitle";

export default function AuthError() {
  setTitle("Auth Error");

  return (
    <Card
      sx={{
        maxWidth: 345,
        margin: "auto",
        marginTop: "50px",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <CardHeader title="Auth Error" subheader="Please try again" />
      <CardContent>
        <p>There was an error with your authentication. Please try again.</p>
      </CardContent>
      <CardActions>
        <Link to="/">
          <Button variant="contained" color="primary">
            Go Home
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}
