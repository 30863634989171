import fetchData from "../API/Fetch";
import { store } from "../store";

export const fetchReports = (orgID) => {
  if (!orgID) {
    store.dispatch({ type: "reports/update", payload: [] });
    return;
  }

  fetchData(`/report/${orgID}`).then((response) => {
    if (response) {
      store.dispatch({
        type: "reports/update",
        payload: response,
      });
    } else {
      store.dispatch({ type: "reports/update", payload: [] });
    }
  });
};
