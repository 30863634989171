import React from "react";
import { Box, Typography } from "@mui/material";
import DataTableBase from "../DataTableBase";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatDate } from "../../Functions/formatDate";

export default function OrganisationLogsTable() {
  const { t } = useTranslation("common");

  const organisationLogs = useSelector((state) => state.organisationLogs);

  //columns for devices for data table
  const columns = [
    {
      name: "id",
      label: t("common.ID"),
      options: {
        display: false,
      },
    },
    {
      name: "time_created",
      label: "Time Created",
      options: {
        customBodyRender: (value) => {
          return <Typography>{formatDate(value)}</Typography>;
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "user_email",
      label: "User",
      options: {
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
  ];

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <DataTableBase
          columns={columns}
          data={organisationLogs}
          title={"Organisation Logs"}
          id="organisation-logs"
          name="organisation-logs"
          pageSize={10}
          sortOrder="time_created"
          sortDirection="desc"
        />
      </Box>
    </>
  );
}
