import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function HumidityGuague(props) {
  const [humidity, setHumidity] = React.useState(0);
  const [defaultArch, setDefaultArch] = React.useState([
    {
      limit: 20,
      //red
      color: "#EA4228",
    },
    {
      limit: 30,
      // yellow
      color: "#F5CD19",
    },
    {
      limit: 70,
      //green
      color: "#5BE12C",
    },
    {
      limit: 80,
      color: "#F5CD19",
    },
    {
      limit: 100,
      color: "#EA4228",
    },
  ]);
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(100);

  const { humidityValue, title } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      let humidity;

      if (humidityValue) {
        humidity = jsonData[humidityValue];
      } else {
        humidity = jsonData.humidity;
      }

      //check if value is an array, if it is get the first value
      if (Array.isArray(humidity)) {
        humidity = humidity[0];
      }

      //get humidity
      setHumidity(humidity);

      if (props.deviceProfile) {
        let deviceProfile = JSON.parse(props.deviceProfile);

        let arch = [
          //use min and max
          {
            limit: parseFloat(deviceProfile.humidity.safe_range_start),
            //red
            color: "#EA4228",
          },
          {
            limit: parseFloat(deviceProfile.humidity.safe_range_end),
            // GREEn
            color: "#5BE12C",
          },
          {
            //dark red
            color: "#EA4228",
          },
        ];

        setDefaultArch(arch);
        setMinValue(deviceProfile.humidity.min);
        setMaxValue(deviceProfile.humidity.max);
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, props.deviceProfile, humidityValue]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={title ? title : "Humidity"}
        action={<Typography variant="h5">{humidity + "%"}</Typography>}
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
        }}
        value={humidity}
        minValue={minValue}
        maxValue={maxValue}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax
          type="humidity"
          value={humidityValue ? humidityValue : "humidity"}
          unit="%"
        />
      </Box>
    </Card>
  );
}
