import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function DevicePageCard(props) {
  const [lastValue, setLastValue] = React.useState("N/A");

  const { value, unit, title, formatter } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (deviceHistory && deviceHistory.length) {
      //get last data object
      let lastDataObject = deviceHistory[deviceHistory.length - 1];
      //parse data
      let jsonData = lastDataObject.data;
      jsonData = JSON.parse(jsonData);
      //get last level
      let last_value = jsonData[value];
      //set last level

      if (formatter) {
        last_value = formatter(last_value);
      }
      if (unit) {
        last_value = last_value + " " + unit;
      }
      setLastValue(last_value);
    }
  }, [deviceHistory, value, formatter, lastValue, unit]);

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Typography
          variant="h4"
          //dont wrap text
          noWrap
          //hide overflow
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        ></Typography>
      </CardContent>
    </Card>
  );
}
