import fetchData from "../API/Fetch";
import { CONFIG } from "../config";

export default async function checkBilling() {
  //check if billing is enabled

  if (!CONFIG.billing_enabled) return true;

  //if current path is /billing/limit-reached or /account, return
  if (
    window.location.pathname === "/billing/limit-reached" ||
    window.location.pathname === "/billing/register-card" ||
    window.location.pathname === "/billing/invoice-due" ||
    window.location.pathname === "/billing/success" ||
    window.location.pathname === "/billing/cancel" ||
    window.location.pathname === "/billing/subscription-success"
  )
    return;

  fetchData(`/billing/check`).then((data) => {
    if (!data) return false;

    //check response message
    //card_required = redirect to /billing/register-card
    //limit_reached = redirect to /billing/limit-reached
    //billing_inactive = redirect to /billing/register-card

    if (data.message === "card_required") {
      window.location.href = "/billing/register-card";
      return false;
    }

    if (data.message === "limit_reached") {
      window.location.href = "/billing/limit-reached";
      return false;
    }

    if (data.message === "billing_inactive") {
      window.location.href = "/billing/register-card";
      return false;
    }

    return true;
  });
}
