export default function hasPermission(organisationUserRoles, userID, role) {
  if (!organisationUserRoles || !userID || !role) {
    return false;
  }

  let userRole = organisationUserRoles.find(
    (userrole) => parseInt(userrole.user_id) === parseInt(userID)
  );

  if (!userRole) {
    return false;
  }

  if (userRole.admin_permission) {
    return true;
  }

  if (role === "add") {
    return userRole.add_permission;
  } else if (role === "edit") {
    return userRole.edit_permission;
  } else if (role === "delete") {
    return userRole.delete_permission;
  } else if (role === "transfer") {
    return userRole.transfer_permission;
  }

  return false;
}
