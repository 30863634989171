const initialState = 0;

function currentReducer(state = initialState, action) {
  switch (action.type) {
    case "current/update":
      return action.payload;
    case "current/add":
      return state + 1;
    default:
      return state;
  }
}

export default currentReducer;
