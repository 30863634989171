import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function SoilMoistureGuague(props) {
  const [soil_moisture, setSoilMoisture] = React.useState(0);
  const defaultArch = [
    //anything less than 40% is red and anything greater than 80% is red
    //anything between 60% and 80% is green
    // anything between 40% and 60% is yellow

    {
      limit: 40,
      color: "#EA4228",
    },

    {
      limit: 50,
      color: "#F5CD19",
    },

    {
      limit: 80,
      color: "#5BE12C",
    },
    {
      limit: 90,
      color: "#F5CD19",
    },
    {
      limit: 100,
      color: "#EA4228",
    },
  ];

  const minValue = 0;
  const maxValue = 100;

  const { value } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      let soil_moisture;

      if (value) {
        soil_moisture = jsonData[value];
      } else {
        soil_moisture = jsonData.soil_moisture;
      }

      //check if value is an array, if it is get the first value
      if (Array.isArray(soil_moisture)) {
        soil_moisture = soil_moisture[0];
      }

      //get soil_moisture
      setSoilMoisture(soil_moisture);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, props.deviceProfile, value]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title="Soil Moisture"
        action={<Typography variant="h5">{soil_moisture + ""}</Typography>}
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
        }}
        value={soil_moisture}
        minValue={minValue}
        maxValue={maxValue}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax
          type="soil_moisture"
          value={value ? value : "soil_moisture"}
          unit=""
        />
      </Box>
    </Card>
  );
}
