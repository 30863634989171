import * as React from "react";
import Map, {
  FullscreenControl,
  Layer,
  NavigationControl,
  ScaleControl,
  Source,
} from "react-map-gl";
import { useSelector } from "react-redux";
import { CONFIG } from "../../config";
import mapboxgl from "mapbox-gl";
import { updateCurrent } from "../../Functions/updateCurrent";
import getCleanedPolyline from "../../Functions/getCleanedPolyline";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { updatePitch } from "../../Functions/updatePitch";
import { formatDate } from "../../Functions/formatDate";
import convertMvToPercent from "../../Functions/convertMvToPercent";
import { useTheme } from "@mui/material";

export default function DeviceMap() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);
  const device = useSelector((state) => state.device);
  const current = useSelector((state) => state.current);
  const [currentData, setCurrentData] = React.useState(null);
  const mapRef = React.useRef(null);
  const [polylineHistory, setPolylineHistory] = React.useState([]);
  const pitch = useSelector((state) => state.pitch);
  const theme = useTheme();

  class PitchToggle {
    constructor({ bearing = 0, pitch = 60, minpitchzoom = null }) {
      this._bearing = bearing;
      this._pitch = pitch;
      this._minpitchzoom = minpitchzoom;
    }

    onAdd(map) {
      this._map = map;
      let _this = this;

      this._btn = document.createElement("button");

      // get pitch and set button icon
      if (localStorage.getItem("pitch") === "0") {
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        this._btn.title = "Toggle Pitch (3D)";
      } else {
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        this._btn.title = "Toggle Pitch (2D)";
      }
      this._btn.type = "button";
      this._btn["aria-label"] = "Toggle Pitch";
      this._btn.onclick = function () {
        if (map.getPitch() === 0) {
          let options = { pitch: _this._pitch, bearing: _this._bearing };
          if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
            options.zoom = _this._minpitchzoom;
          }
          updateSnackbarMessage("3D View Enabled");
          updatePitch(60);
          //set pitch in local storage so it can be retrieved when the page is refreshed
          localStorage.setItem("pitch", 60);

          map.easeTo({ pitch: 60, bearing: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        } else {
          updatePitch(0);
          updateSnackbarMessage("2D View Enabled");
          localStorage.setItem("pitch", 0);

          map.easeTo({ pitch: 0, bearing: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        }
      };

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
      this._container.appendChild(this._btn);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  const pitchToggle = new PitchToggle({
    bearing: 0,
    pitch: pitch,
    minpitchzoom: null,
  });

  const size = 200;

  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    // When the layer is added to the map,
    // get the rendering context for the map canvas.
    onAdd: function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext("2d");
    },

    // Call once before every frame where the icon will be used.
    render: function () {
      const context = this.context;

      // Check if device history is empty
      if (deviceHistory.length === 0) {
        const radius = (size / 3) * 0.3;
        const outerRadius = (size / 3) * 0.7 * 0 + radius;

        context.clearRect(0, 0, 150, 150);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = "red";
        context.strokeStyle = "black";
        context.lineWidth = 2;
        context.fill();
        context.stroke();
      } else {
        const duration = 3000;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 3) * 0.3;
        const outerRadius = (size / 3) * 0.7 * t + radius;

        // Draw the outer circle.
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = `rgba(${CONFIG.primaryColourRGB.r}, ${
          CONFIG.primaryColourRGB.g
        }, ${CONFIG.primaryColourRGB.b}, ${1 - t})`;
        context.fill();

        // Draw the inner circle.
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = CONFIG.primaryColour;
        context.strokeStyle = CONFIG.secondaryColour;
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();
      }

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      mapRef.current.triggerRepaint();

      // Return `true` to let the map know that the image was updated.
      return true;
    },
  };

  const handleMapLoad = (e) => {
    mapRef.current = e.target;
    //fit to bounds of device history

    if (!mapRef.current) return;

    drawPolyline();
    if (polylineHistory.length) {
      //if polyline length = 1, fly to that point
      if (polylineHistory.length === 1) {
        let coord = polylineHistory[0];

        if (coord.length) {
          //if its a string with , split it, otherwise if array, use it

          let lat, lng;

          if (typeof coord === "string") {
            let split = coord.split(",");
            lng = parseFloat(split[0]);
            lat = parseFloat(split[1]);
          } else {
            lng = coord[0];
            lat = coord[1];
          }

          //fly to lat long, no animation
          mapRef.current.flyTo({
            center: [lng, lat],
            zoom: 16,
            animate: false,
            pitch: pitch,
          });
        }
      } else {
        let bounds = new mapboxgl.LngLatBounds();
        polylineHistory.forEach((point) => {
          bounds.extend(point);
        });

        //if the bounds are too small. less than 300m, fly to the center of the bounds
        if (bounds.getNorthEast().distanceTo(bounds.getSouthWest()) < 300) {
          mapRef.current.flyTo({
            center: bounds.getCenter(),
            zoom: 16,
            animate: false,
            pitch: pitch,
          });
        } else {
          mapRef.current.fitBounds(bounds, {
            padding: 50,
            animate: false,
            pitch: pitch,
          });
        }
      }
    } else {
      //we only have the device.lat and device.lng, fly to that
      mapRef.current.flyTo({
        center: [device.lng, device.lat],
        zoom: 16,
        animate: false,
        pitch: pitch,
      });
    }
    mapRef.current.addControl(pitchToggle, "top-left");
    mapRef.current.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });

    mapRef.current.on("click", "device-history-circles", (e) => {
      let coordinates = e.features[0].geometry.coordinates.slice();
      let description = e.features[0].properties.data;

      //desc should read time_created, geocode, battery

      description = `<h4>${formatDate(
        e.features[0].properties.time_created
      )}</h4>`;
      description += `<p>Location: ${e.features[0].properties.geocode}</p>`;
      description += `<p>Battery: ${convertMvToPercent(
        e.features[0].properties.battery,
        device.device_brand
      )}%</p>`;

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(mapRef.current);
    });

    mapRef.current.on("click", "currentLocation", (e) => {
      let coordinates = e.features[0].geometry.coordinates.slice();
      let description = e.features[0].properties.data;

      //desc should read time_created, geocode, battery

      description = `<h4>${formatDate(
        e.features[0].properties.time_created
      )}</h4>`;
      description += `<p style="color: '#000000'">Geocode: ${e.features[0].properties.geocode}</p>`;
      description += `<p>Battery: ${convertMvToPercent(
        e.features[0].properties.battery,
        device.device_brand
      )}%</p>`;

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(mapRef.current);
    });
  };

  const drawPolyline = React.useCallback(async () => {
    let polyline = deviceHistory.map((point) => {
      let data = JSON.parse(point.data);
      return [data.longitude, data.latitude];
    });

    if (deviceProfile) {
      //it might need json decoding, check
      if (typeof deviceProfile === "string") {
        let deviceProfileDecoded = JSON.parse(deviceProfile);

        if (!deviceProfileDecoded.tracker) return;
        if (!deviceProfileDecoded.tracker.route_matching_type) return;
        if (deviceProfileDecoded.tracker.route_matching_type !== "none") {
          polyline = await getCleanedPolyline(
            polyline,
            deviceProfileDecoded.tracker.route_matching_type
          );
        }
      }

      //if polyline is empty, set it to deviceHistory
      if (polyline.length === 0) {
        polyline = deviceHistory.map((point) => {
          let data = JSON.parse(point.data);
          return [data.longitude, data.latitude];
        });
      }
    }

    //remove any null, 0 or 0.0 values
    polyline = polyline.filter((point) => {
      if (!point) return false;
      if (point[0] === 0 || point[1] === 0) return false;
      if (point[0] === 0.0 || point[1] === 0.0) return false;
      if (point[0] === null || point[1] === null) return false;
      if (point[0] === undefined || point[1] === undefined) return false;
      return true;
    });

    //remove all null values from polyline
    polyline = polyline.filter((point) => {
      if (!point) return false;
      return true;
    });

    setPolylineHistory(polyline);
  }, [deviceHistory, deviceProfile]);

  React.useEffect(() => {
    drawPolyline();
    setCurrentData(deviceHistory[current]);
    let lastHistory = deviceHistory[deviceHistory.length - 1];
    if (lastHistory) {
      let data = JSON.parse(lastHistory.data);
      setCurrentData(data);
    }
  }, [deviceHistory, drawPolyline, current]);

  React.useEffect(() => {
    if (!mapRef.current) return;

    //clean deviceHistory of any null, 0 or 0.0 values
    let deviceHistoryClean = deviceHistory.filter((point) => {
      let data = JSON.parse(point.data);
      if (!data.longitude || !data.latitude) return false;
      if (data.longitude === 0 || data.latitude === 0) return false;
      if (data.longitude === "0.0" || data.latitude === "0.0") return false;
      return true;
    });

    //if current > deviceHistory.length, set current to -1
    if (current >= deviceHistoryClean.length) {
      updateCurrent(deviceHistoryClean.length - 1);
      return;
    }

    if (!deviceHistory[current]) return;
    let data = JSON.parse(deviceHistoryClean[current].data);

    //append time_created to data
    data.time_created = deviceHistoryClean[current].time_created;

    setCurrentData(data);

    if (mapRef.current) {
      //fly to , no animation
      mapRef.current.flyTo({
        center: [data.longitude, data.latitude],
        zoom: 16,
        animate: false,
        pitch: pitch,
      });
    }
  }, [current, mapRef, deviceHistory, pitch]);

  return (
    <Map
      mapboxApiAccessToken={CONFIG.mapboxAccessToken}
      mapStyle={
        theme.palette.mode === "light"
          ? "mapbox://styles/mapbox/streets-v11"
          : "mapbox://styles/mapbox/dark-v10"
      }
      height="100%"
      onLoad={(e) => {
        handleMapLoad(e);
      }}
      id="map-panel"
    >
      <Source
        id="device-history"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: deviceHistory.map((point) => {
            let data = JSON.parse(point.data);

            //if longitude or latitude is null or 0, return
            if (!data.longitude || !data.latitude) return {};
            //if longitude or latitude is 0, return
            if (data.longitude === 0 || data.latitude === 0) return {};
            //if longitude or latitude is 0.0, return
            if (data.longitude === 0.0 || data.latitude === 0.0) return {};
            if (data.longitude === "0.0" || data.latitude === "0.0") return {};
            if (data.longitude === null || data.latitude === null) return {};
            if (data.longitude === undefined || data.latitude === undefined)
              return {};

            if (data.longitude === "0" || data.latitude === "0") return {};

            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [data.longitude, data.latitude],
              },
              properties: {
                geocode: data.geocode,
                time_created: point.time_created,
                battery: data.voltageMv,
              },
            };
          }),
        }}
      />

      <Source
        id="polylineHistory"
        type="geojson"
        data={{
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: polylineHistory,
          },
        }}
      />

      {currentData ? (
        <>
          <Source
            id="dot-point"
            type="geojson"
            data={{
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [currentData.longitude, currentData.latitude],
                  },
                  properties: {
                    geocode: currentData.geocode,
                    time_created: currentData.time_created,
                    battery: currentData.voltageMv,
                  },
                },
              ],
            }}
          />
        </>
      ) : (
        //no current data, get device.lat and device.lng

        <Source
          id="dot-point"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [device.lng, device.lat],
                },
                properties: {
                  geocode: device.last_location,
                  time_created: device.time_updated,
                  battery: convertMvToPercent(
                    device.batteryMv,
                    device.device_brand
                  ),
                },
              },
            ],
          }}
        />
      )}
      <Layer
        id="device-history-circles"
        type="circle"
        source="device-history"
        paint={{
          "circle-radius": 6,
          "circle-color": CONFIG.primaryColour,
          "circle-opacity": 0.8,
        }}
      />
      <Layer
        id="polylineHistory"
        type="line"
        source="polylineHistory"
        layout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        paint={{
          "line-color": CONFIG.primaryColour,
          "line-width": 3,
          "line-opacity": 1,
        }}
      />

      <Layer
        id="currentLocation"
        type="symbol"
        source="dot-point"
        layout={{
          "icon-image": "pulsing-dot",
        }}
      />
      {
        //add controls for zooming and rotating,
      }

      <NavigationControl position="top-left" />
      <FullscreenControl position="top-left" />
      <ScaleControl position="bottom-right" />
    </Map>
  );
}
