import React, { useEffect, useState } from "react";
import { Create, Dns, Input, QrCodeOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ScanQRCodeDialog from "./ScanQR";
import ClaimCodeDialog from "./claimCode";
import SelfHostDialog from "./selfHost";
import ManualKeysDialog from "./manualKeys";
import { CONFIG } from "../../config";
import { useSelector } from "react-redux";
import fetchRemainingDeviceLimit from "../../Functions/fetchRemainingDeviceLimit";

export default function AddDeviceDialog(props) {
  const [openQrScanner, setOpenQrScanner] = useState(false);
  const [openManual, setOpenManual] = useState(false);
  const [openSelfHost, setOpenSelfHost] = useState(false);
  const [openClaimCode, setOpenClaimCode] = useState(false);

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const user = useSelector((state) => state.user);

  const [limitReached, setLimitReached] = useState(false);

  //we need to check the account owners device limit has not been reached, if it has we replace the add device button with a message saying they have reached their limit and need to upgrade their account
  useEffect(() => {
    if (CONFIG.billing_enabled) {
      if (!selectedOrganisation) {
        return;
      }
      if (!selectedOrganisation.id) {
        return;
      }

      //check the config to see if billing is enabled

      async function fetchData(organisationId) {
        let limit = await fetchRemainingDeviceLimit(organisationId);

        if (limit <= 0) {
          setLimitReached(true);
        } else {
          setLimitReached(false);
        }
      }

      fetchData(selectedOrganisation.id);
    }
  }, [selectedOrganisation]);

  const { t } = useTranslation("common");

  const { open, handleClose } = props;

  const handleQrScannerClose = () => {
    setOpenQrScanner(false);
  };

  const handleManualClose = () => {
    setOpenManual(false);
    handleClose();
  };

  const handleClaimCodeClose = () => {
    setOpenClaimCode(false);
    handleClose();
  };

  const handleSelfHostClose = () => {
    setOpenSelfHost(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>{t("devices.add_device_title")}</DialogTitle>
      <DialogContent>
        {limitReached &&
        selectedOrganisation &&
        selectedOrganisation.owner_id !== user.id ? (
          <Stack spacing={1}>
            <>
              <Typography>
                Device Limit reached, Please ask the organisation owner to
                increase their device limit
              </Typography>
              <Button variant="contained" color="primary" onClick={handleClose}>
                OK
              </Button>
            </>
            }
          </Stack>
        ) : (
          <Stack spacing={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenQrScanner(true)}
              startIcon={<QrCodeOutlined />}
            >
              {t("devices.scan_qr_code")}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenClaimCode(true)}
              startIcon={<Input />}
            >
              {t("devices.enter_claim_code")}
            </Button>
            {CONFIG.self_host && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenSelfHost(true)}
                startIcon={<Dns />}
              >
                {t("devices.self_host")}
              </Button>
            )}
            {CONFIG.manual_add && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenManual(true)}
                startIcon={<Create />}
              >
                {t("devices.enter_manually")}
              </Button>
            )}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
      </DialogActions>

      <ScanQRCodeDialog
        open={openQrScanner}
        handleClose={handleQrScannerClose}
      />
      <ClaimCodeDialog
        open={openClaimCode}
        handleClose={handleClaimCodeClose}
      />
      <SelfHostDialog open={openSelfHost} handleClose={handleSelfHostClose} />
      <ManualKeysDialog open={openManual} handleClose={handleManualClose} />
    </Dialog>
  );
}
