import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function EventCard() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [eventStatus, setEventStatus] = React.useState("No Event");

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      setEventStatus(jsonData.event);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <>
      <Card sx={{ height: "92%", mb: 1 }}>
        <CardHeader title={"Event Status"} />
        <CardContent>
          <Typography variant="h5">
            {eventStatus !== "" ? eventStatus : "No Event"}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
