import getMatch from "./getMatch";

// use mapbox matching api to clean up the line
export default async function getCleanedPolyline(coordinates, profile) {
  try {
    // split coordinates into 25 point chunks, if last chunk is less than 100 points, add it to the previous chunk
    let chunks = [];
    let chunk = [];
    for (let i = 0; i < coordinates.length; i++) {
      if (chunk.length < 25) {
        chunk.push(coordinates[i]);
      } else {
        chunks.push(chunk);
        chunk = [];
        chunk.push(coordinates[i]);
      }
    }
    if (chunk.length > 0) {
      chunks.push(chunk);
    }

    // get the cleaned line for each chunk
    let cleanedLines = [];
    for (let i = 0; i < chunks.length; i++) {
      let cleanedLine = await getMatch(chunks[i].join(";"), profile);
      cleanedLines.push(cleanedLine);
    }

    // reduce the array of arrays to a single array
    cleanedLines = cleanedLines.reduce((acc, val) => acc.concat(val), []);

    return cleanedLines;
  } catch (e) {
    console.log("error cleaning line", e);
    return coordinates;
  }
}
