import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import deleteOrganisationMember from "../../Functions/deleteOrganisationMember";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function OrganisationMemberDeleteDialog(props) {
  const { open, onClose, member_id, organisation } = props;

  const { t } = useTranslation("common");

  const deleteMember = async () => {
    await deleteOrganisationMember(organisation.id, member_id);
    updateSnackbarMessage("Organisation Member deleted successfully");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="organisation-delete-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="organisation-delete-dialog-title">
        {t("organisations.delete_member")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("organisations.delete_member_confirm")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t("common.cancel")}
        </Button>
        <Button variant="contained" onClick={deleteMember} color="primary">
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
