import React from "react";

import DeviceMetric from "./DeviceMetric";

export default function MoldIndex() {
  return (
    <DeviceMetric
      title="Mold Risk"
      value="mold_index"
      icon="virus"
      iconSize="6x"
      aspect={10}
      metricWidth={3}
      graphWidth={12}
      unit=""
      textsize="h3"
      valueColor="red"
    />
  );
}
