import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Stack } from "@mui/system";
import { Button, Grid, Tooltip, useTheme } from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ActionAddDialog from "../components/Dialogs/actionAdd";
import { formatDate } from "../Functions/formatDate";
import moment from "moment";
import { useSelector } from "react-redux";
import fetchActions from "../Functions/fetchActions";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import PageTitle from "../components/PageTitle";
import setTitle from "../Functions/setTitle";
import ActionsTable from "../components/Tables/ActionsTable";
import hasPermission from "../Functions/hasPermission";

function Actions() {
  const user = useSelector((state) => state.user);
  const isMobile = useSelector((state) => state.isMobile);
  const actions = useSelector((state) => state.actions);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const userOrganisationRoles = useSelector(
    (state) => state.userOrganisationRoles
  );
  const [addActionDialogOpen, setAddActionDialogOpen] = useState(false);

  const theme = useTheme();
  const { t } = useTranslation("common");

  const handleAddActionClick = () => {
    setAddActionDialogOpen(true);
  };

  const handleRefreshActionsClick = () => {
    fetchActions(localStorage.getItem("selectedOrganisationID"));
    updateSnackbarMessage(t("common.refreshed") + " " + formatDate(moment()));
  };

  const renderAddActionButton = () => {
    if (
      (selectedOrganisation &&
        user &&
        selectedOrganisation.owner_id === user.id) ||
      hasPermission(userOrganisationRoles, user.id, "add")
    ) {
      return (
        <Tooltip title="Add an action" placement="top" arrow>
          <Button
            variant="outlined"
            color={theme.palette.mode === "dark" ? "primary" : "secondary"}
            onClick={handleAddActionClick}
            sx={{ mr: 1 }}
          >
            <Add />
          </Button>
        </Tooltip>
      );
    }
    return null;
  };

  useEffect(() => {
    setTitle("Actions", selectedOrganisation);
  }, [selectedOrganisation]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 1 }}
          >
            <PageTitle
              theme={theme}
              title={
                selectedOrganisation && selectedOrganisation.name
                  ? selectedOrganisation.name + " " + t("actions.name")
                  : t("actions.name")
              }
              subTitle={
                actions && actions.length + " actions in this organisation"
              }
            />
            {renderAddActionButton()}
            <Tooltip title="Refresh Actions" placement="top" arrow>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={handleRefreshActionsClick}
              >
                <Refresh />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>

      <ActionsTable />

      <ActionAddDialog
        open={addActionDialogOpen}
        setOpen={setAddActionDialogOpen}
        handleClose={() => {
          setAddActionDialogOpen(false);
          fetchActions(localStorage.getItem("selectedOrganisationID"));
        }}
      />
    </>
  );
}

export default withAuthenticationRequired(Actions);
