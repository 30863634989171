import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { useSelector } from "react-redux";

export default function WaterUsageGuage(props) {
  const [instantaneousFlow, setInstantaneousFlow] = React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (!deviceHistory || !deviceHistory.length) {
      return;
    }
    //get last data object from deviceHistory
    let lastData = deviceHistory[deviceHistory.length - 1];
    //parse data
    let jsonData = lastData.data;
    jsonData = JSON.parse(jsonData);

    //set instantaneousFlow to value of instantaneousFlow
    let instantaneousFlow = jsonData.instantaneousFlow;
    setInstantaneousFlow(instantaneousFlow);
  }, [deviceHistory]);

  return (
    <GaugeComponent
      type="semicircle"
      arc={{
        width: 0.2,
        padding: 0.005,
        cornerRadius: 1,
        //gradient: true,
      }}
      pointer={{
        color: "#345243",
        length: 0.8,
        width: 15,
        // elastic: true,
      }}
      labels={{
        valueLabel: { formatTextValue: (value) => value + "L/min" },
        tickLabels: {
          type: "outer",
        },
      }}
      value={instantaneousFlow ? instantaneousFlow : 0}
      //set to use litres as unit
      valueFormat={"litres"}
    />
  );
}
