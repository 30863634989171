import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import deleteDashboard from "../../Functions/dashboardDelete";

export default function DeleteDashboardConfirmationDialog(props) {
  const { open, handleClose, dashboardId, refreshDashboard, dashboardName } =
    props;

  const handleDelete = () => {
    deleteDashboard(dashboardId);
    refreshDashboard();
    updateSnackbarMessage("Dashboard Deleted", "success");
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Confirm Deletion Of Dashboard: {dashboardName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this dashboard?
          <br />
          <br /> NOTE: It cannot be restored after deletion.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
