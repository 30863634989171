import { Box } from "@mui/material";
import formatTemperature from "../../Functions/formatTemperature";
import ImagePinMetric from "../ImagePinMetric";

export default function DevicePin({ device, isMobile, theme }) {
  let last_data = device.last_data ? JSON.parse(device.last_data) : null;

  if (last_data) {
    return (
      <>
        {device.device_type === 1 && <></>}
        {device.device_type === 2 && (
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              { title: "Humidity", key: "humidity", unit: "%" },
              { title: "CO2", key: "co2", unit: "ppm" },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 3 && (
          // Temp, Hum
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              { title: "Humidity", key: "humidity", unit: "%" },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 4 && (
          // Tracker with button
          <></>
        )}
        {device.device_type === 5 && (
          //distance sensor
          <>
            <Card
              fields={[
                {
                  title: "Distance",
                  key: "distance",
                  unit: "mm",
                },
              ]}
              theme={theme}
              last_data={last_data}
            />
          </>
        )}
        {device.device_type === 6 && (
          //motion sensor
          <Card
            fields={[
              {
                title: "Motion?",
                key: "motion",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={last_data && !last_data.motion}
          />
        )}
        {device.device_type === 7 && (
          //temperature

          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                formatter: formatTemperature,
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 8 && (
          //paxcounter

          <Card
            fields={[
              {
                title: "Total",
                key: "count",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 9 && (
          //temperature 1 and 2
          <Card
            fields={[
              {
                title: "Temp 1",
                key: "temperature_1",
                unit: "",
              },
              {
                title: "Temp 2",
                key: "temperature_2",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 10 && (
          //motion sensor
          <Card
            fields={[
              {
                title: "Motion",
                key: "motion",
                unit: "",
              },
              {
                title: "Time",
                key: "time",
                unit: "s",
              },
              {
                title: "Count",
                key: "count",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={last_data && !last_data.motion}
          />
        )}
        {device.device_type === 11 && (
          //Door/Window Sensor
          <Card
            fields={[
              {
                title: "Open?",
                key: "door_open",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={last_data && !last_data.motion}
          />
        )}
        {device.device_type === 12 && (
          //Water Leak Sensor
          <Card
            fields={[
              {
                title: "Leak?",
                key: "water_leak",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={last_data && !last_data.water_leak}
          />
        )}
        {device.device_type === 13 && (
          //Air Quality Sensor with tvoc, co2, temperature, humidity
          <Card
            fields={[
              {
                title: "TVOC",
                key: "tvoc",
                unit: "ppb",
              },
              {
                title: "CO2",
                key: "co2",
                unit: "ppm",
              },
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Humidity",
                key: "humidity",
                unit: "%",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 14 && (
          //pet tracker,show location and isLost (true/false)

          <></>
        )}
        {device.device_type === 15 && (
          // Oyster3
          <></>
        )}
        {device.device_type === 16 && (
          // Oyster3
          <></>
        )}
        {device.device_type === 17 && (
          // Cayenne LLP
          <></>
        )}
        {device.device_type === 18 && (
          //tektelic vivid
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Humidity",
                key: "humidity",
                unit: "%",
              },
              {
                title: "CO2",
                key: "co2",
                unit: "ppm",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 19 && (
          /*sensecore Kraken*/
          <Card
            fields={[
              {
                title: "Has Flow?",
                key: "HasFlowRate",
                unit: "",
              },
              {
                title: "Current Flow",
                key: "instantaneousFlow",
                unit: "L/min",
              },
              {
                title: "Current Velocity",
                key: "instantaneousVelocity",
                unit: "m/s",
              },
              {
                title: "Total Usage",
                key: "positiveCumulative",
                unit: "L",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
          />
        )}
        {device.device_type === 20 && (
          /*distance sensor 2*/
          <Card
            fields={[
              {
                title: "Distance",
                key: "distance",
                unit: "mm",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 21 && (
          /*power meter*/
          <Card
            fields={[
              {
                title: "Current",
                key: "current",
                unit: "A",
              },
              {
                title: "Voltage",
                key: "voltage",
                unit: "V",
              },
              {
                title: "Watt",
                key: "watt",
                unit: "W",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 22 && (
          <Card
            fields={[
              {
                title: "Leak?",
                key: "leak",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={last_data && !last_data.leak}
          />
        )}
        {device.device_type === 23 && (
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Humidity",
                key: "humidity",
                unit: "%",
              },
              {
                title: "CO2",
                key: "co2",
                unit: "ppm",
              },
              {
                title: "TVOC",
                key: "tvoc",
                unit: "",
              },
              {
                title: "PM2.5",
                key: "pm2_5",
                unit: "µg/m³",
              },
              {
                title: "AQI",
                key: "aqi",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 24 && (
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Humidity",
                key: "humidity",
                unit: "%",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 25 && (
          <Card
            fields={[
              {
                title: "Contact",
                key: "state",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={last_data && !last_data.state}
          />
        )}
        {device.device_type === 26 && (
          <Card
            fields={[
              {
                title: "Motion?",
                key: "state",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={last_data && !last_data.state}
          />
        )}
        {device.device_type === 27 && (
          <Card
            fields={[
              {
                title: "Min",
                key: "min",
                unit: "",
              },
              {
                title: "Max",
                key: "max",
                unit: "",
              },
              {
                title: "Avg",
                key: "avg",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 28 && (
          <Card
            fields={[
              {
                title: "Ambient Temp",
                key: "ambient_temp",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Leak?",
                key: "leak_status",
                unit: "",
              },
              {
                title: "Total Volume",
                key: "total_volume",
                unit: "L",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={last_data && !last_data.leak_status}
          />
        )}
        {device.device_type === 29 && (
          //rak 4 button
          <Card
            fields={[
              {
                title: "Button 1",
                key: "B1",
              },
              {
                title: "Button 2",
                key: "B2",
              },
              {
                title: "Button 3",
                key: "B3",
              },
              {
                title: "Button 4",
                key: "B4",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={
              last_data &&
              (last_data.B1 || last_data.B2 || last_data.B3 || last_data.B4)
            }
          />
        )}
        {device.device_type === 30 && (
          <Card
            fields={[
              {
                title: "Temp Red",
                key: "temp_red",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Temp Black",
                key: "temp_black",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Temp White",
                key: "temp_white",
                unit: "",
                formatter: formatTemperature,
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 31 && (
          //temperature and humidity
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temp",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Humidity",
                key: "humidity",
                unit: "%",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 32 && (
          <Card
            fields={[
              {
                title: "Door Open?",
                key: "door_open",
                unit: "",
              },
              {
                title: "Count",
                key: "count",
                unit: "",
              },
            ]}
            theme={theme}
            last_data={last_data}
            colorTrueFalse={true}
            trueFalse={last_data && !last_data.door_open}
          />
        )}
        {device.device_type === 33 && (
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Humidity",
                key: "humidity",
                unit: "%",
              },
              {
                title: "CO2",
                key: "co2",
                unit: "ppm",
              },
              {
                title: "PM1",
                key: "PM1_0",
                unit: "µg/m³",
              },
              {
                title: "PM2.5",
                key: "PM2_5",
                unit: "µg/m³",
              },
              {
                title: "PM10",
                key: "PM10",
                unit: "µg/m³",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 34 && (
          //temperature air, temperature soil, soil moisture
          <Card
            fields={[
              {
                title: "Air Temp",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Soil Temp",
                key: "soil_temperature",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Soil Moisture",
                key: "soil_moisture",
                unit: "%",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 35 && (
          //dragino lsph01 soil temperature, soil ph
          <Card
            fields={[
              {
                title: "Soil PH",
                key: "soil_ph",
                unit: "",
              },
              {
                title: "Soil Temp",
                key: "soil_temperature",
                unit: "",
                formatter: formatTemperature,
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 36 && (
          //dragino llms01 leaft moisture, leaf temperature
          <Card
            fields={[
              {
                title: "Leaf Moisture",
                key: "leaf_moisture",
                unit: "%",
              },
              {
                title: "Leaf Temp",
                key: "leaf_temperature",
                unit: "",
                formatter: formatTemperature,
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 37 && (
          //DraginoLSN50V2_d20
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 38 && (
          //temperature humidity\
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Humidity",
                key: "humidity",
                unit: "%",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 39 && (
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature_celsius",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Humidity",
                key: "humidity",
                unit: "%",
              },
              {
                title: "Pressure",
                key: "pressure",
                unit: "hPa",
              },
              {
                title: "Altitude",
                key: "altitude",
                unit: "m",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 40 && (
          <Card
            fields={[
              {
                title: "Temperature",
                key: "temperature",
                unit: "",
                formatter: formatTemperature,
              },
              {
                title: "Humidity",
                key: "humidity",
                unit: "%",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
        {device.device_type === 41 && (
          <Card
            fields={[
              {
                title: "Door 1",
                key: "door_open",
              },
              {
                title: "Door 2",
                key: "door_open2",
              },
            ]}
            theme={theme}
            last_data={last_data}
          />
        )}
      </>
    );
  } else {
    return <p>{device.name}</p>;
  }
}

function Card(props) {
  const { fields, last_data, theme, colorTrueFalse, trueFalse } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: colorTrueFalse
          ? trueFalse
            ? "green"
            : "red"
          : theme.palette.primary.main,
        color: colorTrueFalse ? "white" : "black",
        borderRadius: "20px",
        padding: "10px",
      }}
    >
      <DeviceMetrics
        fields={fields}
        last_data={last_data}
        colorTrueFalse={colorTrueFalse}
      />
    </Box>
  );
}

function DeviceMetrics(props) {
  const { fields, last_data, colorTrueFalse } = props;

  return (
    <>
      {fields.map((field) => (
        <ImagePinMetric
          title={field.title}
          formatter={field.formatter}
          content={
            last_data && last_data[field.key] !== undefined
              ? colorTrueFalse
                ? last_data[field.key]
                  ? "Yes"
                  : "No"
                : field.unit
                ? last_data[field.key] + field.unit
                : last_data[field.key]
              : "N/A"
          }
          colorTrueFalse={colorTrueFalse}
          trueFalse={last_data && last_data[field.key]}
        />
      ))}
    </>
  );
}
