import { useTheme } from "@emotion/react";
import { Card, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  ReferenceArea,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";

export default function MoldIndexGraph(props) {
  const [moldIndexHistory, setMoldIndexHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);

  //we want to mock this data in device history for now when the page loads, so call store.dispatch with the mock data below

  const theme = useTheme();
  const { field } = props;

  useEffect(() => {
    try {
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;
        let moldIndex;

        if (field) {
          moldIndex = jsonData[field];
        } else {
          moldIndex = jsonData.mold_index;
        }

        tempHistory.push({
          Date: time_created,
          MoldIndex: moldIndex,
        });
      });
      setMoldIndexHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory, field]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={"Mold Risk History"}
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "light" ? "#000" : "#616161",
        }}
      />
      <ResponsiveContainer width="100%" aspect={6}>
        <AreaChart
          data={moldIndexHistory}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            dataKey="Date"
            type={"category"}
            tickFormatter={(value) => {
              return formatDateOnly(value);
            }}
          />
          <YAxis
            dataKey="MoldIndex"
            type="category"
            yAxisId="1"
            domain={["Low Risk", "Moderate Risk", "High Risk"]}
            //give the label more width
            width={100}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: theme.palette.background.default,
            }}
            cursor={false}
            labelFormatter={(label) => {
              return formatDate(label);
            }}
            formatter={(value, name) => {
              return [value, "Mold Index"];
            }}
          />

          <ReferenceArea
            yAxisId="1"
            y1={"Low Risk"}
            y2={"Moderate Risk"}
            strokeOpacity={0.1}
            fill="green"
            fillOpacity={0.17}
          />
          <ReferenceArea
            yAxisId="1"
            y1={"Moderate Risk"}
            y2={"High Risk"}
            fill="red"
            fillOpacity={0.17}
          />
          <Area
            yAxisId="1"
            dataKey="MoldIndex"
            stroke={theme.palette.primary.main}
            animationDuration={300}
            fill={theme.palette.primary.main}
            strokeWidth={2}
            dominantBaseline={"middle"}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
}
