import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";

export default function TotalVolumeGraph(props) {
  const [totalVolumeHistory, setTotalVolumeHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const theme = useTheme();

  const { field } = props;

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to totalVolumeHistory
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let totalVolumeLookup = jsonData[field];

        tempHistory.push({
          Date: time_created,
          TotalVolume: totalVolumeLookup,
        });
      });

      setTotalVolumeHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory, field]);

  return (
    <ResponsiveContainer aspect={7} width="100%">
      <AreaChart
        data={totalVolumeHistory}
        margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <defs>
          <linearGradient id="TotalVolume" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="Date"
          type={"category"} //format date to be more readable
          tickFormatter={(value) => {
            return formatDateOnly(value);
          }}
        />
        <YAxis
          dataKey="TotalVolume"
          type="number"
          yAxisId="1"
          //use domain to set min and max totalVolume, if min totalVolume is a minus, add -5 to it
        />
        <Tooltip
          contentStyle={{
            backgroundColor: theme.palette.background.default,
          }}
          cursor={false}
          labelFormatter={(label) => {
            return formatDate(label);
          }}
          //format label to capitalize first letter of each word and format value to use celsius symbol and format date

          formatter={(value, name) => {
            return [value + " L", name];
          }}
        />
        <Area
          yAxisId="1"
          dataKey="TotalVolume"
          stroke={theme.palette.primary.main}
          animationDuration={300}
          fill="url(#TotalVolume)"
          strokeWidth={2}
          dominantBaseline={"middle"}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
