import fetchData from "../API/Fetch";
import { store } from "../store";

export default async function fetchOrganisationLogs(organisationID) {
  try {
    const response = await fetchData(`/organisation/${organisationID}/logs`);
    store.dispatch({
      type: "organisationLogs/update",
      payload: response ? response : [],
    });

    return response;
  } catch (error) {
    console.error("Error fetching organisation logs: ", error);
  }
}
