import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { postData } from "../../API/Post";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { fetchDeviceAlerts } from "../../Functions/fetchDeviceAlerts";

export default function DownlinkManager(props) {
  const { t } = useTranslation("common");
  const { open, handleClose } = props;

  const [command, setCommand] = useState("");
  const [port, setPort] = useState("1");

  const device = useSelector((state) => state.device);
  const dateRange = useSelector((state) => state.dateRange);

  let lora = true;

  if (device && device.device_brand && device.device_brand.non_lora) {
    lora = false;
  }

  async function sendCommand() {
    if (lora) {
      await postData(`/device/${device.id}/downlink`, {
        command: command,
        port: port,
      });
    } else {
      await postData(`/device/${device.id}/downlink/cat1m`, {
        device_id: device.id,
        command: command,
      });
    }

    fetchDeviceAlerts(device.id, dateRange.startDate, dateRange.endDate);

    updateSnackbarMessage(
      "Command: " + command + " successfully queued for device: " + device.name
    );
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>Send a Command</DialogTitle>

      <DialogContent>
        <Box sx={{ mb: 2 }} />
        <Stack spacing={1}>
          <TextField
            name="command"
            label={lora ? "Command (base64)" : "Command"}
            variant="outlined"
            fullWidth
            value={command}
            onChange={(e) => setCommand(e.target.value)}
          />

          {lora && (
            <TextField
              label="Port"
              variant="outlined"
              fullWidth
              value={port}
              onChange={(e) => setPort(e.target.value)}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={sendCommand}>
          {t("common.queuedownlink")}
        </Button>
        <Button onClick={handleClose}>{t("common.cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
