import React, { useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const CommandInterface = () => {
  // State variables to track user input
  const [deviceID, setDeviceID] = useState("");
  const [action, setAction] = useState("");
  const [actionValue, setActionValue] = useState("");
  const [builtCommand, setBuiltCommand] = useState("");

  // Function to handle command submission
  const handleSubmit = () => {
    // Generate the command string based on the selected action and device ID
    let command = "";
    if (action === "setUploadIntervalSleep") {
      command = `*HQ2014${deviceID}${actionValue}##`;
    } else if (action === "setUploadIntervalClock") {
      command = `*HQ2015${deviceID}${actionValue}##`;
    } else if (action === "selectTimezone") {
      command = `*HQ2016${deviceID}${actionValue}##`;
    } else if (action === "alarmOnOff") {
      command = `*HQ2017${deviceID}${actionValue}##`;
    } else if (action === "restartDevice") {
      command = `*HQ2010${deviceID}##`;
    }
    // Update built command state
    setBuiltCommand(command);
  };

  // Function to handle action value change
  const handleActionValueChange = (e) => {
    setActionValue(e.target.value);
  };

  return (
    <div style={{ backgroundColor: "black", padding: "20px" }}>
      <Typography variant="h4" color="white" gutterBottom>
        Command Interface
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Enter device ID"
            value={deviceID}
            onChange={(e) => setDeviceID(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Select Action</InputLabel>
            <Select value={action} onChange={(e) => setAction(e.target.value)}>
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="setUploadIntervalSleep">
                Set Upload Interval in Sleep Mode
              </MenuItem>
              <MenuItem value="setUploadIntervalClock">
                Set Upload Interval in Clock Mode
              </MenuItem>
              <MenuItem value="selectTimezone">Select Timezone</MenuItem>
              <MenuItem value="alarmOnOff">Alarm On/Off</MenuItem>
              <MenuItem value="restartDevice">Restart Device</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {action && action !== "restartDevice" && (
          <Grid item xs={12} md={6}>
            {action !== "alarmOnOff" ? (
              <TextField
                fullWidth
                label={
                  action === "selectTimezone"
                    ? "Timezone"
                    : "Interval (in minutes)"
                }
                value={actionValue}
                onChange={handleActionValueChange}
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel>Alarm Status</InputLabel>
                <Select value={actionValue} onChange={handleActionValueChange}>
                  <MenuItem value="on">On</MenuItem>
                  <MenuItem value="off">Off</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              !deviceID ||
              !action ||
              (action !== "restartDevice" && !actionValue)
            }
          >
            Submit Command
          </Button>
        </Grid>
        {builtCommand && (
          <Grid item xs={12}>
            <Typography variant="h6" color="white">
              Built Command:
            </Typography>
            <Typography variant="body1" color="white">
              {builtCommand}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CommandInterface;
