import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import fetchReportFiles from "../Functions/fetchReportfiles";
import DataTableBase from "../components/DataTableBase";
import { formatDate } from "../Functions/formatDate";
import hasPermission from "../Functions/hasPermission";
import { RunCircle } from "@mui/icons-material";

export default function Report(props) {
  const reports = useSelector((state) => state.reports);
  const isMobile = useSelector((state) => state.isMobile);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const user = useSelector((state) => state.user);
  const userOrganisationRoles = useSelector(
    (state) => state.userOrganisationRoles
  );
  const { id } = useParams();
  const theme = useTheme();
  const report = reports.find((report) => parseInt(report.id) === parseInt(id));

  const [files, setFiles] = useState([]);

  useEffect(() => {
    const getfiles = async () => {
      // Fetch report files
      let fetchFiles = await fetchReportFiles(id);
      setFiles(fetchFiles);
    };

    getfiles();
  }, [id]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent={isMobile ? "flex-start" : "flex-end"}
        sx={{ mt: 1 }}
      >
        <PageTitle
          title={report && report.name ? report.name : "Report"}
          theme={theme}
        />
        {
          // if user is not the owner of the organisation, don't show the add device button
          (selectedOrganisation &&
            user &&
            selectedOrganisation.owner_id === user.id) ||
          hasPermission(userOrganisationRoles, user.id, "add") ? (
            <Tooltip title="Run Now" placement="top" arrow>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
              >
                <RunCircle />
              </Button>
            </Tooltip>
          ) : null
        }
      </Stack>
      <Box sx={{ mt: 1 }}>
        <DataTableBase
          title="Report Files"
          columns={[
            {
              name: "url",
              options: {
                label: "Report",
                customBodyRender: (value) => {
                  let filename = value.split("/").pop();

                  return (
                    <Typography onClick={() => window.open(value, "_blank")}>
                      {filename}
                    </Typography>
                  );
                },
              },
            },
            {
              name: "time_created",
              options: {
                label: "Time Created",
                customBodyRender: (value) => {
                  return (
                    <Typography variant="body2">{formatDate(value)}</Typography>
                  );
                },
              },
            },
            {
              name: "url",
              options: {
                label: "Options",
                customBodyRender: (value) => {
                  return (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        window.open(value, "_blank");
                      }}
                    >
                      Download
                    </Button>
                  );
                },
              },
            },
          ]}
          data={files}
        />
      </Box>
    </>
  );
}
