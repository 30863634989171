import fetchData from "../API/Fetch";

export default async function fetchRemainingDeviceLimit(organisationId) {
  if (!organisationId) {
    return 0;
  }

  try {
    const response = await fetchData(
      `/organisation/${organisationId}/remaining-device-limit`
    );

    return response;
  } catch (error) {
    console.error(error);
    return 0;
  }
}
