import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import TemperatureGuague from "../Guages/Temperature";
import MotionCard from "../Cards/Motion";
import MotionGraph from "../Graphs/Motion";
import TemperatureGraph from "../Graphs/Temperature";
import OccupancyCount from "../Cards/OccupancyCount";
import FrameList from "../FrameList";

export default function MotionWithTemp(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 3 : 4}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 3 : 4} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>
        <Grid item xs={12} md={!isShare ? 3 : 4} sx={{ mb: 1 }}>
          <OccupancyCount />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={3} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <MotionCard />
        </Grid>

        <Grid
          item
          xs={12}
          md={9}
          sx={{
            mb: 1,
          }}
        >
          <MotionGraph />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <TemperatureGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <TemperatureGraph useIsXL={false} />
        </Grid>

        <Grid item xs={12} md={!isShare ? 6 : 12} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={6}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
