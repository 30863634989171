import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import deleteOrganisationMember from "../../Functions/deleteOrganisationMember";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { useSelector } from "react-redux";

export default function LeaveOrganisationDialog(props) {
  const { t } = useTranslation("common");

  const { open, onClose, organisation } = props;

  const user = useSelector((state) => state.user);

  async function removeOrganisationMember() {
    try {
      let response = await deleteOrganisationMember(organisation.id, user.id);

      if (response && response.status === 200) {
        updateSnackbarMessage(t("organisations.leave_success"));
        onClose();
      } else {
        updateSnackbarMessage(`${t("organisations.leave_error")} `);
      }
    } catch (error) {
      console.log(error);
      updateSnackbarMessage(t("organisations.leave_error"));
    }
  }

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>{t("organisations.leave_title")}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t("organisations.leave_confirmation", {
            organisation: organisation.name,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => onClose()}>
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            removeOrganisationMember();
          }}
        >
          {t("common.leave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
