const initialState = [];

function actionsReducer(state = initialState, action) {
  switch (action.type) {
    case "actions/init":
      return action.payload;
    case "actions/update":
      return action.payload;

    default:
      return state;
  }
}

export default actionsReducer;
