import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import { yellow } from "@mui/material/colors";
import { useSelector } from "react-redux";

export default function PeopleCounterGraph(props) {
  const [countHistory, setCountHistory] = React.useState([]);

  const theme = useTheme();

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  const [showBLE, setShowBLE] = React.useState(true);
  const [showWIFI, setShowWIFI] = React.useState(true);
  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let countHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;
        let count = jsonData.count;

        let historyItem = {};

        historyItem.Date = time_created;
        historyItem.count = count;

        if (jsonData.wifi === undefined) {
          setShowWIFI(false);
        } else {
          historyItem.wifi = jsonData.wifi;
        }
        if (jsonData.ble === undefined) {
          setShowBLE(false);
        } else {
          historyItem.ble = jsonData.ble;
        }

        countHistory.push(historyItem);
      });

      setCountHistory(countHistory);
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Count History" />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? 5 : 2}>
          <AreaChart
            data={countHistory}
            margin={{ top: 0, right: 30, left: -13, bottom: 0 }}
          >
            <defs>
              <linearGradient id="Count" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient id="Wifi" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={yellow[500]} stopOpacity={0.8} />
                <stop offset="95%" stopColor={yellow[500]} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="Ble" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  //blue
                  stopColor="#2196f3"
                  stopOpacity={0.8}
                />
                <stop offset="95%" stopColor="#2196f3" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis type={"number"} yAxisId="1" domain="[dataMin, dataMax]" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
            />
            <Area
              yAxisId="1"
              type="monotone"
              dataKey="count"
              //use theme color for line
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Count)"
              strokeWidth={2}
            />
            {showWIFI && (
              <Area
                yAxisId="1"
                type="monotone"
                dataKey="wifi"
                //use theme color for line
                stroke={theme.palette.primary.main}
                animationDuration={300}
                fill="url(#Wifi)"
                strokeWidth={2}
              />
            )}
            {showBLE && (
              <Area
                yAxisId="1"
                type="monotone"
                dataKey="ble"
                //use theme color for line
                stroke={theme.palette.primary.main}
                animationDuration={300}
                fill="url(#Ble)"
                strokeWidth={2}
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
