import React from "react";
import formatTemperature from "../../Functions/formatTemperature";
import DeviceTypeBase from "./DeviceTypeBase";

export default function DraginoLSN50v2S31(props) {
  let { isShare } = props;

  const columns = [
    /*
     {
      field: "speedKmph",
      title: "Speed",
      icon: "tachometer-alt",
      iconSize: "6x",
      formatter: (value) => formatSpeed(value, true),
      graphValueFormatter: (value) => formatSpeed(value, false),
      aspect: 10,
      metricWidth: 3,
      graphWidth: 12,
      unit: formatSpeed(0, true, true),
    },
    */
    {
      field: "temperature",
      title: "Temperature",
      icon: "thermometer-half",
      iconSize: "6x",
      formatter: (value) => formatTemperature(value),
      graphFormatter: (value) => formatTemperature(value, false),
      aspect: 10,
      metricWidth: 6,
      graphWidth: 6,
      unit: formatTemperature(0, true, true),
    },
    {
      field: "humidity",
      title: "Humidity",
      icon: "tint",
      iconSize: "6x",
      formatter: (value) => value + "%",
      aspect: 10,
      metricWidth: 6,
      graphWidth: 6,
      unit: "%",
    },
  ];

  return (
    <DeviceTypeBase
      isShare={isShare}
      columns={columns}
      showBattery={true}
      sideBySide
    />
  );
}
