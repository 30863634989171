import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";

export default function WaterFlowGraph(props) {
  const [instantaneousFlowHistory, setInstantaneousFlowHistory] =
    React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  const theme = useTheme();

  let useIsXL = true;

  if (props.useIsXL !== undefined) {
    useIsXL = props.useIsXL;
  }

  const [isXl, setIsXl] = React.useState(
    useIsXL === true && window.innerWidth > 2048
  );

  const { instantaneousFlowValue } = props;

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to instantaneousFlowHistory
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        //if instantaneousFlowValue is not null, set it to use instantaneousFlow
        if (instantaneousFlowValue) {
          //find the key with matching name to instantaneousFlowValue
          let key = Object.keys(jsonData).find(
            (key) => key.toLowerCase() === instantaneousFlowValue.toLowerCase()
          );
          //if key is found, set instantaneousFlow to value of key
          if (key) {
            jsonData.instantaneousFlow = jsonData[key];
          }
        } else {
          let instantaneousFlow = jsonData.instantaneousFlow;

          tempHistory.push({
            Date: time_created,
            InstantaneousFlow: instantaneousFlow,
          });
        }
      });

      setInstantaneousFlowHistory(tempHistory);

      // on reisize, check isXl and update aspect ratio
      const handleResize = () => {
        setIsXl(useIsXL === true && window.innerWidth > 2048);
      };

      window.addEventListener("resize", handleResize);
    } catch (error) {}
  }, [deviceHistory, instantaneousFlowValue, useIsXL]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={"Current Flow Rate"}
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "light" ? "#000" : "#616161",
        }}
        action={
          //get last instantaneousFlow value and display it
          instantaneousFlowHistory.length > 0 ? (
            <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
              {instantaneousFlowHistory[instantaneousFlowHistory.length - 1]
                .InstantaneousFlow + " L/min"}
            </Typography>
          ) : (
            <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
              {"N/A"}
            </Typography>
          )
        }
      />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? (isXl ? 3 : 2) : 2}>
          <AreaChart
            data={instantaneousFlowHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient
                id="InstantaneousFlow"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis
              dataKey="InstantaneousFlow"
              type="number"
              yAxisId="1"
              //use domain to set min and max instantaneousFlow, if min instantaneousFlow is a minus, add -5 to it
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              //format label to capitalize first letter of each word and format value to use celsius symbol and format date

              formatter={(value, name) => {
                return [value + " L/min", name];
              }}
            />
            <Area
              yAxisId="1"
              dataKey="InstantaneousFlow"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#InstantaneousFlow)"
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
