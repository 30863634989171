import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { formatDate } from "../../Functions/formatDate";
import { useTranslation } from "react-i18next";

export default function AlertLogDialog(props) {
  const { open, handleClose, lastAlert } = props;
  const { t } = useTranslation("common");

  if (!lastAlert) return null;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {formatDate(lastAlert.time_created || 0).toLocaleString()}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{lastAlert.message || "N/A"}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          {t("common.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
