import { Card, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { useSelector } from "react-redux";
import AvgAndMax from "../Cards/avgAndMax";

export default function PressureGuage(props) {
  const [pressure, setPressure] = React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);

  //500 - 1500 hPa
  let defaultArch = [
    {
      limit: 500,
      //red
      color: "#FF0000",
    },

    {
      //green
      limit: 1500,
      color: "#00FF00",
    },
  ];

  const { value } = props;

  useEffect(() => {
    if (!deviceHistory || !deviceHistory.length) {
      return;
    }
    //get last data object from deviceHistory
    let lastData = deviceHistory[deviceHistory.length - 1];
    //parse data
    let jsonData = lastData.data;
    jsonData = JSON.parse(jsonData);

    //set pressure to value of pressure
    if (value) {
      setPressure(jsonData[value]);
      return;
    } else {
      let pressure = jsonData.pressure;
      setPressure(pressure);
    }
  }, [deviceHistory, value]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={"Pressure"}
        action={<Typography variant="h5">{pressure} hPa</Typography>}
      />

      <GaugeComponent
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
          tickLabels: {
            type: "outer",
            valueConfig: {
              formatTextValue: (value) => value + " L",
              fontSize: 10,
            },
          },
        }}
        value={pressure}
        minValue={0}
        maxValue={1500}
        type="semicircle"
      />
      <AvgAndMax
        deviceHistory={deviceHistory}
        value={value ? value : "pressure"}
        unit="hPa"
      />
    </Card>
  );
}
