import React from "react";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function MotionCardNBIOT() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [motion, setMotion] = React.useState();
  const [distance, setDistance] = React.useState();

  const theme = useTheme();

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      setMotion(jsonData.state);
      setDistance(jsonData.distance);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <>
      <Card sx={{ mb: 1, height: "47.5%" }}>
        <CardContent>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="subtitle"
              sx={{
                // if light theme then color is black, else dark grey
                color: theme.palette.mode === "light" ? "#000" : "#616161",
              }}
            >
              Motion Detected?
            </Typography>
          </Box>
          <Tooltip
            title={
              motion === 1
                ? "Motion Detected: Yes"
                : motion === 0
                ? "Motion Detected: No"
                : "Motion Detected: Unknown"
            }
          >
            <Typography variant="p" sx={{ fontSize: "3rem" }}>
              {motion === 1 ? "Yes" : motion === undefined ? "Unknown" : "No"}
            </Typography>
          </Tooltip>
        </CardContent>
      </Card>
      <Card sx={{ mb: 1, height: "50%" }}>
        <CardContent>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="subtitle"
              sx={{
                // if light theme then color is black, else dark grey
                color: theme.palette.mode === "light" ? "#000" : "#616161",
              }}
            >
              Distance
            </Typography>
          </Box>
          <Tooltip title={"Distance: " + distance}>
            <Typography variant="p" sx={{ fontSize: "3rem" }}>
              {distance ? distance + "mm" : "Unknown"}
            </Typography>
          </Tooltip>
        </CardContent>
      </Card>
    </>
  );
}
