const initialState = { open: false, message: "", sevirty: "success" };

//snackbar open reducer
export default function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case "snackbar/update":
      return {
        ...state,
        open: true,
        message: action.payload.message,
        severity: action.payload.severity ? action.payload.severity : "success",
      };
    case "snackbar/close":
      return {
        ...state,
        open: false,
        message: "",
        severity: "success",
      };

    default:
      return state;
  }
}
