//dialogbase to add organisation
import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import addOrganisationMember from "../../Functions/addOrganisationMember";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function OrganisationAddMemberDialog(props) {
  const { open, onClose, organisationId } = props;
  const { t } = useTranslation("common");

  const { handleSubmit, control } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = async (data) => {
    const { email } = data;

    const organisationMember = {
      //trim and lowercase email
      email: email.trim().toLowerCase(),
    };

    await addOrganisationMember(organisationMember, organisationId);

    updateSnackbarMessage("Organisation Member added successfully");

    onClose();
  };

  const validationLabels = {
    email: {
      required: t("organisations.email_required"),
      pattern: t("organisations.email_invalid"),
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="organisation-add-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="organisation-add-dialog-title">
        {t("organisations.add_member")}
      </DialogTitle>
      <DialogContent>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              autoFocus
              margin="dense"
              id="email"
              label={t("organisations.email")}
              type="text"
              fullWidth
              {...field}
              error={error !== undefined}
              helperText={error ? validationLabels.email[error.type] : ""}
              variant="outlined"
              sx={{
                mt: 1,
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
