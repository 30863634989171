import fetchData from "../API/Fetch";
import { store } from "../store";

export const fetchDashboards = (orgID) => {
  if (!orgID) {
    store.dispatch({ type: "dashboards/update", payload: [] });
    return;
  }

  fetchData(`/dashboard/organisation/${orgID}`).then((response) => {
    if (response) {
      store.dispatch({
        type: "dashboards/update",
        payload: response,
      });
    } else {
      store.dispatch({ type: "dashboards/update", payload: [] });
    }
  });
};
