const getForwarderTitle = ({ tableMeta, t }) => {
  if (!tableMeta) return;
  if (!tableMeta.rowData) return;

  let forwarderURL = tableMeta.rowData[9];
  let title = "";
  title = `${t("actions.forwarder")} to ${forwarderURL}`;
  return title;
};

export default getForwarderTitle;
