import React, { useEffect, useState } from "react";
import changeDeviceName from "../../Functions/changeDeviceName";
import DialogBase from "./DialogBase";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { useSelector } from "react-redux";
import { fetchDevice } from "../../Functions/fetchDevice";

export default function DeviceNameChangeDialog(props) {
  const { t } = useTranslation("common");
  const {
    changeNameDialogOpen,
    setChangeNameDialogOpen,
    handledeviceOptionsClose,
  } = props;

  const [DeviceNameChange, setDeviceNameChange] = useState();

  const device = useSelector((state) => state.device);

  useEffect(() => {
    if (device && device.name) setDeviceNameChange(device.name);
  }, [device]);

  return (
    <DialogBase
      title={t("devices.change_name_title")}
      open={changeNameDialogOpen}
      onClose={() => setChangeNameDialogOpen(false)}
      validateFields={() =>
        changeDeviceName(device.id, DeviceNameChange).then(async () => {
          setChangeNameDialogOpen(false);
          fetchDevice(device.id);
          handledeviceOptionsClose();
          updateSnackbarMessage(
            t("devices.name_change_success", { name: DeviceNameChange })
          );
        })
      }
    >
      <TextField
        id="device-name"
        variant="outlined"
        value={DeviceNameChange}
        onChange={(e) => setDeviceNameChange(e.target.value)}
        fullWidth
      />
    </DialogBase>
  );
}
