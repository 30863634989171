import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function VoltageCard() {
  const [lastVoltage, setLastVoltage] = React.useState("N/A");

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (deviceHistory && deviceHistory.length) {
      //get last data object
      let lastDataObject = deviceHistory[deviceHistory.length - 1];
      //parse data
      let jsonData = lastDataObject.data;
      jsonData = JSON.parse(jsonData);
      //get last level
      let lastVoltage = jsonData.voltage;
      //set last level
      setLastVoltage(lastVoltage);
    }
  }, [deviceHistory]);

  return (
    <Card>
      <CardHeader title="Voltage" />
      <CardContent>
        <Typography
          variant="h4"
          //dont wrap text
          noWrap
          //hide overflow
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {lastVoltage ? lastVoltage + "V" : "No Reading"}
        </Typography>
      </CardContent>
    </Card>
  );
}
