import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function EventStatusCard() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [eventStatus, setEventStatus] = React.useState("No Event");

  useEffect(() => {
    if (!deviceHistory || !deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);

      if (jsonData.event_status) {
        setEventStatus(
          jsonData.event_status ? jsonData.event_status : "No Event"
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <>
      <Card sx={{ mb: 1, height: "100%" }}>
        <CardHeader
          title={"Event Status"}
          titleTypographyProps={{
            fontSize: "1.2rem",
          }}
          sx={{ pb: 0, mb: 0 }}
        />
        <CardContent
          style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            paddingTop: "10px",
          }}
        >
          <Typography variant="h4">{eventStatus}</Typography>
        </CardContent>
      </Card>
    </>
  );
}
