import React from "react";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { formatDate } from "../../Functions/formatDate";

export default function LastOpenedDurationCard(props) {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [lastDuration, setLastDuration] = React.useState(0);

  const theme = useTheme();

  const { val = "time", title = "Last Open Duration" } = props;

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    let last_data = deviceHistory[deviceHistory.length - 1].data;
    let jsonData = JSON.parse(last_data);
    let last_opened = jsonData[val];

    setLastDuration(last_opened);
  }, [deviceHistory, val]);

  return (
    <Card sx={{ mt: 1 }}>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Tooltip title={`Last Open Duratioj: ${lastDuration}`}>
          <Typography variant="h5">
            {lastDuration ? formatDate(lastDuration) : "N/A"}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
