import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const PageTitle = ({ title, theme, subTitle = 0, beta = false }) => {
  const isMobile = useSelector((state) => state.isMobile);
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Typography
        variant="h4"
        component="p"
        color={theme.palette.mode === "dark" ? "primary" : "black"}
        sx={{ fontSize: isMobile ? "1.2rem" : "1.5rem", fontWeight: 600 }}
      >
        {title}
        {beta ? <sup sx={{ mr: 1 }}> Beta</sup> : null}
      </Typography>
      {
        //if beta, display beta tag
      }

      {subTitle ? (
        <Typography
          variant="caption"
          component="p"
          color={theme.palette.mode === "dark" ? "primary" : "black"}
          sx={{ fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 400 }}
        >
          {subTitle}
        </Typography>
      ) : null}
    </Box>
  );
};

export default PageTitle;
