import fetchData from "../API/Fetch";
import { store } from "../store";

export const fetchDevices = async (org_id) => {
  const response = await fetchData(`/organisation/${org_id}/devices`);

  //dispatch to users/update reducer
  store.dispatch({ type: "devices/update", payload: response ? response : [] });

  return response;
};
