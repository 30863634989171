import React from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import HumidityGuague from "../Guages/Humidity";
import TemperatureGraph from "../Graphs/Temperature";
import HumidityGraph from "../Graphs/Humidity";
import LastAlert from "../Cards/LastAlert";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";

import FrameList from "../FrameList";
import PMGraph from "../Graphs/PMGraph";
import PMGuague from "../Guages/PMGuague";
import PMNumberGraph from "../Graphs/PMNumberGraph";
import C02Guague from "../Guages/C02PPM";
import PressureGuage from "../Guages/Pressure";
import C02Graph from "../Graphs/C02";
import PressureGraph from "../Graphs/Pressure";
import Rssi from "../Cards/Rssi";
import DeviceMetric from "../Cards/DeviceMetric";

export default function GoIoTwiskaairhumiditypmqos(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} lg={!isShare ? 4 : 12} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>

        <Grid item xs={12} lg={!isShare ? 4 : 12} sx={{ mb: 1 }}>
          <Rssi />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} lg={4} sx={{ mb: 1 }}>
              <LastAlert />
            </Grid>
          </>
        )}

        {
          //lux and sound
        }
        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <DeviceMetric
            title="Lux"
            value="light_lux"
            icon="sun"
            iconSize="6x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="Lux"
            textsize="h3"
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <DeviceMetric
            title="Sound"
            value="sound"
            icon="volume-up"
            iconSize="6x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="dB"
            textsize="h3"
          />
        </Grid>

        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <TemperatureGuague temperatureValue="external_temperature" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <HumidityGuague humidityValue="humidity" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <C02Guague value="CO2" />
        </Grid>

        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <PressureGuage value="air_pressure" />
        </Grid>

        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <PMGuague value="PM1_0" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <PMGuague value="PM2_5" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <PMGuague value="PM4_0" />
        </Grid>

        <Grid item xs={12} lg={3} sx={{ mb: 1 }}>
          <PMGuague value="PM10" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <TemperatureGraph
            useIsXL={false}
            temperatureValue="external_temperature"
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <HumidityGraph humidityValue="humidity" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <C02Graph value="CO2" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <PressureGraph value="air_pressure" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <PMGraph value="PM1_0" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <PMNumberGraph value="PM1_0_number" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <PMGraph value="PM2_5" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <PMNumberGraph value="PM2_5_number" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <PMGraph value="PM4_0" mass />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <PMNumberGraph value="PM4_0_number" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <PMGraph value="PM10" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 1 }}>
          <PMNumberGraph value="PM10_number" />
        </Grid>

        <Grid item xs={12} lg={!isShare ? 7 : 12}>
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} lg={5}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
