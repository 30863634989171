import { putData } from "../API/Put";

export default async function setOrganisationMemberRoles(
  organisationMemberRole,
  organisationId
) {
  if (!organisationMemberRole || !organisationId) {
    return;
  }
  //{organisation_id}/roles/{user_id}
  let result = await putData(
    `/organisation/${organisationId}/roles/${organisationMemberRole.user_id}`,
    organisationMemberRole
  );

  return result;
}
