import { postData } from "../API/Post";

export default async function addDashboard(
  name,
  description,
  devices,
  organisation_id
) {
  try {
    if (name === "") {
      return false;
    }
    if (devices.length === 0) {
      return false;
    }

    let device_ids = devices.map((device) => device.id);

    let data = {
      name: name,
      description: description,
      devices: device_ids,
      organisation_id: organisation_id,
    };
    await postData("/dashboard/", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
