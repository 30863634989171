import { Box } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import { updateDeviceXY } from "../../Functions/updateDeviceXy";
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import DevicePin from "./devicePin";

export default function ImageWithPins() {
  const devices = useSelector((state) => state.devices);

  const isMobile = useSelector((state) => state.isMobile);

  const theme = useTheme();

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const [height, setHeight] = React.useState("600px");

  const updateXY = async (id, x, y) => {
    //save to database
    await updateDeviceXY(id, x, y, selectedOrganisation);
  };

  const handleDrop = (device, monitor) => {
    const delta = monitor.getSourceClientOffset();
    const container = document
      .getElementById("imageContainer")
      .getBoundingClientRect();

    const x = Math.round(delta.x - container.left);
    const y = Math.round(delta.y - container.top);

    updateXY(device.id, x, y);
  };

  const [, drop] = useDrop(() => ({
    accept: "sensor",
    drop: (item, monitor) => handleDrop(item, monitor),
  }));

  useEffect(() => {
    if (!selectedOrganisation || !selectedOrganisation.image) return;

    const getMeta = (url, cb) => {
      const img = new Image();
      img.onload = () => cb(null, img);
      img.onerror = (err) => cb(err);
      img.src = url;
    };

    getMeta(selectedOrganisation.image, (err, img) => {
      if (err) {
        console.log(err);
      } else {
        setHeight(img.height + "px");
      }
    });
  }, [selectedOrganisation]);

  return (
    <Box
      ref={drop}
      id="imageContainer"
      sx={{
        position: "relative",
        width: "100%",
        height: height,
        backgroundImage: "url('" + selectedOrganisation.image + "')",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "600px",
      }}
    >
      {devices.map((device) => (
        <DraggableDevice
          key={device.id}
          device={device}
          isMobile={isMobile}
          theme={theme}
        />
      ))}
    </Box>
  );
}

function DraggableDevice({ device, isMobile, theme }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "sensor",
    item: device,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        position: "absolute",
        top: device.y ? device.y + "px" : 50 + "%",
        left: device.x ? device.x + "px" : 50 + "%",
        cursor: "pointer",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <DevicePin device={device} isMobile={isMobile} theme={theme} />
    </div>
  );
}
