import { useTheme } from "@emotion/react";
import { FirstPage, PlayArrow, Stop } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { formatDate } from "../../Functions/formatDate";
import cleanAddress from "../../Functions/cleanAddress";
import { useSelector } from "react-redux";
import { updateCurrent } from "../../Functions/updateCurrent";
import { addCurrent } from "../../Functions/addCurrent";

export default function TimelineCard(props) {
  const theme = useTheme();
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const current = useSelector((state) => state.current);
  const interval = React.useRef(null);

  const isMobile = useSelector((state) => state.isMobile);

  //start playback
  const startPlayback = () => {
    //create interval with  current state and deviceHistoryFiltered length
    interval.current = setInterval(() => {
      //update current in redux by +1
      addCurrent();
    }, 1000);
  };

  //stop playback
  const stopPlayback = () => {
    clearInterval(interval.current);
  };

  //restart playback
  const restartPlayback = () => {
    updateCurrent(0);
  };

  React.useEffect(() => {
    //if current = deviceHistoryFiltered length, clear interval
    if (current === deviceHistory.length) {
      clearInterval(interval.current);
    }
  }, [current, deviceHistory]);

  //filter out any device_history where last_data.latitude and last_data.longitude are 0
  let deviceHistoryFiltered = deviceHistory.filter((item) => {
    let data = JSON.parse(item.data);
    if (data.latitude === 0 || data.longitude === 0) {
      return false;
    }
    if (data.latitude === null || data.longitude === null) {
      return false;
    }
    if (data.latitude === undefined || data.longitude === undefined) {
      return false;
    }
    if (data.latitude === "0" || data.longitude === "0") {
      return false;
    }
    //0.0
    if (data.latitude === "0.0" || data.longitude === "0.0") {
      return false;
    }

    if (data.latitude === 0.0 || data.longitude === 0.0) {
      return false;
    }
    return true;
  });

  return (
    <Card className="history-playback-control">
      <CardHeader
        title="Timeline Player"
        titleTypographyProps={{
          fontSize: "1.2rem",
        }}
        action={
          !isMobile && (
            //check to see if deviceHistoryFiltered has speed (not empty)
            <Typography variant="body1" sx={{ pt: 1 }}>
              {deviceHistoryFiltered[current]
                ? `${formatDate(
                    deviceHistoryFiltered[current].time_created
                  )} - ${cleanAddress(deviceHistoryFiltered[current])}`
                : "No data"}
            </Typography>
          )
        }
        subheader={
          isMobile && (
            //check to see if deviceHistoryFiltered has speed (not empty)
            <Typography variant="body1" sx={{ pt: 1 }}>
              {deviceHistoryFiltered[current]
                ? `${formatDate(
                    deviceHistoryFiltered[current].time_created
                  )} - ${cleanAddress(deviceHistoryFiltered[current])}`
                : "No data"}
            </Typography>
          )
        }
        sx={{ pb: 0, mb: 0 }}
      />
      <CardContent
        style={{
          marginBottom: "0px",
          paddingBottom: "0px",
          paddingTop: "5px",
        }}
      >
        <Slider
          aria-label="Default"
          min={1}
          max={deviceHistoryFiltered ? deviceHistoryFiltered.length - 1 : 0}
          step={1}
          onChange={(e, value) => {
            updateCurrent(value);
          }}
          value={current}
          className="slider"
          marks={
            deviceHistoryFiltered &&
            deviceHistoryFiltered.map((item, index) => {
              return {
                value: index,
              };
            })
          }
          sx={{
            // set color based on theme mode
            color: theme.palette.success.main,
          }}
        />
        <Stack
          direction="row"
          spacing={5}
          justifyContent="center"
          alignItems="stretch"
          sx={{ mb: 1 }}
        >
          <PlayArrow
            onClick={() => {
              startPlayback();
            }}
            sx={{
              // set color based on theme mode
              color: theme.palette.success.main,
              //onhover set color to secondary, set pointer
              "&:hover": {
                color: theme.palette.secondary.main,
                cursor: "pointer",
              },
            }}
          />

          <Stop
            onClick={() => {
              stopPlayback();
            }}
            sx={{
              // set color based on theme mode
              color: theme.palette.success.main,
              "&:hover": {
                color: theme.palette.secondary.main,
                cursor: "pointer",
              },
            }}
          />

          <FirstPage
            onClick={() => {
              restartPlayback();
            }}
            sx={{
              // set color based on theme mode
              color: theme.palette.success.main,
              "&:hover": {
                color: theme.palette.secondary.main,
                cursor: "pointer",
              },
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
