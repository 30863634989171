import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function AQIGuague(props) {
  const [pmValue, setPmValue] = React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  let defaultArch = [
    {
      //good, green
      limit: 50,
      color: "#1f883d",
    },
    {
      //satisfactory, light green
      limit: 100,
      color: "#14e34f",
    },
    {
      //moderate, yellow
      limit: 150,
      color: "#FFFF00",
    },
    {
      //poor, orange
      limit: 200,
      color: "#FFA500",
    },
    {
      //very poor red
      limit: 300,
      //dark red
      color: "#EE4B2B",
    },
    {
      //very poor red
      limit: 500,
      //dark red
      color: "#800020",
    },
  ];

  const { value } = props;

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      setPmValue(jsonData[value][0]);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, deviceProfile, value]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={value}
        action={<Typography variant="h5">{pmValue}</Typography>}
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
          tickLabels: {
            type: "outer",
          },
        }}
        value={pmValue}
        minValue={0}
        maxValue={500}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax type={value} unit={""} />
      </Box>
    </Card>
  );
}
