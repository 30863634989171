const initialState = [];

function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case "device/init":
      return action.payload;
    case "device/update":
      return action.payload;

    default:
      return state;
  }
}

export default deviceReducer;
