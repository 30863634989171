import React from "react";
import DeviceTypeBase from "./DeviceTypeBase";

export default function MokoSmartLW005(props) {
  let { isShare } = props;

  const columns = [
    /*
        {
      field: "speedKmph",
      title: "Speed",
      icon: "tachometer-alt",
      iconSize: "6x",
      formatter: (value) => formatSpeed(value, true),
      graphValueFormatter: (value) => formatSpeed(value, false),
      aspect: 10,
      metricWidth: 3,
      graphWidth: 12,
      unit: formatSpeed(0, true, true),
    },
    */
    {
      field: "plug_state",
      title: "Plug State",
      icon: "plug",
      iconSize: "6x",
      truefalse: true,
      trueV: "On",
      falseV: "Off",
      formatter: (value) => (value ? "On" : "Off"),
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
    {
      field: "total_energy",
      title: "Total Energy",
      icon: "battery-full",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      valueToTrim: "KWH",
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
    {
      field: "energy_of_last_hour",
      title: "Total Energy Last Hour",
      icon: "battery-full",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      valueToTrim: "KWH",
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
    {
      field: "instantaneous_active_power",
      title: "Instantaneous Active Power",
      icon: "bolt",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      valueToTrim: "W",
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
    {
      field: "instantaneous_current",
      title: "Instantaneous Current",
      icon: "bolt",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      valueToTrim: "A",
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
    {
      field: "instantaneous_power_factor",
      title: "Instantaneous Power Factor",
      icon: "percentage",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      valueToTrim: "%",
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
    {
      field: "instantaneous_voltage",
      title: "Instantaneous Voltage",
      icon: "bolt",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      valueToTrim: "V",
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
    {
      field: "total_energy",
      title: "Total Energy History",
      trimValue: true,
      formatter: (value) => value,
      trimNumber: 3,
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
  ];

  return (
    <DeviceTypeBase isShare={isShare} columns={columns} showBattery={false} />
  );
}
