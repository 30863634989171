import { postData } from "../API/Post";

export default async function addReport(
  name,
  schedule_id,
  schedule_time,
  devices,
  contacts,
  organisation_id
) {
  try {
    if (name === "") {
      return false;
    }
    if (devices.length === 0) {
      return false;
    }

    let data = {
      name: name,
      devices: devices,
      contacts: contacts,
      organisation_id: organisation_id,
      schedule_id: schedule_id,
      schedule_time: schedule_time,
    };

    await postData("/report/", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
