import React from "react";
import AnalogInputGraph from "../Graphs/AnalogInput";
import { Grid } from "@mui/material";
import CardBase from "./CardBase";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

export default function AnalogInput(props) {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [analogInput, setAnalogInput] = React.useState([]);

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let tempData = [];
      deviceHistory.forEach((history) => {
        let data = history.data;

        //data is an array of channels, and the values of each is an array
        //if type == 1, add it to the array

        if (typeof data === "string") {
          data = JSON.parse(data);
        }

        //check if empty
        if (!data || data.length === 0) {
          return;
        }

        //see if channel exists in tempData, if not add it and add the value, if it does, add the value to the array
        data.forEach((channel) => {
          //check if channel exists
          let channelExists = tempData.find(
            (tempChannel) => tempChannel.channel === channel.channel
          );

          if (channelExists) {
            //add value to array
            channelExists.values.push(channel.value);
          } else {
            //add channel to array
            tempData.push({
              channel: channel.channel,
              values: [channel.value],
            });
          }
        });
      });

      setAnalogInput(tempData);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  //for each channel, return the analog input cards

  return (
    <>
      <Grid container spacing={1}>
        {analogInput &&
          analogInput.map((channel) => {
            return (
              <>
                <Grid item xs={12} md={6} lg={3}>
                  <Box sx={{ mb: 1, height: "100%" }}>
                    <CardBase values={channel} title="Analog Input" />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={9}>
                  <Box sx={{ mb: 1, height: "100%" }}>
                    <AnalogInputGraph analogInputHistory={channel} />
                  </Box>
                </Grid>
              </>
            );
          })}
      </Grid>
    </>
  );
}
