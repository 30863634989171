//dialogbase to add organisation
import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import addOrganisation from "../../Functions/addOrganisation";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function DialogOrganisationAdd(props) {
  const { open, onClose } = props;
  const { t } = useTranslation("common");

  const { handleSubmit, control } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = async (data) => {
    const { name, description } = data;

    const organisation = {
      name: name,
      description: description,
    };

    let response = await addOrganisation(organisation);

    if (response === undefined) {
      updateSnackbarMessage("Error adding organisation");
      onClose();
      return;
    }

    if (response.status !== 200) {
      updateSnackbarMessage("Error adding organisation");
      onClose();
      return;
    }

    if (response.status === 200) {
      updateSnackbarMessage("Organisation added successfully");
      onClose();
      return;
    }
  };

  const validationLabels = {
    name: {
      required: t("organisations.name_required"),
    },
    description: {
      required: t("organisations.description_required"),
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="organisation-add-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="organisation-add-dialog-title">
        {t("organisations.add")}
      </DialogTitle>
      <DialogContent>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              autoFocus
              margin="dense"
              id="name"
              label={t("organisations.org_name")}
              type="text"
              fullWidth
              {...field}
              error={error !== undefined}
              helperText={error ? validationLabels.name[error.type] : ""}
              variant="outlined"
              sx={{
                mt: 1,
              }}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{
            required: true,
          }}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              autoFocus
              margin="dense"
              id="description"
              label={t("common.description")}
              multiline
              rows={4}
              fullWidth
              {...field}
              error={error !== undefined}
              helperText={error ? validationLabels.description[error.type] : ""}
              variant="outlined"
              sx={{
                mt: 1,
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
