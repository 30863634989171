import { formatDate } from "./formatDate";
import formatTemperature from "./formatTemperature";

const getAlertTitle = (tableMeta, t) => {
  if (!tableMeta) return;

  if (!tableMeta.rowData) return;

  let alertType = tableMeta.rowData[4];
  let aboveBelow = tableMeta.rowData[5];
  let threshold = tableMeta.rowData[6];
  let out_of_hours = tableMeta.rowData[7];
  let start_time = tableMeta.rowData[8];
  let end_time = tableMeta.rowData[9];
  let true_false = tableMeta.rowData[15];
  let temp_value = tableMeta.rowData[18];

  let title = "";
  if (alertType === "temperature") {
    title = `${t("actions.temperature")} ${aboveBelow} ${formatTemperature(
      threshold
    )}`;

    if (temp_value) {
      title = `${t("actions.temperature")} ${aboveBelow} ${formatTemperature(
        threshold
      )} (${temp_value})`;
    }
  } else if (alertType === "humidity") {
    title = `${t("actions.humidity")} ${aboveBelow} ${threshold}%`;
  } else if (alertType === "battery") {
    title = `${t("actions.battery")} ${aboveBelow} ${threshold}%`;
  } else if (alertType === "movement") {
    title = `${t("actions.movement")}`;
  } else if (alertType === "door") {
    title = `${t("actions.door")}`;
  } else if (alertType === "button_pressed") {
    title = `${t("common.button_pressed")}`;
  } else if (alertType === "sos_event") {
    title = `${t("common.sos_event")}`;
  } else if (alertType === "water-leak") {
    title = `${t("actions.water_leak")}`;
  } else if (alertType === "light") {
    title = `${t("common.light")} ${aboveBelow} ${threshold}%`;
  } else if (alertType === "HasFlowRate") {
    title = `${t("common.has_flow_rate")}? ${true_false}`;
  } else if (alertType === "pm1_5") {
    title = `${t("common.pm1_5")} ${aboveBelow} ${threshold}`;
  } else if (alertType === "pm2_5") {
    title = `${t("common.pm2_5")} ${aboveBelow} ${threshold}`;
  } else if (alertType === "pm10") {
    title = `${t("common.pm10")} ${aboveBelow} ${threshold}`;
  } else if (alertType === "aqi") {
    title = `${t("common.aqi")} ${aboveBelow} ${threshold}`;
  } else if (alertType === "tvoc") {
    title = `${t("common.tvoc")} ${aboveBelow} ${threshold}`;
  } else if (alertType === "analog") {
    title = `${t("common.analog")} ${aboveBelow} ${threshold}`;
  } else if (alertType === "eventmode_shock") {
    title = `Shock Event Detetected`;
  } else if (alertType === "eventmode_motionless") {
    title = `Motionless Event Detected`;
  } else if (alertType === "eventmode_start_moving") {
    title = `Start Moving Event Detected`;
  } else if (alertType === "eventmode_end_movement") {
    title = `End Movement Event Detected`;
  } else if (alertType === "soil_moisture") {
    title = `${t("common.soil_moisture")} ${aboveBelow} ${threshold}%`;
  } else if (alertType === "soil_temperature") {
    title = `${t("common.soil_temperature")} ${aboveBelow} ${formatTemperature(
      threshold
    )}`;
  } else if (alertType === "soil_conductivity") {
    title = `${t("common.soil_conductivity")} ${aboveBelow} ${threshold}`;
  } else if (alertType === "soil_ph") {
    title = `${t("common.soil_ph")} ${aboveBelow} ${threshold}`;
  } else if (alertType === "leaf_temperature") {
    title = `${t("common.leaf_temperature")} ${aboveBelow} ${formatTemperature(
      threshold
    )}`;
  } else if (alertType === "leaf_moisture") {
    title = `${t("common.leaf_moisture")} ${aboveBelow} ${threshold}%`;
  }

  if (
    alertType === "geofence-enter" ||
    alertType === "geofence-exit" ||
    alertType === "geofence-enter-exit" ||
    alertType === "motion"
  ) {
    if (out_of_hours === "true") {
      title += `${t("actions.out_of_hours")} between ${formatDate(
        start_time
      )} and ${formatDate(end_time)}`;
    }
    if (alertType === "geofence-enter") {
      title += ` ${t("actions.geofence_enter")}`;
    } else if (alertType === "geofence-exit") {
      title += ` ${t("actions.geofence_exit")}`;
    } else if (alertType === "geofence-enter-exit") {
      title += ` ${t("actions.geofence_enter_exit")}`;
    } else if (alertType === "motion") {
      title += ` ${t("actions.motion")}`;
    }
  }

  return title;
};

export default getAlertTitle;
