import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";

export default function DeviceDetail(props) {
  const { title, content } = props;
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Tooltip title={`${title}: ${content}`} placement="top" arrow>
          <Typography variant="h5">{content}</Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
