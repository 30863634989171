import React from "react";
import { Grid } from "@mui/material";
import DeviceActionLogs from "../Cards/Alerts";
import LastAlert from "../Cards/LastAlert";
import LastSeen from "../Cards/LastSeen";
import FrameList from "../FrameList";
import HasFlow from "../Cards/HasFlow";
import SignalQuality from "../Cards/SignalQuality";
import WaterFlowGraph from "../Graphs/WaterFlow";
import PositiveCumulativeGraph from "../Graphs/PositiveCumulative";
import InstantaneousVelocityGraph from "../Graphs/InstantaneousVelocity";

export default function WaterMeterSensecore(props) {
  let { isShare } = props;

  /*
    HasFlowRate:true
    instantaneousFlow:0.39
    positiveCumulative:28.35
    instantaneousVelocity:0.48
    signalQuality:100
    */

  /*
    Cards:

    Total Water Tracked
    Usage This Month
    Usage This Week
    Usage Today
    Current Flow Rate
    Water Flowing?
    Today's FlowRate L/min
    Last 3 Hours Flowrate L/min
    Last 6 Hours FlowRate L/min
    Water Usage Each Day
    60 Day Water Usage Trend (m3)
    */

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <SignalQuality />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          {
            //Water Flowing?
          }
          <HasFlow />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          {
            //Current Flow Rate
          }
          <WaterFlowGraph />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          {
            //instantaneousVelocity
          }
          <InstantaneousVelocityGraph />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          {
            //Total Water Tracked
          }
          <PositiveCumulativeGraph />
        </Grid>
        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
