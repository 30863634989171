import { Card } from "@material-ui/core";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Home } from "@mui/icons-material";
import setTitle from "../Functions/setTitle";

export default function PaymentSuccess() {
  useEffect(() => {
    setTitle("Billing Success");
  }, []);

  return (
    // align card to center space around
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "100%",
          maxWidth: 500,
          margin: 1,
        }}
      >
        <CardHeader title="Billing Success" />
        <CardContent>
          <Typography variant="body1">
            Thank you for registering a card with Trackpac. Your card has been
            successfully registered. You can now use the Trackpac service.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              window.location.href = "/";
            }}
            startIcon={<Home />}
          >
            Return to dashboard
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
