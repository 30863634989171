import React from "react";
import { Grid } from "@mui/material";
import DeviceActionLogs from "../Cards/Alerts";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import WaterLeakGraph from "../Graphs/WaterLeak";
import FrameList from "../FrameList";
import Rssi from "../Cards/Rssi";

export default function WaterLeakNBIOT(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <BatteryStatus field={"bat"} />
        </Grid>

        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <Rssi />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 1 }}>
              <LastAlert />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <WaterLeakGraph field="leak" />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
