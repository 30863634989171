import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import deleteData from "../../API/Delete";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { useSelector } from "react-redux";
import fetchContacts from "../../Functions/fetchContacts";

const ContactDeleteDialog = (props) => {
  const { t } = useTranslation("common");

  const { selectedContacts } = props;

  const contacts = useSelector((state) => state.contacts);

  async function deleteContact() {
    try {
      selectedContacts.forEach(async (contact) => {
        await deleteData(`/contact/${contact}`);
      });

      updateSnackbarMessage(t("contacts.delete_success"));

      //set timer to wait 1 second before fetching contacts
      setTimeout(() => {
        fetchContacts(localStorage.getItem("selectedOrganisationID"));
      }, 1000);
    } catch (error) {
      console.log(error);
      updateSnackbarMessage(t("contacts.delete_error"));
    } finally {
      props.onClose();
    }
  }

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>{t("contacts.delete_title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("contacts.delete_confirmation")}
        </DialogContentText>
        <List>
          {contacts &&
            contacts.length > 0 &&
            contacts.map((contact) => {
              if (selectedContacts.includes(contact.id)) {
                return (
                  <ListItem key={contact.id + "-selected-fowarder-item"}>
                    <ListItemText
                      key={contact.id + "-selected-contact-item-text"}
                      primary={contact.name}
                    />
                  </ListItem>
                );
              } else {
                return null;
              }
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => props.onClose()}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteContact();
          }}
        >
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDeleteDialog;
