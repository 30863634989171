import { Typography } from "@mui/material";

export default function ImagePinMetric(props) {
  const { content, title, formatter = null } = props;

  let formattedContent = content;

  if (formatter) {
    formattedContent = formatter(content);
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{ fontWeight: "bold", fontSize: "0.75rem" }}
      >
        {title}{" "}
        <span style={{ fontWeight: "normal" }}>: {formattedContent}</span>
      </Typography>
    </>
  );
}
