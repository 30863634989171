import React from "react";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { formatDate } from "../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function LastOpenedCard() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [lastDate, setLastDate] = React.useState(null);

  const theme = useTheme();

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    //last item of device history is the most recent
    //find the last time the door was open
    for (let i = deviceHistory.length - 1; i >= 0; i--) {
      let data = deviceHistory[i];
      let jsonData = data.data;
      jsonData = JSON.parse(jsonData);
      if (jsonData.door_open === 1) {
        let time_updated = data.time_created;
        setLastDate(formatDate(time_updated));
        break;
      }
    }
  }, [deviceHistory]);

  return (
    <Card sx={{ mt: 1 }}>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            Last Time Opened
          </Typography>
        </Box>
        <Tooltip title={`Last Opened: ${lastDate}`}>
          <Typography variant="h5">{lastDate || "N/A"}</Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
