import getCleanedPolyline from "./getCleanedPolyline";

//add polyline for history
export default async function addPolyline(
  mapRef,
  deviceHistory,
  deviceProfile = null
) {
  try {
    //if device history.value is empty, return
    if (!deviceHistory || !deviceHistory || deviceHistory.length < 1) return;

    if (deviceProfile && deviceProfile.length) {
      //deviceProfile {"tracker":{"route_matching_type":"none","animated_lines":false}}
      //json parse deviceProfile
      if (typeof deviceProfile === "string") {
        deviceProfile = JSON.parse(deviceProfile);
      }
    }

    //create coords array for history.data items

    let coords = deviceHistory.map((item) => {
      let data = item.data;
      //json parse data
      if (typeof data === "string") {
        data = JSON.parse(data);
      }
      //return coordinates if not empty
      if (data.latitude && data.longitude) {
        if (data.latitude !== 0 && data.longitude !== 0) {
          return [data.longitude, data.latitude];
        } else {
          return null;
        }
      }
      return null;
    });

    //filter out null values
    coords = coords.filter((item) => item !== null);

    let polyLineGeometry = coords;

    //if route_matching_type is not none, get cleaned polyline
    if (
      deviceProfile &&
      deviceProfile.tracker &&
      deviceProfile.tracker.route_matching_type !== "none"
    ) {
      polyLineGeometry = await getCleanedPolyline(
        coords,
        deviceProfile.tracker.route_matching_type
      );
    }

    if (!mapRef.getSource("history")) {
      // add a source for the device's location history
      mapRef.addSource("history", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: polyLineGeometry,
              },
            },
          ],
        },
      });

      // if deviceProfile.tracker.animated_lines is true, add animated lines
      if (
        deviceProfile &&
        deviceProfile.tracker &&
        deviceProfile.tracker.animated_lines
      ) {
        // add a line layer without line-dasharray defined to fill the gaps in the dashed line
        mapRef.addLayer({
          type: "line",
          source: "history",
          id: "line-background",
          paint: {
            "line-color": "#004d40",
            "line-width": 3,
            "line-opacity": 1,
          },
        });
        // add a line layer with line-dasharray set to the first value in dashArraySequence
        mapRef.addLayer({
          type: "line",
          source: "history",
          id: "line-dashed",
          paint: {
            "line-color": "#00D09F",
            "line-width": 3,
            "line-dasharray": [0, 4, 3],
          },
        });

        try {
          const dashArraySequence = [
            [0, 4, 3],
            [0.5, 4, 2.5],
            [1, 4, 2],
            [1.5, 4, 1.5],
            [2, 4, 1],
            [2.5, 4, 0.5],
            [3, 4, 0],
            [0, 0.5, 3, 3.5],
            [0, 1, 3, 3],
            [0, 1.5, 3, 2.5],
            [0, 2, 3, 2],
            [0, 2.5, 3, 1.5],
            [0, 3, 3, 1],
            [0, 3.5, 3, 0.5],
          ];

          let step = 0;

          function animateDashArray(timestamp) {
            try {
              // Update line-dasharray using the next value in dashArraySequence. The
              // divisor in the expression `timestamp / 50` controls the animation speed.
              const newStep = parseInt(
                (timestamp / 50) % dashArraySequence.length
              );

              if (newStep !== step) {
                mapRef.setPaintProperty(
                  "line-dashed",
                  "line-dasharray",
                  dashArraySequence[step]
                );
                step = newStep;
              }

              // Request the next frame of the animation.
              requestAnimationFrame(animateDashArray);
            } catch (err) {
              console.log(err);
            }
          }
          // Start the animation.
          animateDashArray(0);
        } catch (err) {
          console.log(err);
        }
      } else {
        mapRef.addLayer({
          type: "line",
          source: "history",
          id: "line-background",
          paint: {
            "line-color": "#00D09F",
            "line-width": 3,
            "line-opacity": 1,
          },
        });
      }
    } else {
      //if source already exists, update the source data
      mapRef.getSource("history").setData({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: polyLineGeometry,
            },
          },
        ],
      });
    }
  } catch (err) {
    console.log(err);
  }
}
