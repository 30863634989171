const initialState = [];

function userOrganisationRolesReducer(state = initialState, action) {
  switch (action.type) {
    case "userOrganisationRoles/add":
      return [...state.value, action.payload];
    case "userOrganisationRoles/remove":
      return state.value.filter((device) => device.id !== action.payload);
    case "userOrganisationRoles/update":
      return action.payload;
    default:
      return state;
  }
}

export default userOrganisationRolesReducer;
