import { postData } from "../API/Post";

export default async function addOrganisationMember(member, organisationId) {
  try {
    const response = await postData(
      "/organisation/" + organisationId + "/member/",
      member
    );

    return response;
  } catch (error) {
    return error;
  }
}
