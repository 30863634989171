import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import { postData } from "../API/Post";
import { useNavigate } from "react-router-dom";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";

const ActionStep = ({
  actionStep,
  index,
  handleRemoveActionStep,
  handleActionStepTypeChange,
  handleActionStepChange,
}) => {
  const {
    name,
    type,
    webhook_url,
    downlink_payload,
    downlink_port,
    custom_function,
    forward_url,
  } = actionStep;

  const contacts = useSelector((state) => state.contacts);

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        <Stack direction="column" spacing={2}>
          <FormControl>
            <TextField
              fullWidth
              variant="outlined"
              label="Action Step Name"
              value={name}
              onChange={(e) =>
                handleActionStepTypeChange(index, type, e.target.value)
              }
            />
          </FormControl>
          <TextField
            fullWidth
            variant="outlined"
            label="Action Type"
            value={type}
            select
            onChange={(e) =>
              handleActionStepTypeChange(index, e.target.value, name)
            }
          >
            <MenuItem value="alert">Alert</MenuItem>
            <MenuItem value="forward">Forward</MenuItem>
            <MenuItem value="webhook">Webhook</MenuItem>
            <MenuItem value="downlink">Downlink</MenuItem>
            <MenuItem value="custom_function">Custom Function</MenuItem>
          </TextField>

          {
            //based on the action type, render the appropriate form
          }

          {type === "alert" && (
            <Autocomplete
              multiple
              id="tags-outlined"
              options={contacts}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Contacts"
                  placeholder="Select Contacts"
                />
              )}
            />
          )}
          {type === "forward" && (
            <TextField
              fullWidth
              variant="outlined"
              label="Forward URL"
              value={forward_url}
              onChange={(e) =>
                handleActionStepChange(
                  type,
                  webhook_url,
                  e.target.value,
                  downlink_payload,
                  downlink_port,
                  custom_function
                )
              }
            />
          )}
          {type === "webhook" && (
            <TextField
              fullWidth
              variant="outlined"
              label="Webhook URL"
              value={webhook_url}
              onChange={(e) =>
                handleActionStepChange(
                  type,
                  e.target.value,
                  downlink_payload,
                  downlink_port,
                  custom_function
                )
              }
            />
          )}
          {type === "downlink" && (
            <>
              <TextField
                fullWidth
                variant="outlined"
                label="Downlink Payload"
                value={downlink_payload}
                onChange={(e) =>
                  handleActionStepChange(
                    type,
                    webhook_url,
                    e.target.value,
                    downlink_port,
                    custom_function
                  )
                }
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Downlink Port"
                value={downlink_port}
                onChange={(e) =>
                  handleActionStepChange(
                    type,
                    webhook_url,
                    downlink_payload,
                    e.target.value,
                    custom_function
                  )
                }
              />
            </>
          )}
          {type === "custom_function" && (
            <TextField
              fullWidth
              variant="outlined"
              label="Custom Function"
              multiline
              rows={4}
              value={custom_function}
              onChange={(e) =>
                handleActionStepChange(
                  type,
                  webhook_url,
                  downlink_payload,
                  downlink_port,
                  e.target.value
                )
              }
            />
          )}

          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            onClick={() => handleRemoveActionStep(index)}
          >
            Remove
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default function ActionFlow() {
  const [actionSteps, setActionSteps] = useState([
    {
      name: "Alert",
      type: "alert",
      webhook_url: "https://",
      forward_url: "https://",
      downlink_payload: "",
      downlink_port: 1,
      custom_function: "",
    },
  ]);

  const [selectedDevices, setSelectedDevices] = useState([]);

  const [trigger, setTrigger] = useState({
    type: "temperature",
    above_below: "above",
    threshold: 0,
  });

  const theme = useTheme();

  const navigate = useNavigate();

  const devices = useSelector((state) => state.devices);

  const handleAddActionStep = () => {
    setActionSteps([
      ...actionSteps,
      {
        name: "step " + (actionSteps.length + 1),
        type: "alert",
        webhook_url: "https://",
        forward_url: "https://",
        downlink_payload: "",
        downlink_port: 1,
        custom_function: "",
      },
    ]);
  };

  const handleRemoveActionStep = (index) => {
    const newActionSteps = [...actionSteps];
    newActionSteps.splice(index, 1);
    setActionSteps(newActionSteps);
  };

  const handleTriggerChange = (type, above_below, threshold) => {
    setTrigger({
      type,
      above_below,
      threshold,
    });
  };

  const handleActionStepChange = (
    index,
    webhook_url,
    downlink_payload,
    downlink_port,
    custom_function,
    forwarder_url
  ) => {
    const newActionSteps = [...actionSteps];
    newActionSteps[index] = {
      ...newActionSteps[index],
      webhook_url,
      downlink_payload,
      downlink_port,
      custom_function,
      forwarder_url,
    };
    setActionSteps(newActionSteps);
  };

  const handleActionStepTypeChange = (index, type, name) => {
    //get the current action step
    const actionStep = actionSteps[index];
    //set the type:
    actionStep.type = type;
    actionStep.name = name;

    //update the action step
    const newActionSteps = [...actionSteps];
    newActionSteps[index] = actionStep;
    setActionSteps(newActionSteps);
  };

  const handleSaveActionFlow = () => {
    //save the action flow
    const data = {
      devices: selectedDevices,
      trigger: trigger,
      actionSteps: actionSteps,
    };

    postData("/action_flow", data);

    updateSnackbarMessage("Action Flow Saved");
    navigate("/actions");
  };

  return (
    <>
      <PageTitle title="Action Flow" theme={theme} />

      <Card>
        <CardHeader title={`Action Flow`} />
        <CardContent>
          <TextField fullWidth variant="outlined" label="Action Flow Name" />
        </CardContent>
        <CardHeader title={`Select Devices`} />
        <CardContent>
          {
            //autocomplete devices, id is the value, name is the label
          }
          <Autocomplete
            multiple
            id="tags-outlined"
            options={devices}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            onChange={(event, value) => setSelectedDevices(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Devices"
                placeholder="Select Devices"
              />
            )}
          />
        </CardContent>
        <CardHeader title={`Trigger`} />
        <CardContent>
          <Stack direction="column" spacing={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Check Type"
              value={trigger.type || "temperature"}
              onChange={(e) =>
                handleTriggerChange(
                  e.target.value,
                  trigger.above_below,
                  trigger.threshold
                )
              }
              select
            >
              <MenuItem value="temperature">Temperature</MenuItem>
              <MenuItem value="humidity">Humidity</MenuItem>
              <MenuItem value="pressure">Pressure</MenuItem>
              <MenuItem value="battery">Battery</MenuItem>
              <MenuItem value="light">Light</MenuItem>
              <MenuItem value="motion">Motion</MenuItem>
              <MenuItem value="button-press">Button Press</MenuItem>
            </TextField>

            <TextField
              fullWidth
              variant="outlined"
              label="Above or Below"
              value={trigger.above_below || "above"}
              select
              onChange={(e) =>
                handleTriggerChange(
                  trigger.type,
                  e.target.value,
                  trigger.threshold
                )
              }
            >
              <MenuItem value="above">Above</MenuItem>
              <MenuItem value="below">Below</MenuItem>
            </TextField>

            <TextField
              fullWidth
              variant="outlined"
              label="Threshold"
              value={trigger.threshold || 0}
              onChange={(e) =>
                handleTriggerChange(
                  trigger.type,
                  trigger.above_below,
                  e.target.value
                )
              }
              inputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {!trigger.type
                      ? ""
                      : trigger.type === "temperature"
                      ? "°C"
                      : trigger.check_type === "humidity"
                      ? "%"
                      : trigger.check_type === "pressure"
                      ? "hPa"
                      : trigger.check_type === "battery"
                      ? "%"
                      : trigger.check_type === "light"
                      ? "lux"
                      : trigger.check_type === "motion"
                      ? "motion"
                      : trigger.check_type === "button-press"
                      ? "button press"
                      : ""}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Button variant="outlined">Cancel</Button>
          <Button variant="outlined" onClick={handleSaveActionFlow}>
            Save
          </Button>
        </CardActions>
      </Card>

      <Grid
        container
        spacing={1}
        alignItems="stretch"
        alignContent="stretch"
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <Button variant="outlined" onClick={handleAddActionStep}>
            Add Action Step
          </Button>
        </Grid>
        {actionSteps.map((actionStep, index) => (
          <Grid item xs={12} md={6} lg={3} sx={{ mb: 1 }} key={index}>
            <ActionStep
              index={index}
              actionStep={actionStep}
              handleRemoveActionStep={handleRemoveActionStep}
              handleActionStepChange={handleActionStepChange}
              handleActionStepTypeChange={handleActionStepTypeChange}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
