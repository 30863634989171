import React, { useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import DialogBase from "./DialogBase";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { fetchDevice } from "../../Functions/fetchDevice";

export default function DeviceChangeForwarderDialog(props) {
  const { t } = useTranslation("common");
  const {
    forwarderDialogOpen,
    setForwarderDialogOpen,
    forwarder_id,
    handledeviceOptionsClose,
  } = props;

  const device = useSelector((state) => state.device);
  const user = useSelector((state) => state.user);

  const [forwarder, setForwarder] = useState(forwarder_id ? forwarder_id : 0);

  const changedeviceForwarder = async () => {
    fetchDevice(device.id);

    updateSnackbarMessage(t("devices.forwarder_changed"));
    setForwarderDialogOpen(false);
    handledeviceOptionsClose();
  };

  return (
    <DialogBase
      title={t("devices.change_forwarder")}
      open={forwarderDialogOpen}
      onClose={() => setForwarderDialogOpen(false)}
      validateFields={() => changedeviceForwarder()}
    >
      <TextField
        id="device-name"
        variant="outlined"
        value={forwarder}
        onChange={(e) => setForwarder(e.target.value)}
        fullWidth
        select
      >
        <MenuItem key="update-forwarder-none-0" value="0">
          None
        </MenuItem>
        {user &&
          user.forwarders &&
          user.forwarders.map((forwarder) => (
            <MenuItem
              key={"forwarder-change-" + forwarder.name + "-" + forwarder.id}
              id={"forwarder-change-" + forwarder.name + "-" + forwarder.id}
              value={forwarder.id}
            >
              {forwarder.name}
            </MenuItem>
          ))}
      </TextField>
    </DialogBase>
  );
}
