import { useTheme } from "@emotion/react";
import { Card, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import formatTemperature from "../../../Functions/formatTemperature";

export default function TemperatureGraph(props) {
  const [temperatureHistory, setTemperatureHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  const theme = useTheme();

  let useIsXL = true;

  if (props.useIsXL !== undefined) {
    useIsXL = props.useIsXL;
  }

  const [isXl, setIsXl] = React.useState(
    useIsXL === true && window.innerWidth > 2048
  );

  const { temperatureValue, title, aspect } = props;

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to temperatureHistory
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let temperature = 0;

        //if temperatureValue is not null, set it to use temperature
        if (temperatureValue) {
          temperature = jsonData[temperatureValue];
        } else {
          temperature = jsonData.temperature;
        }

        tempHistory.push({
          Date: time_created,
          Temperature: formatTemperature(temperature, true),
        });
      });
      setTemperatureHistory(tempHistory);

      // on reisize, check isXl and update aspect ratio
      const handleResize = () => {
        setIsXl(useIsXL === true && window.innerWidth > 2048);
      };

      window.addEventListener("resize", handleResize);
    } catch (error) {}
  }, [deviceHistory, temperatureValue, useIsXL]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={title ? title + " History" : "Temperature History"} />
      <ResponsiveContainer
        aspect={aspect ? aspect : !isMobile ? (isXl ? 3 : 4) : 2}
      >
        <AreaChart
          data={temperatureHistory}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <defs>
            <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="Date"
            type={"category"} //format date to be more readable
            tickFormatter={(value) => {
              return formatDateOnly(value);
            }}
          />
          <YAxis dataKey="Temperature" type="number" yAxisId="1" />
          <Tooltip
            contentStyle={{
              backgroundColor: theme.palette.background.default,
            }}
            cursor={false}
            labelFormatter={(label) => {
              return formatDate(label);
            }}
            //format label to capitalize first letter of each word and format value to use celsius symbol and format date

            formatter={(value, name) => {
              return [
                localStorage.getItem("temperature_unit") === "F"
                  ? value + "°F"
                  : value + "°C",
                title ? title : name,
              ];
            }}
          />
          <Area
            yAxisId="1"
            dataKey="Temperature"
            stroke={theme.palette.primary.main}
            animationDuration={300}
            fill="url(#Temperature)"
            strokeWidth={2}
            dominantBaseline={"middle"}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
}
