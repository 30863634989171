export const convertPercentToColour = (percent) => {
  if (!percent) return "#f44336";

  //if percent has a % sign, remove it, convert to int
  if (String(percent).includes("%")) {
    percent = parseInt(percent.slice(0, -1));
  }

  if (isNaN(percent)) {
    return percent === "Normal" ? "#4caf50" : "#ff5722";
  }

  if (percent > 75) return "#4caf50";
  if (percent > 50) return "#ff9800";
  if (percent > 25) return "#ff5722";
  return "#f44336";
};

export default convertPercentToColour;
